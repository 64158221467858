import { Typography } from '@material-ui/core';
import fullVendorList from '../data/vendors/processedH2Vendors.json';

const VendorCatalogContainer = [
    {
        key: 'vendorCatalogContainer',
        type: 'catalog',
        sections: [
            {
                key: 'vendorCatalogSection',
                collection: 'vendorFiltersSorters',
                type: 'catalog',
                spacing: 4,
                cardMapping: {
                    subheader: {
                        field: 'vendorTypes',
                        className: ' vendorType'
                    },
                    media: {
                        field: 'imageURL',
                        className: ' vendorMedia'
                    },
                    cardContent: {
                        functions: [
                            (element) => {
                                return (
                                    <Typography>{element.vendorName}</Typography>
                                )
                            }
                        ]
                    },
                    buttonLink: {
                        field: 'vendorURL',
                        className: ' vendorButton',
                        buttonText: 'See Website'
                    }

                },
                elements: fullVendorList
            }
        ]
    }
]

const VendorFilterFieldGroups = [
    {
        key: 'vendorFiltersSorters',
        sections: [
            {
                key: 'vendorFiltersSortersSection',
                collection: 'vendorFiltersSorters',
                type: 'interactive',
                elements: [
                    {
                        key: 'evChargersPlugType',
                        type: 'buttonCluster',
                        buttonWidth: '50%',
                        property: 'vendorTypeFilter',
                        title: 'Vendor type',
                        buttons: [
                            {
                                name: 'Production',
                                action: 'Production'
                            },
                            {
                                name: 'Dispensing & Storage',
                                action: 'Dispensing & Storage'
                            }
                        ],
                        scaleOverrides: {
                            xs: 12,
                            md: 8
                        }
                    },
                    {
                        key: 'vendorSortBy',
                        property: 'vendorSortBy',
                        type: 'dropdown',
                        options: [
                            {
                                name: 'A - Z',
                                value: 'az'
                            },
                            {
                                name: 'Z - A',
                                value: 'za'
                            }
                        ],
                        title: 'Sort By',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    }
                ]
            }
        ]
    }
]

const VendorCatalogToggle = [
    {
        key: 'fuelingAlternativeToggle',
        sections: [
            {
                key: 'fuelingAlternativeToggleButton',
                collection: 'vendorFiltersSorters',
                type: 'interactive',
                elements: [
                    {
                        key: 'fuelAlternativesToggle',
                        property: 'fuelType',
                        type: 'toggleTabs',
                        options: [
                            {name: 'Electric', action: 'electric', key: 'fuelType_electric'},
                            {name: 'Hydrogen', action: 'hydrogen', key: 'fuelType_hydrogen'}
                        ],
                        default: 'Electric'
                    }
                ]
            }
        ],
    }
]

const H2VendorCatalogData = [ 
    ...VendorCatalogToggle,
    ...VendorFilterFieldGroups, 
    ...VendorCatalogContainer
]

export default H2VendorCatalogData;
