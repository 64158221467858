import React, {useState} from 'react';
import { TextField, Box, Typography, Button } from "@material-ui/core"
import { AmplifyLoginData } from '../../ComponentMetadata';

const AmplifyLogin = (
    {
        className,
        componentMetadata,
        collection,
        onSubmit,
        isRegistrationForm,
        renderFunction = null,
    }
) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const localStateGetters = {
        userName,
        password
    }

    const localStateSetters = {
        setUserName,
        setPassword
    }

    var X = Object.assign({}, componentMetadata, localStateGetters, localStateSetters);

    const renderSection = (sections) => {
        var elements = [];
        var renderedSection = sections.map(item => {
            if ('section' in item) renderSection(item);
            else if ('elements' in item) {
                item.elements.forEach((element) => {
                    elements.push(renderElement(element))
                });
            }
            return elements;
        });
        return renderedSection;
    }
    
    const renderElement = (element) => {
    
        var materialUiComponent;
        switch (element.props.type) {
            case 'typography':
                materialUiComponent = Typography
                break;
            case 'text':
            case 'password':
                materialUiComponent = TextField
                break;
            case 'submit': 
                materialUiComponent = Button    
                break;
            default:
                materialUiComponent = 'div'
        }
    
        if(element.props.localStateUpdaterName) {
            const setter = localStateSetters[element.props.localStateUpdaterName];
            element.props.onChange = (evt) => {
                setter(evt.target.value);
            };
        }

        if(element.props.localStatePropName) {
            const getter = localStateGetters[element.props.localStatePropName];
            element.props.value = getter;
        }
    
        return React.createElement(
            materialUiComponent,
            element.props
        );
    }

    const defaultRenderFunction = () => {
        return (
            <Box className={className}>
                {renderSection(X.sections)}
            </Box>
        )
    }

    const doRender = renderFunction || defaultRenderFunction;
    return doRender();
}

export const  AmplifyLoginMetaData = AmplifyLoginData;
export default AmplifyLogin;
