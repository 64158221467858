import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

/** STYLES **/
const dropdownStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const sliderStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));
  
const InfrastructureEvDesign = (
    {
        componentMetadata,
        collection, 
        onChange,
        renderFunction = null,
        dropdown,
        slider
    }
) => {
    const noOp = () => { return null }

    const equipmentEditable = collection.guidedPlannerInteracted ? 'notEditable' : collection.equipmentEditable;

    const renderSectionElements = (section) => {
        const direction = section.direction || 'row';

        const stuffIntoGridItem = (builtElement, key, scaleOverrides = {}) => {
            const numberOfElements = section.elements.length;
            const mdElementWidth = direction === 'row'
                ? Math.floor(12 / numberOfElements)
                : 12
            const smInputWidth = (mdElementWidth) * 2 > 6
                ? mdElementWidth
                : mdElementWidth * 2;
            const xsInputWidth = 12;

            return(
                <Grid
                    item
                    key={key}
                    xs={scaleOverrides.xs || xsInputWidth}
                    sm={scaleOverrides.sm || smInputWidth}
                    md={scaleOverrides.md || mdElementWidth}
                >{builtElement}</Grid>
            )
        }
        
        const elementBuilders = {
            dropdown: (input, val) => {
                
                const dd = dropdown(input, (newVal) => {
                    onChange(input.property, newVal);
                }, collection, input.options, classes )

                const stringVal = input.options[val];                
                
                const returnedValue = (input.editTogglable && equipmentEditable === 'editable')
                ? dd
                : elementBuilders.blurb({
                    title: input.title,
                    type: 'blurb',
                    text: '{propValue}',
                    property: input.textProperty || input.property,
                }, stringVal);

                return returnedValue;
            },
            slider: (input) => {
                const handleChange = (newVal) => {
                    onChange(input.property, newVal)
                }                 
                    
                const returnedSlider = slider(input, handleChange, collection, sliderClasses)

                return returnedSlider;
            },
            editButton: (input) => {
                 const editButton = collection.guidedPlannerInteracted ? null : (<Button
                    onClick={
                        () => {
                            const nextEquipEditable = (equipmentEditable === 'editable') 
                                ? 'notEditable'
                                : 'editable';
                            
                            onChange(input.property, nextEquipEditable);    
                        }
                    }
                >{input.text[equipmentEditable]}</Button>);
            
                return editButton;
            },
            blurb: (el, val) => {

                const macrifiedString = el.text.replace('{propValue}', collection[el.property]);

                return (
                    <React.Fragment>
                        <Typography>{el.title}</Typography>
                        <Typography>{macrifiedString}</Typography>
                    </React.Fragment>
                );
            }
        }

        return (
            <Grid
                container
                direction={direction}
                justify="center"
                key={section.key}
                spacing={2}
            >
                {
                    section.elements.map((el) => {
                        const builder = elementBuilders[el.type] || noOp;
                        const val = collection[el.property];
            
                        return stuffIntoGridItem(builder(el, val), `${section.collection}_${el.property}`, el.scaleOverrides);
                    })
                }
            </Grid>
        )
    }

    const renderFieldGroup = (fieldGroup) => {
        const groupTitle = typeof fieldGroup.title === 'string'
            ? fieldGroup.title
            : fieldGroup.title[equipmentEditable];

        return (
            <Box 
                className="boxShadowGroup"
                key={fieldGroup.key}
            >
                <Typography>{groupTitle}</Typography>
                {
                    fieldGroup.sections.map((section) => {
                        return renderSectionElements(section);
                    })
                }
            </Box>
        )
    }

    const defaultRenderFunction = () => {
        return (
            <Box>
                {componentMetadata.map((fieldGroup) => {
                    return renderFieldGroup(fieldGroup);
                })}
            </Box>
        )
    }

    const classes = dropdownStyles();
    const sliderClasses = sliderStyles();

    const doRender = renderFunction || defaultRenderFunction;

    return doRender();
}
export default InfrastructureEvDesign;