/**
 * Adds child to a ReactMD metadata element  
 * @param {object} element
 * @param {any} addToChildren
 * @returns {object} element
 */
const addChildToElement = (element, addToChildren) =>{
    if ('children' in element) {
        // if (Array.isArray(element.children)) {
        //     if (Array.isArray(addToChildren))
        //         element.children = element.children.concat(addToChildren)
        //     else element.children.push(addToChildren)
        // }
        // else if (Array.isArray(addToChildren))
            // element.children = addToChildren.concat(element.children)
        // else
            // element.children = [element.children, addToChildren]
    }
    else if (Array.isArray(addToChildren))
        element['children'] = [...addToChildren]
    else element['children'] = addToChildren
    return element;
}
export default addChildToElement;