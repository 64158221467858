import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chargerType: '',
    chargerID: '',
    chargerQuantity: 0,
    trenching: 100,
    totalKWCapacity: 0,
    isRecommendation: false
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {}; 
const reducers = {};

stateKeys.forEach((key) => {
    stateRetrievers[key] = (state) => {     
      if(!state.EVDesign) {
        return null;
      }
      return state.EVDesign[key];
    }
  
    reducers[key] = (state, action) => {
      state[key] = action.payload;
    }
});

export const evDesignSlice = createSlice({
    name: "evDesign",
    initialState,
    reducers
});

export const updaters = evDesignSlice.actions;

export const retrievers = stateRetrievers;

export default evDesignSlice.reducer;
