import { Container, Box, Paper, Grid } from "@material-ui/core"

import Typography from '@material-ui/core/Typography';
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import calstart_logo from '../assets/images/calstart-logo2.png'
import homepage_step1 from "../assets/images/homepage_step_1.svg"
import homepage_step2 from "../assets/images/homepage_step_2.svg"
import homepage_step3 from "../assets/images/homepage_step_3.svg"
import homepage_logo from "../assets/images/homepage_logo.svg"
import PageTitle from "./PageTitle";

import UserAuthenticationModal from "./UserAuthentication/UserAuthenticationModal";
import { useCheckLogin } from "./UserAuthentication/hooks/useCheckLogin";

const cardData = [
    { image: homepage_step1, step: <div className="homepage_step_text">Step 1</div>, title: <div className="homepage_step_title"><b>Electric</b> or <b>Fuel Cell</b> </div>, content: <p className="homepage_step_content">Learn about your options and decide which fueling option is right for you and your fleet.</p>, callToAction: <NavLink to="/fueling_alternatives">Learn More</NavLink> },
    { image: homepage_step2, step: <div className="homepage_step_text">Step 2</div>, title: <div className="homepage_step_title"><b>Commercial Fleet</b> &amp; <b>Site</b></div>, content: <p className="homepage_step_content">Every charging/refueling site is unique. Tell us about your fleet and we will recommend a system design.</p>, callToAction: <Link to="/guided_planner">Get Started</Link> },
    { image: homepage_step3, step: <div className="homepage_step_text">Step 3</div>, title: <div className="homepage_step_title"><b>Infrastructure Workplan</b></div>, content: <p className="homepage_step_content">Generate cost estimates, anticipated timelines, and review best practices. <br />Already have an idea in mind? Recreate your design to estimate costs and timeline.</p>, callToAction: <><Link to="/design_electric">Electric</Link><Link to="/design_hydrogen">Hydrogen</Link></> }
]
const createHomepageCard = (cardObject) => {
    return (
        <Grid item xs>
            <Paper className="homepage_card">
                <div className="card_content">
                    <img src={cardObject.image} alt="card content icon" />
                    {cardObject.step}
                    {cardObject.title}
                    {cardObject.content}
                </div>
                <div className="cta_row">
                    {cardObject.callToAction}
                </div>
            </Paper>
        </Grid>
    )
}

const sectionContent = () => {
    return (
        <>
            <Box>
                <Typography variant="h1" component="h2" gutterBottom>
                    Planning Green Commercial Fleet Infrastructure is a big job. <br /> <b>Let us help.</b>
                </Typography>
                <p>CALSTART is a leading, nonprofit organization dedicated to promoting advanced transportation technologies and fuels. As the market for zero-emission medium- and heavy-duty vehicles matures in California, fleets need guidance on the development of charging and refueling infrastructure.</p>
                <p>To help these fleets, CALSTART and ZappyRide have created a tool that takes the user through the infrastructure development process, recommends the appropriate equipment, and provides cost and time estimates.</p>
                <Box>
                    <ul>
                        <li>Equipment Recommendations</li>
                        <li>Cost Estimates</li>
                        <li>Rollout Workplan</li>
                        <li>Vendor Options</li>
                    </ul>
                </Box>
            </Box>
            <Grid container spacing={3}>
                {cardData.map(card => createHomepageCard(card))}
            </Grid>

        </>
    )
}


const CalStartHomepage = () => {

    useCheckLogin();

    return (
        <div className="homePage">
            <PageTitle page="Home" />
            <Container >
                <div className="logo">
                    <img src={homepage_logo} alt="homepage logo" />
                    <img src={calstart_logo} className="logo" id="leftSpace" alt="calstart logo" />
                </div>
                {UserAuthenticationModal()}
                {sectionContent()}
            </Container>
        </div>
    )
}
export default CalStartHomepage;
