import React, { useState, useEffect , useRef , useCallback} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import { FlowComponentWrapper } from '@zappy-ride/react-flows';
import { CHARGER_DETAILS } from '../../data/evChargers/evChargerTypesAndPowers';
import evDesignSlice, { updaters, retrievers } from "../../Slices/evDesignSlice";
import { authenticatedUserRetrievers, authenticatedUserReducers } from '../UserAuthentication/authenticatedUserSlice';
import PageButtons from '../PageButtons';
import { EquipmentCatalog, EvCostEstimate, EvWorkPlan } from '../Pages';
import { Box, Typography, Slider, Button } from '@material-ui/core';
import PageHeader from '../PageHeader';
import PageTitle from '../PageTitle';
import { EvDesignMetadataBuilder } from '../EvDesign/EvDesignMetadata';
import { EvDesignRecommendationMetadataBuilder } from '../EvDesign/EvDesignRecommendationMetadata';
import EvDesignComponent from '../EvDesign/EvDesignComponent';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import { userSettingsApi } from '../UserAuthentication/services/userSettingsApi';
import { useCheckLogin } from '../UserAuthentication/hooks/useCheckLogin';

const CHARGER_OPTIONS = [
    { value: 'type', name: 'Type/Size' },
    { value: 'l2ACwl7.2', name: 'Level 2 AC wireless charging, 7.2kW' },
    { value: 'l2ACw7.7', name: 'Level 2 AC wired charging, 7.7kW' },
    { value: 'l2ACw7.7_p2', name: 'Level 2 AC wired charging, 7.7kW, 2 plug' },
    { value: 'l2ACw16.8', name: 'Level 2 AC wired charging, 16.8kW' },
    { value: 'l2ACw16.8_p2', name: 'Level 2 AC wired charging, 16.8kW, 2 plug' },
    { value: 'l2ACw19.2', name: 'Level 2 AC wired charging, 19.2kW' },
    { value: 'l2ACw19.2_p2', name: 'Level 2 AC wired charging, 19.2kW, 2 plug' },
    { value: 'dcfw50', name: 'DC Fast Charging, wired, 50kW' },
    { value: 'dcfw50_p2', name: 'DC Fast Charging, wired, 50kW, 2 plug' },
    { value: 'dcfw150', name: 'DC Fast Charging, wired, 150kW' },
    { value: 'dcfw150_p2', name: 'DC Fast Charging, wired, 150kW, 2 plug' },
    { value: 'dcfw350', name: 'DC Fast Charging, wired, 350kW' },
    { value: 'dcfw350_p2', name: 'DC Fast Charging, wired, 350kW, 2 plug' },
    { value: 'dcfo150', name: 'DC Fast Charging, overhead, 150kW' },
    { value: 'dcfo350', name: 'DC Fast Charging, overhead, 350kW' }
]

const twoPlugChargers = new Set(['l2ACw7.7_p2', 'l2ACw16.8_p2', 'l2ACw19.2_p2', 'dcfw150_p2', 'dcfw350_p2'])

const CHARGER_COUNT = Array.from(Array(50).keys()).map(num => { return { value: `${num}`, name: `${num}` } })

const EV_PAGE_BUTTONS = [
    { text: "View Cost Estimates", link: EvCostEstimate.link },
    { text: "View Workplan", link: EvWorkPlan.link },
    { text: "Equipment Catalog", link: EquipmentCatalog.link },
]

const totalKws = (charger, chargerQuantity) => {
    if (charger in CHARGER_DETAILS) {
        const powerMultiplier = twoPlugChargers.has(charger) ? 2 : 1;
        const chargerPower = (CHARGER_DETAILS[charger].chargerPower * chargerQuantity) * powerMultiplier;

        return `${Math.floor(chargerPower)}`;
    }
}

const EVDesignWrapper = () => {

    const dispatch = useDispatch();
    let [chargerID, setChargerID] = useState(useSelector(retrievers.chargerID) || 'type');
    let [chargerQuantity, setChargerQuantity] = useState(useSelector(retrievers.chargerQuantity));
    let [totalKWCapacity, setTotalKWCapacity] = useState(useSelector(retrievers.totalKWCapacity) || 0);
    let [trenching, setTrenching] = useState(useSelector(retrievers.trenching))
    let [isInEditMode, setisInEditMode] = useState(false);
    let [isRecommendation, setIsRecommendation] = useState(useSelector(retrievers.isRecommendation));
    const oldValues = { chagerId: useSelector(retrievers.chargerID) || 'type', chargerQuantity: useSelector(retrievers.chargerQuantity) };
    const recommendationWasProvided = useSelector(retrievers.isRecommendation);
    const history = useHistory();

    useEffect(() => {
        let totalKillowatts = totalKws(chargerID, chargerQuantity)
        setTotalKWCapacity(totalKillowatts) 
        if (!recommendationWasProvided) {
            dispatch(updaters.chargerID(chargerID))
            dispatch(updaters.chargerQuantity(chargerQuantity))
            dispatch(updaters.totalKWCapacity(Number(totalKillowatts)))
        }
    }, [chargerID, chargerQuantity, dispatch, recommendationWasProvided])

   const evDesignProps = {
        chargerID,
        chargerQuantity,
        trenching, 
        totalKWCapacity,
        isRecommendation
    }

    const evDesignPropsRef = useRef(evDesignProps);

    const existingSettings = { ...useSelector(authenticatedUserRetrievers.userSettings) }
    const idManagementSession = useSelector(authenticatedUserRetrievers.idManagementSession);
    const version = useSelector(authenticatedUserRetrievers.version);
    const settingsID = useSelector(authenticatedUserRetrievers.settingsID);

    const versionRef = useRef(version);
    const settingsIDRef = useRef(settingsID);
    const existingSettingsRef = useRef(existingSettings);

    const buildEVDesignQuery = () => {

        const evDesignKeys = new Set(Object.keys(retrievers));

        const queryData = {};

        Object.keys(evDesignPropsRef.current).forEach((item) => {
            if(evDesignKeys.has(item)) {
                queryData[item] = evDesignPropsRef.current[item];
            }
        })

        const mergedQueryData = Object.assign(existingSettingsRef.current, queryData);

        let stringified = JSON.stringify(mergedQueryData);

        stringified = stringified.replace(/"/g, '\\"')

        return stringified;
    }


    const updateUserSettings = useCallback((stringifiedRedux, version, settingsID) => {
        const query = `
        mutation MyMutation {
            updateSettings(input: {id: "${settingsID}", _version: ${version}, settings: "${stringifiedRedux}"}) {
              id
              settings,
              _version
            }
          }
        `;

        const ep = userSettingsApi.endpoints;
 
        dispatch(ep.changeUserData.initiate(query))
            .then((response) => {
                const newVersion = response.data.updateSettings._version;
                const newSettings = JSON.parse(response.data.updateSettings.settings);

                dispatch(authenticatedUserReducers.version(newVersion));
                dispatch(authenticatedUserReducers.userSettings(newSettings));
            })
            .catch((error) => {
            }) 
    }, [dispatch])

   const createUserSettings = useCallback( async (stringifiedRedux) => {

        const query = `mutation MyMutation {
            createSettings(input: {settings: "${stringifiedRedux}"}) {
                id
                settings
                _version
            }
        }`

        const ep = userSettingsApi.endpoints;

        dispatch(ep.changeUserData.initiate(query))
            .then((response) => {
                const newVersion = response.data.createSettings._version;
                const newSettingsID = response.data.createSettings.id;
                const newSettings = JSON.parse(response.data.createSettings.settings);

                dispatch(authenticatedUserReducers.version(newVersion));
                dispatch(authenticatedUserReducers.settingsID(newSettingsID));
                dispatch(authenticatedUserReducers.userSettings(newSettings));
            })
            .catch((error) => {
            }) 
    }, [dispatch])

    useEffect(() => {
        evDesignPropsRef.current = evDesignProps;
    });

    useEffect(() => {

        versionRef.current = version;
    }, [version])

    useEffect(() => {
        settingsIDRef.current = settingsID;
    }, [settingsID])

    useEffect(() => {
        evDesignPropsRef.current = evDesignProps;
        existingSettingsRef.current = existingSettings;
    })

    /* Should not be necessary, will be addressed with https://zappyride.atlassian.net/browse/FLEET-572 */
    useCheckLogin(() => {
        history.push('guided_planner');
    }, false);

    useEffect(() => {

        return () => {
            if(!idManagementSession) {
                return;
            }
            const stringifiedRedux = buildEVDesignQuery();

            if(versionRef.current && settingsIDRef.current) {
                updateUserSettings(stringifiedRedux, versionRef.current, settingsIDRef.current);
        
            } else {
                createUserSettings(stringifiedRedux);
            }
        }

      }, [idManagementSession, createUserSettings, updateUserSettings]);


    function editButtonClick() {
        setisInEditMode(true)
    }
    function saveButtonClick() {
        dispatch(updaters.chargerID(chargerID))
        dispatch(updaters.chargerQuantity(chargerQuantity))
        dispatch(updaters.totalKWCapacity(Number(totalKws(chargerID, chargerQuantity))));
        setIsRecommendation(false);
        setisInEditMode(false)
    }

    function cancelButtonClick() {
        setChargerID(oldValues.chagerId)
        setChargerQuantity(oldValues.chargerQuantity)
        setisInEditMode(false)
    }

    const editButton = <Button className="editButton" onClick={editButtonClick}><EditOutlinedIcon />Edit</Button>
    const saveButton = <Button className="saveButton" onClick={saveButtonClick}><DoneOutlinedIcon />Save</Button>
    const cancelButton = <Button className="cancelButton" onClick={cancelButtonClick}><ClearOutlinedIcon />Cancel</Button>

    const totalCapacityValueBox = <Box className="totalCapacityValue non_edit"><Typography> {totalKWCapacity} <span className="kw">kw</span></Typography> </Box>
    const totalCapacityValueEditBox = (
        <Box className={`${(isInEditMode) ? "totalCapacityValue" : " totalCapacityValue static"}`} >
            <Typography>{totalKWCapacity} <span className="kw">kw</span></Typography>
            <Box className="editHolder">  {(!isInEditMode) ? editButton : <React.Fragment>{saveButton}{cancelButton}</React.Fragment>}  </Box>
        </Box>
    )

    const TrenchingSlider = () => {

        const [trenchingSliderValue, setTrenchingSliderValue] = useState(useSelector(retrievers.trenching));

        const handleTrenchingValueChange = (event, newValue) => {
            setTrenchingSliderValue(newValue);
            setTrenching(newValue);
        };

        const handleTrenchingSliderValueChangeCommitted = (event, value) => { dispatch(updaters.trenching(value)) }

        return <>
            <Typography>Total length of trenching (through asphalt, concrete, soil)</Typography>
            <div className="h2_demand_slider">
                <Slider
                    value={trenchingSliderValue}
                    onChange={handleTrenchingValueChange}
                    aria-labelledby="range-slider"
                    onChangeCommitted={handleTrenchingSliderValueChangeCommitted}
                    min={0} 
                    max={1320} 
                />

            </div>
            <Typography>{trenchingSliderValue} feet</Typography>
        </>
    }

    const evDesignMetadata = EvDesignMetadataBuilder()
        .withChargerDropDownOptions(CHARGER_OPTIONS)
        .withChargerValue(chargerID)
        .withOnChargerValueChange((event) => {
            setChargerID(event.target.value);
        })    
        .withChargerQuantityDropDownOptions(CHARGER_COUNT)
        .withChargerQuantityValue(chargerQuantity)
        .withOnChargerQuantityValueChange((event) => {
            setChargerQuantity(event.target.value);
        })
        .withTrenchingSlider(TrenchingSlider)
        .withTotalCapacityValue((recommendationWasProvided) ? totalCapacityValueEditBox : totalCapacityValueBox)
        .build()

    const evDesignRecommendationMetadata = EvDesignRecommendationMetadataBuilder()
        .withChargerValue(CHARGER_OPTIONS.filter(charger => charger.value === chargerID)[0].name)
        .withChargerQuantityValue(chargerQuantity)
        .withTrenchingSlider(TrenchingSlider)
        .withTotalCapacityValue(totalCapacityValueEditBox)
        .build()

    return (
        <>
            <PageTitle page="EvDesign" />
            <PageHeader
                title="Your EV Infrastructure Design"
                tagline={isRecommendation
                    ? <React.Fragment>Thanks for all of that information. We’ve crunched the numbers, and you have a few options to chose from.</React.Fragment>
                    : <React.Fragment> Don’t know what to choose? Tell us about your  <NavLink to="/guided_planner">site &amp; fleet</NavLink> so we can make a recommendation. </React.Fragment>}
            />

            {isRecommendation && <Box className="is_recommendation_banner"><Typography className="is_recommendation_text">Our Recommendation</Typography></Box>}

            <div className="infrastructure-ev-design">
                <EvDesignComponent componentMetadata={(recommendationWasProvided && !isInEditMode) ? evDesignRecommendationMetadata : evDesignMetadata} className="infrastructure-ev-design" />
            </div>
            {PageButtons(EV_PAGE_BUTTONS)}
        </>
    )
}



const WrappedEVDesign = FlowComponentWrapper('EVDesign', evDesignSlice)(EVDesignWrapper);

export default WrappedEVDesign;