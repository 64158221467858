import { Box, Button, Grid, Typography } from "@material-ui/core";
import { TextField } from 'mui-rff';

const validate = values => {
    const errors = {};
    if (!values.oldUserPassword) {
        errors.oldUserPassword = 'Required';
    }
    if (!values.userPassword) {
        errors.userPassword = 'Required';
    }
    if (!values.userPasswordConfirm) {
        errors.userPasswordConfirm = 'Required';
    }
    if (values.userPassword !== values.userPasswordConfirm) {
        errors.userPasswordConfirm = 'Must match';
    }

    return errors;
};


export const NewPasswordMetadataBuilder = () => (new _NewPasswordMetadataBuilder());
class _NewPasswordMetadataBuilder {

    constructor() {
        this.inputs = {
            onSubmitFunction: () => { },
            errorMessage: ''
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return (
            NewPasswordMetadata(this.inputs.onSubmitFunction, this.inputs.errorMessage)
        )
    }
}

export const NewPasswordMetadata = (onSubmitFunction, errorMessage) => {
    return (
        {
            onSubmit: onSubmitFunction,
            validateFunction: validate,

            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "newPasswordDialog",
                        className: "new_pasword_dialog",
                        container: true,
                        alignItems: "center",
                        spacing: 2
                    },

                    elements: [
                        {
                            size: 12,
                            uiComponent:Typography,
                            props: {
                                text: errorMessage,
                                className: errorMessage ? 'error-message' : 'hidden-error-message',
                                children: errorMessage
                            }
                        },

                        {
                            size: 12,
                            uiComponent: Typography,
                            props: {
                                children: "Change Password",
                                className: "headline"
                            }
                        },
                        {
                            size: 6,
                            uiComponent: Box,
                            props: {
                                className: "body",
                                children: (
                                    <span>
                                        Password must contain at least 7 letters and 1 number
                                    </span>
                                ),
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                type: "password",
                                label: "Current password",
                                placeholder: "",
                                InputLabelProps: { shrink: true },
                                name: "oldUserPassword"
                            }
                        },

                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                type: "password",
                                label: "New password",
                                placeholder: "",
                                InputLabelProps: { shrink: true },
                                name: "userPassword"
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                type: "password",
                                label: "Confirm password",
                                placeholder: "",
                                name: "userPasswordConfirm"
                            }
                        },
                        {
                            size: 6,
                            uiComponent: Button,
                            props: {
                                type: "submit",
                                variant: "contained",
                                children: "Update",
                            }
                        },                  
                    ]
                }
            ]
        }

    )
}
