import { CHARGER_OPTIONS } from "../data/evChargers/evChargerTypesAndPowers";

const quantities = {};

let i = 0;

while(i <= 50) {
    quantities[`${i}`] = `${i}`;
    i++;
}

const equipmentFieldGroup = {
    hasEditToggle: true,
    title: {
      editable: 'Equipment',
      notEditable: 'Our recommendation'  
    },
    editStateName: 'equipmentEditable',
    key: 'equipment',
    sections: [
        {
            collection: 'evInfrastructureDesign',
            key: 'equipment',
            type: 'interactive',
            elements: [
                {
                    title: 'Charger',
                    type: 'dropdown',
                    options: CHARGER_OPTIONS,
                    placeholder: 'Type/Size',
                    property: 'chargerID',
                    textProperty: 'chargerType',
                    editTogglable: true,
                    scaleOverrides: {
                        md: 6
                    }
                },
                {
                    title: 'Quantity',
                    type: 'dropdown',
                    options: quantities,
                    placeholder: '',
                    property: 'chargerQuantity',
                    editTogglable: true,
                    scaleOverrides: {
                        md: 3
                    }
                },
                {
                    title: 'Total Capacity',
                    type: 'blurb',
                    text: '{propValue} kW',
                    property: 'totalKWCapacity',
                    scaleOverrides: {
                        md: 2
                    } 
                },
                {
                    type: 'editButton',
                    text: {
                        editable: 'Save',
                        notEditable: 'Edit'
                    },
                    property: 'equipmentEditable',
                    scaleOverrides: {
                        md: 1
                    }
                }
            ]
        }
    ]
}

const trenchingFieldGroup = {
    title: 'Trenching',
    key: 'trenching',
    sections: [
        {
            collection: 'evInfrastructureDesign',
            title: 'Total length of trenching (through asphalt, concrete, soil)',
            key: 'trenching',
            type: 'interactive',
            elements: [
                {
                    type: 'slider',
                    min: 0,
                    max: 500,
                    unit: 'feet',
                    title: 'Total length of trenching (through asphalt, concrete, soil)',
                    property: 'trenching',
                    scaleOverrides: {
                        md: 4
                    }
                }
            ]
        }
    ]
}

const InfrastructureEVDesignData = [
    equipmentFieldGroup,
    trenchingFieldGroup
]

export default InfrastructureEVDesignData;