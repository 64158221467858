import React from 'react';
import {Box, Button, Card, CardContent, CardHeader, CardMedia, Grid, Icon, Link} from '@material-ui/core';

const CatalogCards = (cardMapping, catalogData, displayClasses) => {
    const catalogContainer = {
        cardMapping
    };

    // @TODO when receiving from backend hopefully we can get rid of this
    const checkForIncompleteImageURL = (url) => {
        const imageURL = url.split('://').length === 1 
            ? `https://assets.zappyride.com/img/chargers/${url}`
            : url;  

        return imageURL;    
    }
    
    const checkArraynessString = (varToString) => {
        if(Array.isArray(varToString)) {
            return varToString.join(', ')
        }

        return varToString;
    }

    // @TODO will need to specify an action per card section. Won't always be wifi
    // Should also do for title, subheader   
    // Also need to handle for CardContent/Typography
    // Specify what content will be within the section description for each of the above (e.g.action: WifiIcon)
    const returnedCatalogCard = (
        <Card>
            <CardHeader
                title={catalogContainer.cardMapping.title && catalogData[catalogContainer.cardMapping.title.field] }
                subheader={ 
                    catalogContainer.cardMapping.subheader && 
                    checkArraynessString(catalogData[catalogContainer.cardMapping.subheader.field]) 
                }
                action={
                    (<Icon>
                        {
                            catalogContainer.cardMapping.action && catalogContainer.cardMapping.action.function(catalogData)
                        }
                    </Icon>)
                }
            />
            {catalogContainer.cardMapping.abovePictureText &&
            (
                <Box
                    display="flex"
                    minWidth="100%"
                >
                    { 
                        catalogContainer.cardMapping.abovePictureText.fields.map((field, i, fields) => {
                            const boxWidth = (1 / fields.length) * 100
                            return(
                                <Box
                                    minWidth={`${boxWidth}%`}
                                    className={`${catalogContainer.cardMapping.abovePictureText.className}`}
                                >
                                    {catalogData[field]}
                                </Box>    
                            )
                        })                                
                    }
                </Box>
            )}
            { 
                catalogContainer.cardMapping.media &&
                (<CardMedia
                    className={`${displayClasses.media}${catalogContainer.cardMapping.media.className || ''}`}
                    image={checkForIncompleteImageURL(catalogData[catalogContainer.cardMapping.media.field])}
                    title='Card image'
                />)
            }
            <CardContent>
                {
                    catalogContainer.cardMapping.cardContent && catalogContainer.cardMapping.cardContent.functions.map((func) => {
                        return func(catalogData);
                    })
                }
                {
                    catalogContainer.cardMapping.buttonLink &&
                    (<Button
                        variant="contained"
                    ><Link
                        href={catalogData[catalogContainer.cardMapping.buttonLink.field]}
                        rel="noopener" 
                        target="_blank"
                    >{catalogContainer.cardMapping.buttonLink.buttonText}</Link></Button>)
                }
            </CardContent> 
        </Card>
    )

    return (
        <Grid
            item
            xs={12}
            sm={2}
            md={4}
        >
            {returnedCatalogCard}
        </Grid>
    )
}

export default CatalogCards;