import {FleetAndSiteCommonInputFields} from './';

const { VehicleTypes, TimesOfDayOptions, SiteInputs, StationType } = FleetAndSiteCommonInputFields;
 
const DepotFields = {
    'fieldGroups': [
        StationType,
        {
            title: 'My Fleet',
            subtitle: '',
            collection: 'fleet',
            sections: [
                {
                    key: 'fleet_section_0',
                    type: 'interactive',
                    inputs: [
                        {
                            property: 'vehicleType',
                            type: 'dropdown',
                            options: VehicleTypes,
                            title: 'Vehicle type',
                            placeholder: 'Select Vehicle Type'
                        },
                        {
                            properties: [
                                'timeAvailableStart',
                                'timeAvailableFinish'
                            ], 
                            type: 'doubleDropdown',
                            options: [
                                [ ...TimesOfDayOptions],
                                [ ...TimesOfDayOptions]
                            ],
                            title: [
                                'Time available to recharge or refuel',
                                ''
                            ],
                            mobileClasses: [
                                '',
                                'charge-finish-mobile'
                            ], 
                            placeholders: [
                                'Start',
                                'Finish'
                            ],
                            scaleOverrides: [
                                {
                                    md: 3
                                },
                                {
                                    md: 3
                                }
                            ]
                        },
                    ],
                },
                {
                    key: 'fleets_section_1',
                    type: 'interactive',
                    inputs: [
                        {
                            property: 'numVehicles',
                            type: 'slider',
                            unit: 'vehicles',
                            title: 'Number of vehicles',
                            min: 0,
                            max: 400
                        },
                        {
                            property: 'depotMilesPerDayPerVehicle',
                            type: 'slider',
                            unit: 'miles',
                            title: 'Average miles per day per vehicle',
                            min: 0,
                            max: 400
                        }
                    ]
                } 
            ]
        },
        SiteInputs
    ],
};

export default DepotFields;