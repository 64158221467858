import React, { useEffect } from 'react';
import { Flow, Flows, FlowSegment } from '@zappy-ride/react-flows';
import { unfiedApi } from './services/unifiedAPIQuery';
import { evDesignSlice } from './Slices/evDesignSlice';
import { H2DesignSlice } from './Slices/h2DesignSlice';
import { guidedPlannerSlice } from './Slices/guidedPlannerSlice';
import {AuthenticatedUserSlice} from './Components/UserAuthentication/authenticatedUserSlice';
import { userSettingsApi } from './Components/UserAuthentication/services/userSettingsApi';
import thunk from "redux-thunk";

import EVDesignWrapper from './Components/Wrappers/EVDesignWrapper';
import CalStartHomepage from './Components/CalStartHomepage';
import NavigationMenu from './Components/NavigationMenu';
import GaTracker from './utils/GaTracker';
import {
  ElectricOrFuelCell, MyFleetAndSite, 
  EvDesign, EvCostEstimate, EvWorkPlan, 
  FuelCellDesign, FuelCellCostEstimate,FuelCellWorkPlan,
  EquipmentCatalog, VendorCatalog, UtilityLookup,
} from './Components/Pages';

import './assets/scss/main.scss';

const App = () => {
  /* eslint-disable */
  const additionalReducers = 
    {
      [unfiedApi.reducerPath]: unfiedApi.reducer,
      [userSettingsApi.reducerPath]: userSettingsApi.reducer,
      ['AuthenticatedUser']:AuthenticatedUserSlice.reducer,
      ['EVDesign']:evDesignSlice.reducer,
      ['H2Design']:H2DesignSlice.reducer,
      ['GuidedPlanner']:guidedPlannerSlice.reducer
    }
  /* eslint-enable */  

  useEffect(() => {
    const path = window.location?.pathname;
    const page = path + window.location.search;

    GaTracker.initialize();
    GaTracker.trackPage(page);
  }, []);

  return (
    <div className="App" >

      <Flows renderNavigationBeforeContent renderNavigationBeforeContentFunction={NavigationMenu}>
        <Flow name="Flow_1" showTopFlowNav middleware={[thunk,unfiedApi.middleware,userSettingsApi.middleware]} additionalReducers={additionalReducers}> 
          <FlowSegment className="home" name="home" exact link="/" component={CalStartHomepage} />
          <FlowSegment className={ElectricOrFuelCell.className} name={ElectricOrFuelCell.name} linkProps={ElectricOrFuelCell.linkProps} link={ElectricOrFuelCell.link} component={ElectricOrFuelCell.component} />
          <FlowSegment className={MyFleetAndSite.className} name={MyFleetAndSite.name} linkProps={MyFleetAndSite.linkProps} link={MyFleetAndSite.link} component={MyFleetAndSite.component} />      
          <FlowSegment className={EvDesign.className} name={EvDesign.name} linkProps={EvDesign.linkProps} link={EvDesign.link} component={EVDesignWrapper} />
          <FlowSegment className={EvCostEstimate.className} name={EvCostEstimate.name} linkProps={EvCostEstimate.linkProps} link={EvCostEstimate.link} component={EvCostEstimate.component} />
          <FlowSegment className={EvWorkPlan.className} name={EvWorkPlan.name} linkProps={EvWorkPlan.linkProps} link={EvWorkPlan.link} component={EvWorkPlan.component} />
          <FlowSegment className={FuelCellDesign.className} name={FuelCellDesign.name} linkProps={FuelCellDesign.linkProps} link={FuelCellDesign.link} component={FuelCellDesign.component} />
          <FlowSegment className={FuelCellCostEstimate.className} name={FuelCellCostEstimate.name} linkProps={FuelCellCostEstimate.linkProps} link={FuelCellCostEstimate.link} component={FuelCellCostEstimate.component} />
          <FlowSegment className={FuelCellWorkPlan.className} name={FuelCellWorkPlan.name} linkProps={FuelCellWorkPlan.linkProps} link={FuelCellWorkPlan.link} component={FuelCellWorkPlan.component} />
          <FlowSegment className={EquipmentCatalog.className} name={EquipmentCatalog.name} linkProps={EquipmentCatalog.linkProps} link={EquipmentCatalog.link} component={EquipmentCatalog.component} /> 
          <FlowSegment className={VendorCatalog.className} name={VendorCatalog.name} linkProps={VendorCatalog.linkProps} link={VendorCatalog.link} component={VendorCatalog.component} />
          <FlowSegment className={UtilityLookup.className} name={UtilityLookup.name} linkProps={UtilityLookup.linkProps} link={UtilityLookup.link} component={UtilityLookup.component} />
        </Flow>
      </Flows>
    </div>)
}

export default App;
