import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

const token = isProd
  ? process.env.REACT_APP_PROD_UNIFIED_API_TOKEN
  : process.env.REACT_APP_DEV_UNIFIED_API_TOKEN;
const baseUrl = isProd
  ? process.env.REACT_APP_PROD_EV_INFO_API_HOST
  : process.env.REACT_APP_DEV_EV_INFO_API_HOST;

export const unfiedApi = createApi({
  reducerPath: 'unifiedAPI',
  baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers) => {
        headers.set('authorization', `Bearer ${token}`);
        return headers;
    }
 }),
  endpoints: (build) => {
    return {
      getChargers: build.query({
        query: (name) => `commercial/chargers?${name}`
      }),
      getVendors: build.query({
        query: () => 'commercial/vendors'
      }),  
      getUtilities: build.query({
        query: (name) => `powersuppliers?postcode=${name.postcode}`
      })
  }},
})

export const { useGetChargersQuery, useGetVendorsQuery, useGetUtilitiesQuery } = unfiedApi;
