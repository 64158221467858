const AmplifyLoginData = (componenetValues, setValues) => {

    // const { reduxUserName, reduxPassword } = componenetValues;
    // const { setReduxUserName, setReduxUserPassword } = setValues;
     return (
        {
            sections :[
                {
                    sectionId: "amplifyLoginTop",
                    sectionTitle: "User Inputs",
                    className: "amplify_inputs",
                    elements: [
                        {
                            props: {
                                type: "text",
                                label: "User Name",
                                placeholder: "",    
                                required: true,
                                localStatePropName: 'userName',
                                localStateUpdaterName: 'setUserName',
                                variant: 'filled',   
                                InputLabelProps: { shrink: true },
                                fullWidth: true,
                            }
                        },
                        {
                            props: {
                                type: "password",
                                label: "Password",
                                placeholder: "",
                                required: true,
                                localStatePropName: 'password',
                                localStateUpdaterName: 'setPassword',
                                variant: 'filled',                          
                                InputLabelProps: { shrink: true },
                                fullWidth: true,
                            }
                        },
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                children: "Log in",
                                onClick: () => {

                                }
                            }
                        }
                    ]
                }
            ]
        });
}

export default AmplifyLoginData;