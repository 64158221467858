import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    engDesignConst: {
        min: 15, 
        max: 45
    },
    permitFeesCost: {
        min: 5,
        max: 45
    },
    chargerCost: {
        min: 0,
        max: 0
    },
    hardwareCost: {
        min: 0,
        max: 0
    },
    installationCost: {
        min: 0, 
        max: 0
    },
    totalProjectCost: {
        min: 0,
        max: 0
    }
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {}; 
const reducers = {};

stateKeys.forEach((key) => {
    stateRetrievers[key] = (state) => {     
      return state.EVCost[key];
    }
  
    reducers[key] = (state, action) => {
      state[key] = action.payload;
    }
});

export const evCostSlice = createSlice({
    name: "evCost",
    initialState,
    reducers
});

export const updaters = evCostSlice.actions;

export const retrievers = stateRetrievers;

export default evCostSlice.reducer;
