import { useDispatch, useSelector } from "react-redux";
import { Auth } from 'aws-amplify';

import { userSettingsApi } from '../../UserAuthentication/services/userSettingsApi';
import { authenticatedUserRetrievers, authenticatedUserReducers } from '../../UserAuthentication/authenticatedUserSlice';
import { retrievers as guidedPlannerRetrievers, updaters as guidedPlannerUpdaters } from '../../../Slices/guidedPlannerSlice';
import { retrievers as EVDesignRetrievers, updaters as EvDesignUpdaters } from '../../../Slices/evDesignSlice';
import { retrievers as h2DesignRetrievers, updaters as h2DesignUpdaters } from '../../../Slices/h2DesignSlice';

const useCheckLogin = (onLoginChecked = () => {}, hydrateImmediately=true) => {

    const dispatch = useDispatch();    
    const hydrated = useSelector(authenticatedUserRetrievers.hydrated);

    const hydrate = () => {
        if(!hydrateImmediately) {
            return;
        }

        dispatch(userSettingsApi.endpoints.getUser.initiate())
        .then((apiResponse) => {
 
            if(apiResponse.status === 'fulfilled') {    
                 const guidedPlannerRetrieverSet = new Set(Object.keys(guidedPlannerRetrievers));
                 const evDesignRetrieverSet = new Set(Object.keys(EVDesignRetrievers));
                 const h2DesignRetrieverSet = new Set(Object.keys(h2DesignRetrievers));
    
                 const responseData = apiResponse.data;
    
                 if(responseData.listSettingss.items && responseData.listSettingss.items.length) {
                     const userData = responseData.listSettingss.items[0];
                     const settings = JSON.parse(userData.settings);

                     dispatch(authenticatedUserReducers.userSettings(settings));
    
                     if(userData.id) {
                         dispatch(authenticatedUserReducers.settingsID(userData.id))                            
                     }
    
                     if(userData._version) {
                         dispatch(authenticatedUserReducers.version(userData._version))
                     }

                     const hydrateSlice = (retrieverNamesSet, reduxUpdaters) => {
                        Object.keys(settings).forEach((settingName) => { 
                            if(retrieverNamesSet.has(settingName)) {
                                dispatch(reduxUpdaters[settingName](settings[settingName]));
                            } 
                        });
                     }

                     hydrateSlice(guidedPlannerRetrieverSet, guidedPlannerUpdaters); 
                     hydrateSlice(evDesignRetrieverSet, EvDesignUpdaters);   
                     hydrateSlice(h2DesignRetrieverSet, h2DesignUpdaters);
                 }

                 dispatch(authenticatedUserReducers.hydrated(true));
            }
        }).catch((error) => {
        })    
    }

    async function getCurrentUser() {
        try {
            const user = await Auth.currentAuthenticatedUser();

            dispatch(authenticatedUserReducers.idManagementSession(user));
            dispatch(authenticatedUserReducers.isLoggedIn(true));

            if(!hydrated) {
                hydrate();
            }    

            onLoginChecked();
        } catch(error) {
            // user is not signed in
        }
    }

    if(!useSelector(authenticatedUserRetrievers.idManagementSession)) {
        getCurrentUser(); 
    } else if (!hydrated) {
        hydrate();
    }
}

export {
    useCheckLogin
}