
import React from "react"
import { Grid, Paper, Typography } from "@material-ui/core"
import {costEstimatesDisclaimer} from "../cap";

const PaperGrid = (props) => {
    return (
        <Paper className={`${props.className}_paper`}>
            {React.createElement(Grid, props)}
        </Paper>
    )
}


export function CostEstimatesMetdataBuilder() { return (new _CostEstimatesMetdataBuilder()) }

export class _CostEstimatesMetdataBuilder {

    constructor() {
        this.inputs =
        {
            engineeringDesignTotalCost: "",
            engineeringDesignDescriptionCardArray: [],
            permittingFeesTotalCost: "",
            permittingFeesDescriptionCardArray: [],
            hardwareInstallationTotalCost: "",
            hardwareInstallationCostArray: [],
            totalProjectCost: ""

        }


        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }
    build() {

        return CostEstimatesMetadata(
            this.inputs.engineeringDesignTotalCost,
            this.inputs.engineeringDesignDescriptionCardArray,
            this.inputs.permittingFeesTotalCost,
            this.inputs.permittingFeesDescriptionCardArray,
            this.inputs.hardwareInstallationTotalCost,
            this.inputs.hardwareInstallationCostArray,
            this.inputs.totalProjectCost
        )
    }
}


export const CostEstimatesMetadata = (engineeringDesignTotalCost
    , engineeringDesignDescriptionCardArray
    , permittingFeesTotalCost
    , permittingFeesDescriptionCardArray
    , hardwareInstallationTotalCost
    , hardwareInstallationCostArray
    , totalProjectCost) => {
    return (
        {
            sections: [
                {
                    uiComponent: Grid
                    ,
                    props: {
                        id: "costEstimateDetails",
                        className: "cost_estimate_details",
                        container: true,
                        alignItems: "flex-start",
                        width: "100%",
                        justifyContent: "space-between"
                    },
                    sections: [
                        {
                            uiComponent: PaperGrid,
                            props: {
                                id: "engineeringAndDesign",
                                className: "engineering_and_design",
                                container: true,
                                alignItems: "flex-start",
                                width: "100%",
                                spacing: 2,
                                justifyContent: "space-between"
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 9
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "title",
                                        children: "Engineering & Design",
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 3
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "title_cost",
                                        children: engineeringDesignTotalCost,
                                    }
                                },
                                {
                                    size: 12,
                                    uiComponent: Grid,
                                    props: {
                                        className: "description_card_array",
                                        container: true,
                                        alignItems: "flex-start",
                                        spacing: 2,
                                        children: engineeringDesignDescriptionCardArray
                                    }
                                }
                            ]
                        },
                        {
                            uiComponent: PaperGrid,
                            props: {
                                id: "permittingAndFees",
                                className: "permitting_and_fees",
                                container: true,
                                alignItems: "flex-start",
                                width: "100%",
                                spacing: 2,
                                justifyContent: "space-between"
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 9
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "title",
                                        children: "Permitting & Fees",
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 3
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "title_cost",
                                        children: permittingFeesTotalCost,
                                    }
                                },
                                {
                                    size: 12,
                                    uiComponent: Grid,
                                    props: {
                                        className: "description_card_array",
                                        container: true,
                                        alignItems: "flex-start",
                                        spacing: 2,
                                        children: permittingFeesDescriptionCardArray
                                    }
                                }
                            ]



                        },
                        {
                            uiComponent: PaperGrid,
                            props: {
                                id: "hardwareAndInstallation",
                                className: "hardware_and_installation",
                                container: true,
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                spacing: 2
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 9
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "title",
                                        children: "Hardware & Installation",
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 3
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "title_cost",
                                        children: hardwareInstallationTotalCost,
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                    },
                                    uiComponent: Grid,
                                    props: {
                                        className: "description_card_array",
                                        container: true,
                                        spacing: 2,
                                        children: hardwareInstallationCostArray
                                    }
                                }
                            ],
                        },
                        {
                            uiComponent: PaperGrid,
                            desktopOnly: true,
                            props: {
                                id: "totalProject",
                                className: "total_project_cost",
                                container: true,
                                alignItems: "flex-start",
                                spacing: 2
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 9                                        
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "title",
                                        children: "Total",
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 6,
                                        md: 3
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "bottom_bar_title_cost",
                                        children: totalProjectCost
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "total_text",
                                        children: costEstimatesDisclaimer,
                                        variant: "body2",
                                        gutterBottom: true
                                    }
                                },


                            ],
                        },
                    ]

                }
            ]

        }


    )
}