const h2Vendors = [
 {
   "name": "Burns & MacDonnell",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.burnsmcd.com/",
   "img": "burnsmcdonnell"
 },
 {
   "name": "Black & Veatch",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.bv.com/",
   "img": "bv"
 },
 {
   "name": "Bayotech",
   "production": true,
   "dispensing_storage": false,
   "vendor_url": "www.bayotech.us",
   "img": "bayotech_logo"
 },
 {
   "name": "EPC LLC",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "http://www.epc4h2.com/",
   "img": "epc_logo"
 },
 {
   "name": "NEL Hydrogen",
   "production": true,
   "dispensing_storage": false,
   "vendor_url": "https://nelhydrogen.com/",
   "img": "Nel_Logo",
 },
 {
   "name": "Air Products",
   "production": true,
   "dispensing_storage": true,
   "vendor_url": "https://www.airproducts.com",
   "img": "air_Products"
 },
 {
   "name": "Air Liquide",
   "production": true,
   "dispensing_storage": false,
   "vendor_url": "https://www.airliquide.com/",
   "img": "air_liquide"
 },
 {
   "name": "Linde (Praxair)",
   "production": true,
   "dispensing_storage": false,
   "vendor_url": "https://www.lindeus.com/",
   "img": "pebsal_linde"
 },
 {
   "name": "Shell",
   "production": true,
   "dispensing_storage": false,
   "vendor_url": "https://www.shell.com/energy-and-innovation/new-energies/hydrogen.html",
   "img": "Shell_logo"
 },
 {
   "name": "OneH2",
   "production": true,
   "dispensing_storage": true,
   "vendor_url": "https://www.oneh2.com/",
   "img": "oneh2"
 },
 {
   "name": "Baker Botts",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.bakerbotts.com/",
   "img": "baker_botts"
 },
 {
   "name": "PowerTap Hydrogen",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://powertapfuels.com/",
   "img": "powertapfuels"
 },
 {
   "name": "TLM Petro Labor Force",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.petrolaborforce.com/",
   "img": "tlmpetrolaborforc"
 },
 {
   "name": "Sumitomo",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.sumitomocorp.com/",
   "img": "Sumitomo_Corp"
 },
 {
   "name": "FiedlerGroup",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.fiedlergroup.com/",
   "img": "fiedlergroup_logo"
 },
 {
   "name": "Iwatani",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.iwatani.com/",
   "img": "iwatani"
 },
 {
   "name": "Worley",
   "production": false,
   "dispensing_storage": true,
   "vendor_url": "https://www.worley.com/",
   "img": "Worley_Logo_2019_1000x303_RGB"
 }
]

export default h2Vendors;