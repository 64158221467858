import WrappedEquipmentCatalog from './Wrappers/EquipmentCatalogWrapper';
import FuelingAlternativesWrapper from './Wrappers/FuelingAlternativesWrapper';
import GuidedPlannerWrapper from './Wrappers/GuidedPlannerWrapper';
import EVDesignWrapper from './Wrappers/EVDesignWrapper';
import H2DesignWrapper from './Wrappers/H2DesignWrapper';
import EVCostsWrapper from './Wrappers/EVCostsWrapper';
import H2CostsWrapper from './Wrappers/H2CostsWrapper'
import EVWorkplanWrapper from './Wrappers/EVWorkplanWrapper';
import WrappedVendorCatalog from './Wrappers/VendorCatalogWrapper';
import UtilityLookupWrapper from './Wrappers/UtilityLookupWrapper';

import CalStartHomepage from './CalStartHomepage';
import {H2WorkPlan as H2WorkPlanComponent} from './H2WorkPlan'

export const Home = { className: "home", name: "home", link: "/", component: CalStartHomepage }
export const ElectricOrFuelCell = { className: "fueling-alternatives-app", name: "Electric or Fuel Cell?", linkProps: { "className": "electric_or_fuel_cell" }, link: "/fueling_alternatives", component: FuelingAlternativesWrapper }
export const MyFleetAndSite = { className:"guided-planner-app", name:"My Commercial Fleet & Site", link:"/guided_planner", linkProps: { "className": "my_fleet_&amp;_site" }, component: GuidedPlannerWrapper }
export const EvDesign = { className: "ev-design", name: "Design", link: "/design_electric", linkProps: { "className": "EV_infrastructure" }, component: EVDesignWrapper }
export const EvCostEstimate = { className: "ev-costs", name: "Cost Estimate", link: "/costs_electric", linkProps: { "className": "EV_infrastructure" }, component: EVCostsWrapper }
export const EvWorkPlan = { className: "ev-workplan", name: "Workplan", link: "/workplan_electric", linkProps: { "className": "EV_infrastructure" }, component: EVWorkplanWrapper }
export const FuelCellDesign = { className: "h2-design", name: "Design", link: "/design_hydrogen", linkProps: { "className": "fuel_cell_infrastructure" }, component: H2DesignWrapper }
export const FuelCellCostEstimate = { className: "h2-costs", name: "Cost Estimate", link: "/costs_hydrogen", linkProps: { "className": "fuel_cell_infrastructure" }, component: H2CostsWrapper }
export const FuelCellWorkPlan = { className: "h2-workplan", name: "Workplan", link: "/workplan_hydrogen", linkProps: { "className": "fuel_cell_infrastructure" }, component: H2WorkPlanComponent }
export const EquipmentCatalog = { className: "equipment-catalog", name: "Equipment Catalog", link: "/equipment_catalog", linkProps: { "className": "resources" }, component: WrappedEquipmentCatalog }
export const VendorCatalog = { className: "vendor-catalog", name: "Vendor Catalog", link: "/vendor_catalog", linkProps: { "className": "resources" }, component: WrappedVendorCatalog }
export const UtilityLookup = { className: "utility-lookup", name: "Utility Lookup", link: "/utility_lookup", linkProps: { "className": "resources" }, component: UtilityLookupWrapper }





export const PageTitleAndLinks = new Map([
    ["Home",  { name: " ", link: Home.link}],
    ["ElectricOrFuelCell",  { name: ElectricOrFuelCell.name, link: ElectricOrFuelCell.link}],
    ["MyFleetAndSite",  { name: MyFleetAndSite.name, link: MyFleetAndSite.link}],
    ["EvDesign",  { name: EvDesign.name, link: EvDesign.link}],
    ["EvCostEstimate",  { name: EvCostEstimate.name, link: EvCostEstimate.link}],
    ["EvWorkPlan",  { name: EvWorkPlan.name, link: EvWorkPlan.link}],
    ["FuelCellDesign",  { name: FuelCellDesign.name, link: FuelCellDesign.link}],
    ["FuelCellCostEstimate",  { name: FuelCellCostEstimate.name, link: FuelCellCostEstimate.link}],
    ["FuelCellWorkPlan",  { name: FuelCellWorkPlan.name, link: FuelCellWorkPlan.link}],
    ["EquipmentCatalog",  { name: EquipmentCatalog.name, link: EquipmentCatalog.link}],
    ["VendorCatalog",  { name: VendorCatalog.name, link: VendorCatalog.link}],
    ["UtilityLookup",  { name: UtilityLookup.name, link: UtilityLookup.link}]   
  ]);

const AllPages = [
    Home, ElectricOrFuelCell, MyFleetAndSite, 
    EvDesign, EvCostEstimate, EvWorkPlan, 
    FuelCellDesign, FuelCellCostEstimate,FuelCellWorkPlan,
    EquipmentCatalog, VendorCatalog, UtilityLookup,     
]
export default AllPages;