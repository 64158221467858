import React from 'react';
import { Typography, Button, Card, CardContent } from "@material-ui/core"
import { Grid } from '@material-ui/core'
import { Link } from "react-router-dom";
import CSTooltip from './CSTooltip';

const _recommendationMetaDataSectionTemplate = (section, title, titleClassName, rationale, rationaleClassName, url, buttonText, tooltips) => {

    const checkTooltips = (idx) => {
        if(!tooltips || !tooltips[idx]) {
            return null;
        }

        return (
            <CSTooltip
                buttonClassName="info-icon reco-tooltip"
                details={tooltips[idx]}
            />
        )
    }

    return ({
        sectionId: section,
        sectionTitle: section,
        className: section,
        elements: [
            {
                props: {
                    type: "title",
                    children: title,
                    variant: 'filled',
                    fullWidth: true,
                    className: titleClassName
                }
            },
            {
                props: {
                    type: "list",
                    children:
                        <ul>
                            <li className={rationaleClassName}>
                                {rationale[0]}
                                {checkTooltips(0)}
                            </li>
                            <li className={rationaleClassName}>
                                {rationale[1]}
                                {checkTooltips(1)}
                            </li>
                        </ul>,
                    variant: 'filled',
                    fullWidth: true,
                }
            },

            {
                props: {
                    type: 'Link',
                    children: <Link to={url}>
                        <button>
                            {buttonText}
                        </button>
                    </Link>
                }
            }
        ]
    });
}


const renderSection = (sections) => {
    var renderedSection = sections.map(item => {
        var elements = [];
        if ('section' in item) renderSection(item);
        else if ('elements' in item) {
            item.elements.forEach((element) => {
                elements.push(renderElement(element))
            });
        }
        return (
            <Card className={item.className}>
                <CardContent>
                    {elements}
                </CardContent>
            </Card>);
    });

    return renderedSection;

}

const renderElement = (element) => {

    var materialUiComponent;
    switch (element.props.type) {
        case 'title':
            materialUiComponent = TitleElement
            break;
        case 'list':
            materialUiComponent = 'div'
            break;
        case 'button':
            materialUiComponent = Button
            break;
        default:
            materialUiComponent = 'div'
    }

    return React.createElement(
        materialUiComponent,
        element.props
    );
}



const TitleElement = (props) => {
    return React.createElement(Typography, props);
}



const RecommendationResults = ({ props }) => {
    return (
        <Grid container spacing={3}>
            {renderSection(props.componentMetadata)}

        </Grid>

    );

}
export const recommendationMetaDataSectionTemplate = _recommendationMetaDataSectionTemplate;
export default RecommendationResults;
