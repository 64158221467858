export const h2CostByDelivryMechanism = {
  tubeTrailer: {
      standardMin: 20,
      standardMax: 40,
  },
  gas: {
      standardMin: 8,
      standardMax: 12,
  },
  liquid: {
      standardMin: 8,
      standardMax: 12,
  },
  cheapElectrolysis: {
      standardMin: 2,
      standardMax: 5,
  },
  expensiveElectrolysis: {
    standardMin: 10,
    standardMax: 20,
  },
  lowDemandSteamMethaneReforming: {
    standardMin: 7,
    standardMax: 13,
  },
  highDemandSteamMethaneReforming: {
    standardMin: 6,
    standardMax: 10
  }
};

export const h2CostVars = {
  h2HardwareInstallationCost : { min: 0, max: 0 },
  h2EngDesignCost : { name: 'Engineering Design Cost', min: 20, max: 50 },
  h2PermitFeesCost: { name: 'Permit and Fees Cost', min: 10, max: 30 },
  tubeTrailerCost : { name: 'No Hardware Needed', min: 0, max: 0 },
  supplyStorageTankCost_liquid : { name: 'Supply Storage Tank Cost (liquid)', min: 600, max: 1000 },
  liquidPumpCost : { name: 'Liquid Pump Cost', min: 125, max: 250 },
  gasCompressorCost : { name: 'Gas Compressor Cost', min: 125, max: 125 },
  dispensersCost: (h2DispenserCount) => {
    return { name: 'Dispensers Cost', tooltip: {
      text: [<div className="tooltipText">Equivalent to gas pumps, dispensers measure hydrogen by mass at two different pressures, H70/700bar and H35/350bar.</div>]
    }, min: (h2DispenserCount * 50), max: (h2DispenserCount * 75) }
  },
  humanMachineInterfaceCost: { name: 'Human Machine Interface Cost', tooltip: {
    text: [<div className="tooltipText">Refers more broadly to the controls cabinet and interface provided to run and administer a hydrogen fueling system.</div>]
  }, min: 200, max: 300 },
  electrolyzerCost:  { name: 'Electrolyzer Cost', min: 1800, max: 1800 },
  smrModuleCost: { name: 'Steam Methane Reformer Module Cost', min: 1300, max: 1300 }
}

export const minTubeTrailerLandSize = 0.125;
export const quarterAcre = 0.25;
export const cheapElectricity = 0.05; //'$0.05/kWh';