import React, { useState, useEffect, useRef, useCallback } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import AuthConfigBuilder from '../AwsAmplify/AuthConfig';
import UserAuthentication, { LoginMetadataBuilder, ForgotPasswordMetadataBuilder, AccountRecoveryMetadataBuilder, SignUpMetadataBuilder, UserVerificationMetadataBuilder, NewPasswordMetadataBuilder, PasswordChangeSuccessMetadataBuilder } from "../UserAuthentication/UserAuthentication";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import { authenticatedUserRetrievers, authenticatedUserReducers } from "../UserAuthentication/authenticatedUserSlice"

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

const authConfigRegion = isProd
    ? process.env.REACT_APP_PROD_AUTH_CONFIG_REGION
    : process.env.REACT_APP_DEV_AUTH_CONFIG_REGION;
const userPoolID = isProd
    ? process.env.REACT_APP_PROD_AUTH_CONFIG_USER_POOL_ID
    : process.env.REACT_APP_DEV_AUTH_CONFIG_USER_POOL_ID;
const userPoolWebClientID = isProd
    ? process.env.REACT_APP_PROD_AUTH_CONFIG_USER_POOL_WEB_CLIENT_ID
    : process.env.REACT_APP_DEV_AUTH_CONFIG_USER_POOL_WEB_CLIENT_ID;

const authConfig = new AuthConfigBuilder()
    .withRegion(authConfigRegion)
    .withUserPoolId(userPoolID)
    .withUserPoolWebClientId(userPoolWebClientID)
    .build();

Amplify.configure(authConfig);

function SimpleModal(props) {

    const dispatch = useDispatch();

    async function signOut() {
        try {
            await Auth.signOut();

            dispatch(authenticatedUserReducers.idManagementSession(null));
            dispatch(authenticatedUserReducers.isLoggedIn(false));

        } catch(error) {
        }
    }

    const handleOpen = () => {
        dispatch(authenticatedUserReducers.isModalOpen(true));
    };

    const handleClose = () => {
        props.setActiveComponent('login');
        dispatch(authenticatedUserReducers.isModalOpen(false));
    };

    const handleChangePassword = () => {
        props.setActiveComponent('changePassword');
        handleOpen();
    }

    const isModalOpen = useSelector(authenticatedUserRetrievers.isModalOpen);

     const openLogin = () => {
        props.setActiveComponent('login');
        handleOpen(true);
    };

    const openCreateAccount = () => {
        props.setActiveComponent('register');
        handleOpen(true);
    }

    const loginModal = props.renderLogin ?
        (<div className="loginButtonAndModal">
            <button type="button" className="left-login-button" onClick={openLogin}>
                Login
            </button>
            <button onClick={openCreateAccount}>
                Create Account
            </button>
            <Modal
                ref={props.ref}
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {props.children}
            </Modal>

        </div>) :
        (<div className="loginButtonAndModal">
            <button type="button" className="left-login-button" onClick={handleChangePassword}>
                Change Password
            </button>
            <Modal
                ref={props.ref}
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {props.children}
            </Modal>
            <button type="button" onClick={signOut}>
                Logout
            </button>
        </div>)

    return (
        <>
            {loginModal}
        </>
    )
}




function SwitchComponents({ active, children, errorMessage }) {
    <Typography className="error_message" >{errorMessage}</Typography>
    // Switch all children and return the "active" one
    return children.filter(child => child.props.name === active)
}

const UserAuthenticationModal = () => {

    const dispatch = useDispatch();

    const handleClose = useCallback(() =>  dispatch(authenticatedUserReducers.isModalOpen(false)), [dispatch])

    async function signIn(username, password) {

        try {
            const user = await Auth.signIn(username, password);

            dispatch(authenticatedUserReducers.idManagementSession(user));
            dispatch(authenticatedUserReducers.isLoggedIn(true));

            if (user) handleClose();

        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    async function confirmSignUp(activationCode) {

        try {
            await Auth.confirmSignUp(usersName, activationCode);
            setActiveComponent('login');

          } catch (error) {
              setErrorMessage(error.message)
          }
    }

    async function resendCode() {
        try {
            await Auth.resendSignUp(usersName);
        } catch (error) {
            setErrorMessage(error.message);
        }
    }

    async function signUp(username, email, password, name, phone) {
        try {
            const { user } = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    name: username
                }
            });
            setVerifyType('email');
            setUsersName(email);
            setVerifyValue(email);
            dispatch(authenticatedUserReducers.idManagementSession(user));
            if (user) setActiveComponent("verify")


        } catch (error) {
            setErrorMessage(error.message);
        }
    }

    async function changePassword(oldPassword, newPassword) {
        try {
            const user = await Auth.currentAuthenticatedUser();

            const passwordChangeSuccess = await Auth.changePassword(user, oldPassword, newPassword);
            if(passwordChangeSuccess) {
                setActiveComponent('passwordChangeSuccess');
            }

        } catch(error) {
            setErrorMessage(error.message);
        }
    }


    async function forgotPassword(username) {
        Auth.forgotPassword(username)
            .then((data) =>
            {
                if(data) {
                    setUsersEmail(username);
                    setActiveComponent('forgotPasswordRecovery')
                }
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
    }

    async function setNewPasswordFromForgotPassword(code, newPassword) {
        Auth.forgotPasswordSubmit(usersEmail, code, newPassword)
        .then((data) => {
            setActiveComponent('login');
        })
        .catch((error) => {
            setErrorMessage(error.message);
        });
    }


    const [activeComponent, setActiveComponent] = useState("login")
    const [errorMessage, setErrorMessage] = useState(null);
    const [verifyType, setVerifyType] = useState('');
    const [usersName, setUsersName] = useState('');
    const [usersEmail, setUsersEmail] = useState('');
    const [verifyValue, setVerifyValue] = useState('');

    const oldActiveComponent = useRef(activeComponent);

    useEffect(() => {
        if(oldActiveComponent.current !== activeComponent) {
            setErrorMessage('');

            oldActiveComponent.current = activeComponent;
        }

        if(activeComponent === 'passwordChangeSuccess') {
            setTimeout(handleClose, 5000);
        }
    }, [activeComponent, handleClose])

    const CloseButton = (
        <div className = "close-button">
            <Button onClick={() => {
                handleClose();
            }}>
                <CloseIcon/>
            </Button>
        </div>
    );

    return (
        <SimpleModal renderLogin={!useSelector(authenticatedUserRetrievers.isLoggedIn)} setActiveComponent={setActiveComponent}>
            <SwitchComponents active={activeComponent} errorMessage={errorMessage} >
                <UserAuthentication name="login"
                    componentMetadata={
                        LoginMetadataBuilder()
                            .withOnSubmitFunction(({ userName, userPassword }) => {
                                setErrorMessage('');
                                signIn(userName, userPassword)
                            })
                            .withOnShowRegistrationClick(() => { setActiveComponent("register") })
                            .withOnShowForgotPasswordClick(() => setActiveComponent("resetPassword"))
                            .withErrorMessage(errorMessage)
                            .build()}
                    closeButton={CloseButton}
                />
                <UserAuthentication name="register"
                    componentMetadata={
                        SignUpMetadataBuilder()
                            .withOnSubmitFunction(
                                ({ usersUserName, usersEmail, usersPassword, usersFirstName }) => {
                                    setErrorMessage('');
                                    signUp(usersUserName, usersEmail, usersPassword, usersFirstName, null)
                                })
                            .withOnShowLoginClick(() => setActiveComponent("login"))
                            .withErrorMessage(errorMessage)
                            .build()}
                    closeButton={CloseButton}
                />
                <UserAuthentication name="resetPassword"
                    componentMetadata={
                        ForgotPasswordMetadataBuilder()
                            .withOnSubmitFunction(({ userName }) => {
                                setErrorMessage('');
                                forgotPassword(userName)
                            })
                            .withOnCancelClick(() => setActiveComponent("login"))
                            .withErrorMessage(errorMessage)
                            .build()}
                    closeButton={CloseButton}
                />
                <UserAuthentication name="changePassword"
                    componentMetadata={
                        NewPasswordMetadataBuilder()
                            .withOnSubmitFunction(({ oldUserPassword, userPassword }) => { changePassword(oldUserPassword, userPassword) })
                            .withErrorMessage(errorMessage)
                            .build()}
                    closeButton={CloseButton}
                />
                <UserAuthentication name="passwordChangeSuccess"
                    componentMetadata={
                        PasswordChangeSuccessMetadataBuilder()
                            .build()}
                    closeButton={CloseButton}
                />
                <UserAuthentication name="forgotPasswordRecovery"
                    componentMetadata={
                        AccountRecoveryMetadataBuilder()
                            .withOnSubmitFunction(({ code, newPassword }) => {
                                setErrorMessage('');
                                setNewPasswordFromForgotPassword(code, newPassword)
                            })
                            .withOnCancelClick(() => setActiveComponent("login"))
                            .withErrorMessage(errorMessage)
                            .build()}
                />
                <UserAuthentication name="verify"
                    componentMetadata={
                        UserVerificationMetadataBuilder()
                            .withVerificationType(verifyType)
                            .withVerificationAttributeValue(verifyValue)
                            .withOnSubmitFunction(({activationCode}) => {
                                setErrorMessage('');
                                confirmSignUp(activationCode)
                            })
                            .withErrorMessage(errorMessage)
                            .withResendFunction(() => { resendCode() })
                            .build()}
                />
            </SwitchComponents>
        </SimpleModal>
    )
}

export default UserAuthenticationModal;