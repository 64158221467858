import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography, Box, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import menuLogo from "../assets/images/menu_logo.png"
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';


import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const NavigationMenu = (links) => {


  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [state, setState] = React.useState({
    open: false,
  });


  const [expandedPanel, setExpandedPanel] = React.useState(false);


const toggleDrawerAndClosePanel = () =>
{
  setState({ ...state, 'open':false });
  setExpandedPanel(false);
 }
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let menuMap = new Map();

  links.filter(
    l => l.props.hasOwnProperty("className"))
    .forEach(link => {
      if (!menuMap.has(link.props.className)) {
        menuMap.set(link.props.className, []);
      }
      menuMap.get(link.props.className).push(link)
    });

  let elements = Array.from(menuMap.keys()).map((key) => {
    let value = menuMap.get(key);
    if (value.length === 1) {
      return (
        React.cloneElement(
          value[0],
          { onClick: toggleDrawerAndClosePanel },
          value[0].props.children
        )
      )
    } else {
      let expanded = value.filter(val => val.props['to'] === window.location.pathname).length > 0
      return (
        <Accordion expanded={expandedPanel === key || expanded} onChange={handleAccordionChange(key)} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography className="menuAccordionParrent">{key.replaceAll("_", " ")}</Typography>
          </AccordionSummary>
          <AccordionDetails onClick={toggleDrawer('open', false)}>
            {value}
          </AccordionDetails>
        </Accordion>
      )
    }
  })

  const desktopNav = (
    <nav className="navigation-before-content">
      <NavLink to="/"><img src={menuLogo} alt="navigation menu logo"/></NavLink>
      {elements}
      <a className="powered-by-zappyride-desktop" href="https://www.zappyride.com" target="_blank" rel="noreferrer">Powered by ZappyRide</a>
    </nav>
  )

  const mobileNav = (
    <Box className="mobile-navigation-bar">
      <Typography className="mobile-navigation-bar-title">
        {document.title}</Typography>
      <><Button className="mobile-navigation-bar-button" onClick={toggleDrawer('open', true)}>
        {!state['open'] && <MenuIcon style={{ 'color': '#fff' }} />}
        {state['open'] && <CloseIcon style={{ 'color': '#fff' }} />}
      </Button>
      </>
      <SwipeableDrawer
        anchor={'left'}
        open={state['open']}
        onClose={toggleDrawer('open', false)}
        onOpen={toggleDrawer('open', true)}
      >
        <nav className="navigation-before-content">
          <NavLink to="/"><img src={menuLogo} alt="navigation menu logo"/></NavLink>
          {elements}
        </nav>
        <a className="powered-by-zappyride-mobile" href="https://www.zappyride.com" target="_blank" rel="noreferrer">Powered by ZappyRide</a>
      </SwipeableDrawer>
    </Box>
  )

  return (isMobileDevice) ? mobileNav : desktopNav;

}

export default NavigationMenu;