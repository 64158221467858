import React, {useCallback, useEffect, useRef} from 'react';
import { getViableDeliveryMechanisms } from '../../cap';
import { FlowComponentWrapper } from '@zappy-ride/react-flows';
import { useDispatch, useSelector } from "react-redux";
import { retrievers as guidedPlannerRetrievers } from "../../Slices/guidedPlannerSlice";
import H2DesignSlice, { updaters, retrievers } from "../../Slices/h2DesignSlice"
import { authenticatedUserRetrievers, authenticatedUserReducers } from '../UserAuthentication/authenticatedUserSlice';
import PageButtons from '../PageButtons';
import { EquipmentCatalog, FuelCellCostEstimate, FuelCellWorkPlan } from '../Pages';
import { H2DeliveryMethodCard } from '../H2DeliveryMethodCard'
import { Grid, Slider, Typography, MobileStepper, useMediaQuery, useTheme } from "@material-ui/core"
import ElectrolysisDelivery from "../../assets/images/electrolysis.svg"
import GasDelivery from "../../assets/images/gasTubeTrailer.svg"
import liquidDelivery from "../../assets/images/liquidTubeTrailer.svg"
import smrDelivery from "../../assets/images/steamMethaneReforming.svg"
import TubeTrailerDelivery from "../../assets/images/tubeTrailer.svg"
import PageHeader from '../PageHeader';
import { NavLink } from 'react-router-dom';
import PageTitle from '../PageTitle';
import SwipeableViews from 'react-swipeable-views';
import {h2CostByDelivryMechanism, h2CostVars} from '../../data/h2Costs/index';
import { useCheckLogin } from '../UserAuthentication/hooks/useCheckLogin';
import { userSettingsApi } from '../UserAuthentication/services/userSettingsApi';
import { commafyNumber, pushToMillions } from '../../utils/numberFormatting';


const H2DesignWrapper = () => {
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));
    const [activeStep, setActiveStep] = React.useState(0);
    const version = useSelector(authenticatedUserRetrievers.version);
    const idManagementSession = useSelector(authenticatedUserRetrievers.idManagementSession);
    const settingsID = useSelector(authenticatedUserRetrievers.settingsID);

    const h2DesignProps = {
        h2Demand: useSelector(retrievers.h2Demand),
        h2DeliveryTubeTrailer: useSelector(retrievers.h2DeliveryTubeTrailer),
        h2DeliveryGaseous: useSelector(retrievers.h2DeliveryGaseous),
        h2DeliveryLiquid: useSelector(retrievers.h2DeliveryLiquid),
        h2DeliveryElectrolysis: useSelector(retrievers.h2DeliveryElectrolysis),
        h2DeliverySteamMethaneReforming: useSelector(retrievers.h2DeliverySteamMethaneReforming),
        selectedMethod: useSelector(retrievers.selectedMethod),
        isSelectedH2DeliveryTubeTrailer: useSelector(retrievers.isSelectedH2DeliveryTubeTrailer),
        isSelectedH2DeliveryGaseous: useSelector(retrievers.isSelectedH2DeliveryGaseous),
        isSelectedH2DeliveryLiquid: useSelector(retrievers.isSelectedH2DeliveryGaseous),
        isSelectedH2DeliveryElectrolysis: useSelector(retrievers.isSelectedH2DeliveryElectrolysis),
        isSelectedH2DeliverySteamMethaneReforming: useSelector(retrievers.isSelectedH2DeliverySteamMethaneReforming),
        selectedDeliveryMethodValue: useSelector(retrievers.selectedDeliveryMethodValue)
    }

    const versionRef = useRef(version);
    const h2DesignPropsRef = useRef(h2DesignProps);
    const settingsIDRef = useRef(settingsID);
    const existingSettings = { ...useSelector(authenticatedUserRetrievers.userSettings) };
    const existingSettingsRef = useRef(existingSettings);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const dispatch = useDispatch();

    const buildH2DesignQuery = () => {

        const h2DesignKeys = new Set(Object.keys(retrievers));

        const queryData = {};

        Object.keys(h2DesignPropsRef.current).forEach((item) => {
            if(h2DesignKeys.has(item)) {
                queryData[item] = h2DesignPropsRef.current[item];
            }
        })

        const mergedQueryData = Object.assign(existingSettingsRef.current, queryData);

        let stringified = JSON.stringify(mergedQueryData);

        stringified = stringified.replace(/"/g, '\\"')

        return stringified;
    }

    useEffect(() => {
        h2DesignPropsRef.current = h2DesignProps;
    });

    useEffect(() => {
        versionRef.current = version;
    }, [version])

    useEffect(() => {
        settingsIDRef.current = settingsID;
    }, [settingsID])

    useEffect(() => {
        existingSettingsRef.current = existingSettings;
    })

    useCheckLogin();

    const updateUserSettings = useCallback(( (stringifiedRedux, version, settingsID) => {
        const query = `
        mutation MyMutation {
            updateSettings(input: {id: "${settingsID}", _version: ${version}, settings: "${stringifiedRedux}"}) {
              id
              settings,
              _version
            }
          }
        `;

        const ep = userSettingsApi.endpoints;
 
        dispatch(ep.changeUserData.initiate(query))
            .then((response) => {
                const newVersion = response.data.updateSettings._version;
                const newSettings = JSON.parse(response.data.updateSettings.settings);

                dispatch(authenticatedUserReducers.version(newVersion));
                dispatch(authenticatedUserReducers.userSettings(newSettings));
            })
            .catch((error) => {
            }) 
    }), [dispatch])

    const createUserSettings = useCallback( async (stringifiedRedux) => {
        const query = `mutation MyMutation {
            createSettings(input: {settings: "${stringifiedRedux}"}) {
                id
                settings
                _version
            }
        }`

        const ep = userSettingsApi.endpoints;

        dispatch(ep.changeUserData.initiate(query))
            .then((response) => {
                const newVersion = response.data.createSettings._version;
                const newSettingsID = response.data.createSettings.id;
                const newSettings = JSON.parse(response.data.createSettings.settings);

                dispatch(authenticatedUserReducers.version(newVersion));
                dispatch(authenticatedUserReducers.settingsID(newSettingsID));
                dispatch(authenticatedUserReducers.userSettings(newSettings));
            })
            .catch((error) => {
            }) 
        }, [dispatch])


    useEffect(() => {

        return () => {
            if(!idManagementSession) {
                return;
            }
            const stringifiedRedux = buildH2DesignQuery();
            if(versionRef.current && settingsIDRef.current) {
                updateUserSettings(stringifiedRedux, versionRef.current, settingsIDRef.current);
        
            } else {
                createUserSettings(stringifiedRedux);
            }
        }

    }, [createUserSettings, updateUserSettings, idManagementSession]);


    const h2PageButtons = [
        { text: "See Cost Estimates", link: FuelCellCostEstimate.link },
        { text: "See Workplan", link: FuelCellWorkPlan.link },
        { text: "Equipment Catalog", link: EquipmentCatalog.link },
    ]

    const cleanWater = useSelector(guidedPlannerRetrievers.cleanWater)
    const naturalGas = useSelector(guidedPlannerRetrievers.naturalGas)
    const cheapElectricity = useSelector(guidedPlannerRetrievers.cheapElectricity)
    const landSize = useSelector(guidedPlannerRetrievers.stationSize)

    const viable = getViableDeliveryMechanisms(landSize, cleanWater, cheapElectricity, naturalGas)
    const hasViableResult = viable.liquid || viable.gas || viable.tubeTrailer || viable.electrolysis || viable.steamMethaneReforming;

    const h2Demand = Math.round(useSelector(retrievers.h2Demand));

    dispatch(updaters.h2DeliveryLiquid(viable.liquid))
    dispatch(updaters.h2DeliveryGaseous(viable.gas))
    dispatch(updaters.h2DeliveryTubeTrailer(viable.tubeTrailer))
    dispatch(updaters.h2DeliveryElectrolysis(viable.electrolysis))
    dispatch(updaters.h2DeliverySteamMethaneReforming(viable.steamMethaneReforming))

    let h2DeliveryTubeTrailer = useSelector(retrievers.h2DeliveryTubeTrailer);
    let h2DeliveryGaseous = useSelector(retrievers.h2DeliveryGaseous);
    let h2DeliveryLiquid = useSelector(retrievers.h2DeliveryLiquid);
    let h2DeliveryElectrolysis = useSelector(retrievers.h2DeliveryElectrolysis);
    let h2DeliverySteamMethaneReforming = useSelector(retrievers.h2DeliverySteamMethaneReforming);
    let selectedDeliveryMethodValue = useSelector(retrievers.selectedDeliveryMethodValue);
    let h2DispenserCount = Math.ceil(useSelector(guidedPlannerRetrievers.h2DispenserCount));

    const h2CostArrays = {
        tubeTrailer: [h2CostVars.h2EngDesignCost, h2CostVars.h2PermitFeesCost],
        gasDelivery: [h2CostVars.dispensersCost(h2DispenserCount), h2CostVars.humanMachineInterfaceCost, h2CostVars.h2EngDesignCost, h2CostVars.h2PermitFeesCost],
        liquidDelivery: [h2CostVars.supplyStorageTankCost_liquid, h2CostVars.gasCompressorCost, h2CostVars.liquidPumpCost, h2CostVars.dispensersCost(h2DispenserCount), h2CostVars.humanMachineInterfaceCost, h2CostVars.h2EngDesignCost, h2CostVars.h2PermitFeesCost],
        electrolysisDelivery: [h2CostVars.supplyStorageTankCost_liquid, h2CostVars.gasCompressorCost, h2CostVars.liquidPumpCost, h2CostVars.dispensersCost(h2DispenserCount), h2CostVars.humanMachineInterfaceCost, h2CostVars.electrolyzerCost, h2CostVars.h2EngDesignCost, h2CostVars.h2PermitFeesCost],
        smr: [h2CostVars.supplyStorageTankCost_liquid, h2CostVars.gasCompressorCost, h2CostVars.liquidPumpCost, h2CostVars.dispensersCost(h2DispenserCount), h2CostVars.humanMachineInterfaceCost, h2CostVars.smrModuleCost, h2CostVars.h2EngDesignCost, h2CostVars.h2PermitFeesCost],
    }

    const getDynamicH2UpfrontCosts = (deliveryMethod) => {
        const costArray = h2CostArrays[deliveryMethod];
        const totalCost = {
            min: 0,
            max: 0
        };

        costArray.forEach((costItem) => {    
            totalCost.min += costItem.min * 1000;
            totalCost.max += costItem.max * 1000;
        });

        return totalCost;
    }


    const getDynamicH2DemandCost = (h2Demand, deliveryMethod ) => {

        const flattenMidrangeFigure = (n) => {
            if(n > 1000000 || n < 10000) {
                return n;
            }

            const coeff = 10000;
            const flattenedNumber = coeff * (Math.round(n / coeff));

            return flattenedNumber;

        }

        const minCost = flattenMidrangeFigure(h2Demand * h2CostByDelivryMechanism[deliveryMethod].standardMin);
        const maxCost = flattenMidrangeFigure(h2Demand * h2CostByDelivryMechanism[deliveryMethod].standardMax);

        return `$${pushToMillions(minCost)} - $${pushToMillions(maxCost)} / day`
    }
    let tubeTrailerOngoingCosts = getDynamicH2DemandCost(h2Demand, "tubeTrailer")
    let gasOngoingCosts = getDynamicH2DemandCost(h2Demand, "gas")
    let liquidOngoingCosts = getDynamicH2DemandCost(h2Demand, "liquid")

    let electrolysisOngoingCosts = getDynamicH2DemandCost(h2Demand, 
        cheapElectricity === 'yes' ?
            'cheapElectrolysis' :
            'expensiveElectrolysis'    
    )

    let smrOngoingCosts = getDynamicH2DemandCost(h2Demand, 
        h2Demand <= 500 ? 
            'lowDemandSteamMethaneReforming' :
            'highDemandSteamMethaneReforming'    
    )

    const tubeTrailerUpfront = getDynamicH2UpfrontCosts('tubeTrailer');
    const gasDeliveryUpfront = getDynamicH2UpfrontCosts('gasDelivery');
    const liquidDeliveryUpfront = getDynamicH2UpfrontCosts('liquidDelivery');
    const electrolysisDeliveryUpfront = getDynamicH2UpfrontCosts('electrolysisDelivery');
    const smrDeliveryUpfront = getDynamicH2UpfrontCosts('smr');

    const deliveryMethodsCards = [        
        { slide: 1, viability: h2DeliveryTubeTrailer, name: 'h2DeliveryTubeTrailer', title: "Tube Trailer Delivery", checkBox: selectedDeliveryMethodValue === 'tubeTrailer', subTitle: "", image: TubeTrailerDelivery, alt: "drawing of hydrogen tube trailer", upfrontCosts: `$${pushToMillions(tubeTrailerUpfront.min)} - $${pushToMillions(tubeTrailerUpfront.max)}`, ongoingCosts: tubeTrailerOngoingCosts, timeline: "Within 30 days", onClick: () => { dispatch(updaters.selectedDeliveryMethodValue("tubeTrailer")) } },
        { slide: 2, viability: h2DeliveryGaseous, name: 'h2DeliveryGaseous', title: "Gaseous Delivery", checkBox: selectedDeliveryMethodValue === 'gasDelivery', subTitle: "+ dispensing station", image: GasDelivery, alt: "drawing of hundred gallon water tank", upfrontCosts: `$${pushToMillions(gasDeliveryUpfront.min)} - $${pushToMillions(gasDeliveryUpfront.max)}`, ongoingCosts: gasOngoingCosts, timeline: "9.5 - 22 months", onClick: () => { dispatch(updaters.selectedDeliveryMethodValue("gasDelivery")) } },
        { slide: 3, viability: h2DeliveryLiquid, name: 'h2DeliveryLiquid', title: "Liquid Delivery", checkBox: selectedDeliveryMethodValue === 'liquidDelivery', subTitle: "+ dispensing station", image: liquidDelivery, alt: "drawing of hundred gallon water tank", upfrontCosts: `$${pushToMillions(liquidDeliveryUpfront.min)} - $${pushToMillions(liquidDeliveryUpfront.max)}`, ongoingCosts: liquidOngoingCosts, timeline: "9.5 - 22 months", onClick: () => { dispatch(updaters.selectedDeliveryMethodValue("liquidDelivery")) } },
        { slide: 4, viability: h2DeliveryElectrolysis, name: 'h2DeliveryElectrolysis', title: "On-site Electrolysis", checkBox: selectedDeliveryMethodValue === 'electrolysisDelivery', subTitle: "+ dispensing station", image: ElectrolysisDelivery, alt: "drawing of electric battery and water tank", upfrontCosts: `$${pushToMillions(electrolysisDeliveryUpfront.min)} - $${pushToMillions(electrolysisDeliveryUpfront.max)}`, ongoingCosts: electrolysisOngoingCosts, timeline: "3.5 - 11 months", onClick: () => { dispatch(updaters.selectedDeliveryMethodValue("electrolysisDelivery")) } },
        { slide: 5, viability: h2DeliverySteamMethaneReforming, name: 'h2DeliverySteamMethaneReforming', title: "On-site Steam Methane Reforming", checkBox: selectedDeliveryMethodValue === 'smr', subTitle: "+ dispensing station", image: smrDelivery, alt: "drawing of steam methane reforming hydrogen", upfrontCosts: `$${pushToMillions(smrDeliveryUpfront.min)} - $${pushToMillions(smrDeliveryUpfront.max)}`, ongoingCosts: smrOngoingCosts, timeline: "7 - 13 months", onClick: () => { dispatch(updaters.selectedDeliveryMethodValue("smr")) } }
    ].map(deliveryMethod => H2DeliveryMethodCard(deliveryMethod))

    const H2DemandSliderAndTitle = () => {
        const handleH2DemandSliderValueChange = (event, newValue) => {
            setH2DemandSliderValue(newValue);
        };
        const handleH2DemandSliderValueChangeCommitted = (event, value) => { dispatch(updaters.h2Demand(value)) }

        const [H2DemandSliderValue, setH2DemandSliderValue] = React.useState(h2Demand);

        return <>
            <Typography id="non-linear-slider" gutterBottom>Hydrogen Demand</Typography>
            <div className="h2_demand_slider">
                <Slider
                    value={H2DemandSliderValue}
                    onChange={handleH2DemandSliderValueChange}
                    // valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    onChangeCommitted={handleH2DemandSliderValueChangeCommitted}
                    max={35000}
                />

            </div>
            <Typography id="non-linear-slider" gutterBottom> {commafyNumber(H2DemandSliderValue)} kg / day </Typography>
        </>
    }

    const isRecommendation = hasViableResult && !isNaN(h2Demand);

    const desktopH2Design = (
        <>
            <PageTitle page="FuelCellDesign" />
            <PageHeader
                title="Your Hydrogen Fuel Cell Infrastructure Design"
                tagline={isRecommendation
                    ? <React.Fragment>Thanks for all of that information. We’ve crunched the numbers, and you have a few options to chose from.</React.Fragment>
                    : <React.Fragment> Don’t know what to choose? Tell us about your  <NavLink to="/guided_planner">site &amp; fleet</NavLink> so we can make a recommendation. </React.Fragment>}
            />

            {!isRecommendation && <H2DemandSliderAndTitle />}
            <Grid style={{ display: 'flex', flexWrap: 'wrap' }} >
                {deliveryMethodsCards}
            </Grid>
            {PageButtons(h2PageButtons)}
        </>

    )
    const mobileH2Design = (
        <>
            <PageTitle page="FuelCellDesign" />
            <PageHeader
                title="Your Hydrogen Fuel Cell Infrastructure Design"
                tagline={isRecommendation
                    ? <React.Fragment>Thanks for all of that information. We’ve crunched the numbers, and you have a few options to chose from.</React.Fragment>
                    : <React.Fragment> Don’t know what to choose? Tell us about your  <NavLink to="/guided_planner">site &amp; fleet</NavLink> so we can make a recommendation </React.Fragment>}
            />

            {!isRecommendation && <H2DemandSliderAndTitle />}
            <SwipeableViews
                enableMouseEvents
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
            >
                {deliveryMethodsCards}
            </SwipeableViews>
            <MobileStepper
                variant="dots"
                steps={deliveryMethodsCards.length}
                position="static"
                activeStep={activeStep}

            />
            {PageButtons(h2PageButtons)}
        </>

    )
    return (isMobileDevice) ? mobileH2Design : desktopH2Design
}
const WrappedH2Design = FlowComponentWrapper('H2Design', H2DesignSlice)(H2DesignWrapper);
export default WrappedH2Design;