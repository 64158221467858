import React from "react";
import { Grid } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const CostEstimates = (
    {
        className,
        componentMetadata,
        renderFunction = null
    }
) => {

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const renderSections = (sections) => {
        sections = (Array.isArray(sections)) ? sections : sections.sections
        const renderedSection = sections.map(item => {

            const elements = [];

            if ('sections' in item) elements.push(renderSections(item.sections));
            else if ('elements' in item) {
                item.elements.forEach((element, index) => {
                    elements.push(renderElement(element, index))
                });
            }

            item.props["children"] = elements;

            let returnedSectionElement = React.createElement(item.uiComponent, item.props)

            if(item.desktopOnly) {
                returnedSectionElement = isMobileDevice ? null : returnedSectionElement;
            }

            if(item.mobileOnly) {
                returnedSectionElement = isMobileDevice ? returnedSectionElement : null;
            }

            return returnedSectionElement;
            });
        return renderedSection;
    }

    const renderElement = (element, idx) => {
        
        const GridContent = React.createElement(element.uiComponent, element.props);
        const gridProps = element.gridItemProps || {xs: element.size};
        gridProps.key = idx;
        gridProps.item = true;
        gridProps.children = GridContent;

        const GridItem = React.createElement(Grid, gridProps);

        return GridItem;
    }

    const defaultRenderFunction = (reactArrayofSections) => {
        return (
            <div className={className} >
                {reactArrayofSections}
            </div>
        )
    }

    const doRender = renderFunction || defaultRenderFunction;
    return doRender(renderSections(componentMetadata));

}


export default CostEstimates;