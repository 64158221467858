import {timeDifference} from '../utils';

const dailyKWHPerVehicle = (kwhPerMile, dailyMilesPerVehicle) => {
    return kwhPerMile * dailyMilesPerVehicle;
}

const depotChargingWindow = (depotOpens, depotCloses) => {
    const chargingDifference = timeDifference(depotOpens, depotCloses);

    return chargingDifference;
}

const depotPlugCount = (vehicleCount, fractionVehiclesCharged = 1) => {
    if(fractionVehiclesCharged > 1) {
        throw new Error('Maximum fraction of vehicles charged cannot exceed 1');
    }

    return vehicleCount * fractionVehiclesCharged;
}

// We MAY need to invert kwhPerMile, as it will be represented as milesPerHWH
const plugKilowattRequiredCapacityAtDepot = (vehicleCount, kwhPerMile, dailyMilesPerVehicle, depotOpens, depotCloses, maxPlugLoad = 350, fractionVehiclesCharged = 1) => {

    const kwhPerVehicle = dailyKWHPerVehicle(kwhPerMile, dailyMilesPerVehicle);
    const plugCount = depotPlugCount(vehicleCount, fractionVehiclesCharged);
    const vehiclesPerPlug = vehicleCount/plugCount;
    const chargeWindow = depotChargingWindow(depotOpens, depotCloses);

    const capacity = kwhPerVehicle / (chargeWindow * vehiclesPerPlug);

    if(capacity > maxPlugLoad) {
        throw new Error('Required capacity per plug exceeds 350 kW');
    }

    return capacity;
}

export {
    plugKilowattRequiredCapacityAtDepot,
    dailyKWHPerVehicle,
    depotChargingWindow,
    depotPlugCount
}