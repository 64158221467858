const commafyNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const pushToMillions = (number) => {
    if(number >= 1000000) {
        const toMil = Math.round((number)/10000)/100;

        return `${commafyNumber(toMil)}M`;
    } else if(number >= 1000) {
        const commafiedNumber = commafyNumber(number / 1000);
        return `${commafiedNumber}K`;
    }

    return number;
}

export {
    commafyNumber,
    pushToMillions
}