import { Box, Link, Typography, Button, Grid } from "@material-ui/core"
import { TextField, Checkboxes } from 'mui-rff';
import {validateEmail } from '../../utils/validators'

const validate = values => {
    const errors = {};

    if (!validateEmail(values.usersEmail)) {
        errors.usersEmail = 'Please enter a valid email address';
    }

    if(!values.usersUserName) {
        errors.usersUserName = 'Required';
    }

    if (!values.usersPassword) {
        errors.usersPassword = 'Required';
    }
    if (!values.usersAgreesToTerms) {
        errors.usersAgreesToTerms = 'Required';
    }

    if (!values.usersPassword) {
        errors.usersPasswordConfirm = 'Required';
    }

    if (values.usersPassword !== values.usersPasswordConfirm) {
        errors.usersPasswordConfirm = 'Password Does Not Match';
    }
    return errors;
};

const labelCheckbox = <Link to="terms">Terms and conditions</Link>



export const SignUpMetadataBuilder = () => (new _SignUpMetadataBuilder());
class _SignUpMetadataBuilder {
    constructor() {
        this.inputs = {
            onSubmitFunction: () => { },
            onShowLoginClick: () => { },
            errorMessage: ''
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return (
            SignUpMetadata(this.inputs.onSubmitFunction, this.inputs.onShowLoginClick, this.inputs.errorMessage)
        )
    }
}


export const SignUpMetadata = (onSubmitFunction, onShowLoginClick, errorMessage) => {
    return (
        {
            onSubmit: onSubmitFunction,        
            validateFunction: validate,
            
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "createAnAccount",
                        className: "create_account",
                        container: true,
                        alignItems: "flex-start",
                        spacing: 2
                    },
                    elements: [
                        {
                            size: 12,
                            uiComponent:Typography,
                            props: {
                                text: errorMessage,
                                className: errorMessage ? 'error-message' : 'hidden-error-message',
                                children: errorMessage
                            }
                        },
                        {
                            size: 12,
                            uiComponent: Typography,
                            props: {
                                text: "Create an account to save your progress",
                                className: "headline",
                                children: "Create an account to save your progress",
                            }
                        },
                        {
                            size: 12,
                            uiComponent: Box,
                            props: {
                                className: "already_have_account",
                                children: (
                                    <span>
                                        Already have an account? <Link aria-label="Log In" className="cta_login" onClick={onShowLoginClick}> Log In</Link>
                                    </span>
                                ),
                            }
                        },


                        {

                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersUserName",
                                type: "text",
                                placeholder: "Enter a username",
                                label: "User Name"
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersEmail",
                                type: "text",
                                placeholder: "Enter your e-mail address",
                                label: "E-Mail Address",

                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersPassword",
                                type: "password",
                                placeholder: "Password",
                                label: "Create Password",
                                tooltip: {
                                    props: {}
                                }
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersPasswordConfirm",
                                type: "password",
                                placeholder: "Confirm Password",
                                label: "Confirm Password",

                                tooltip: {
                                    props: {}
                                }
                            }
                        },                       
                        {
                            size: 8,
                            uiComponent: Checkboxes,
                            props: {
                                data: { label: labelCheckbox, value: 'item1' },
                                className: 'terms_conditions_checkbox',

                                name: "usersAgreesToTerms"
                            }

                        },
                        {
                            uiComponent: Button,
                            props: {
                                type: "submit",
                                className: "create_account",
                                children: "Create Account"                    
                            }
                        }
                    ]
                }
            ]
        })
}