import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FuelingAlternativesCardModal from './FuelingAlternativesCardModal';

const FuelingAlternativesCard = ({
    data,
    renderFunction
}) => {

    const useStyles = makeStyles({
        root: {
            height: '100%'
        },
        media: { 
          height: 80,
          width: 80
        },
      });

    const classes = useStyles();  

    const defaultRender = (cardData) => {

        const learnMoreButton = cardData.learnMore ?
            (<FuelingAlternativesCardModal 
                modalData={cardData.learnMore}    
            />) :
            null

        const cardContent=(        
            <div>
                <Typography 
                    gutterBottom
                >{cardData.title}</Typography>
                <Typography>{cardData.text}</Typography> 
            </div>
        );
    
        return (
            <Card className={`${classes.root} ${cardData.className}`}>
                    <CardMedia
                        className={`${classes.media} fueling-options-icon`} 
                        image={cardData.img}
                        title={cardData.title}
                    />
                    <CardContent className="fueling-options-card-content">
                        {cardContent}
                        {learnMoreButton}
                    </CardContent>
            </Card>
        )
    } 

    const doRender = renderFunction || defaultRender;

    return doRender(data);
}

export default FuelingAlternativesCard;