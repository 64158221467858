
import React from 'react';
import { Grid } from "@material-ui/core"
import { NavLink } from 'react-router-dom';

const buttonBuilder = (text, link, sizing, className, onClick) => {
    return (
        <Grid item xs={sizing}>
            <NavLink className={className} to={link} onClick={onClick}>{text}</NavLink>
        </Grid>
    )
}

const PageButtons = (buttonArray) => {
    let buttons = buttonArray.map(button => {
        return buttonBuilder(button.text, button.link, buttonArray.length / 12, button.className || "", button.onClick)
    })
    return (
        <div className="pageButtons">
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    {buttons}
                </Grid>
            </Grid>
        </div>
    )
}

export default PageButtons;
