import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import { makeStyles } from '@material-ui/core/styles';

import { 
    electrolysis,
    permanentStation,
    steamMethaneReforming,
    tubeTrailer,
    activeCheck,
    dimmedCheck

} from '../../images'

const namedImageSet = {
    electrolysis,
    permanentStation,
    steamMethaneReforming,
    tubeTrailer, 
    activeCheck,
    dimmedCheck
};

/** STYLES **/
const sliderStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

const cardStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    media: { // @TODO sizes of button media from Figma are not quite consistent.
      height: 90,
      width: 90,
      margin: '0 auto'
    },
    titleBlock: {
        width: '90%',
        height: '5rem'
    },
    alignLeft: {
        textAlign: 'left'
    },
    checkMark: {
        height: 16,
        width: 16,
    }
  }));
  
  
  const InfrastructureH2Design = (
    {
        fieldGroups,
        renderFunction = null,
        slider,
        onChange
    }
) => {


    const collection = {
        deliveryTubeTrailer: 'notSelected',
        deliveryGaseous: 'notSelected',
        deliveryLiquid: 'selected',
        electrolysis: 'notViable',
        methaneReforming: 'notViable',
        h2Demand: 0
    }

    const cardClassNames = {
        notSelected: 'h2delivery-card-not-selected',
        selected: 'h2delivery-card-selected',
        notViable: 'h2-delivery-card-notViable'
    }

    const cardCheckmarks = {
        notSelected: namedImageSet.dimmedCheck,
        selected: namedImageSet.activeCheck,
        notViable: namedImageSet.dimmedCheck
    }

    const cardClasses = cardStyles();
    const sliderClasses = sliderStyles();

    const renderElementsInSection = (section) => {

        const stuffIntoGridItem = (builtElement, key, scaleOverrides = {}) => {
            const direction = section.direction || 'row';
            const numberOfElements = section.elements.length;
            const mdElementWidth = direction === 'row'
                ? Math.floor(12 / numberOfElements)
                : 12
            const smInputWidth = (mdElementWidth) * 2 > 6
                ? mdElementWidth
                : mdElementWidth * 2;
            const xsInputWidth = 12;
    
            return(
                <Grid
                    item
                    key={key}
                    xs={scaleOverrides.xs || xsInputWidth}
                    sm={scaleOverrides.sm || smInputWidth}
                    md={scaleOverrides.md || mdElementWidth}
                >{builtElement}</Grid>
            )
        }

        const renderCards = (card) => {
            const cardClassName = cardClassNames[collection[card.property]];
            const cardCheckmark = cardCheckmarks[collection[card.property]];

            const handleClick = () => {
                if(collection[card.property] === 'notViable') {

                    return;
                }
                onChange(`${section.collection} ${card.property} active`);
            }

            return(
                <Card className={`${cardClasses.root} ${cardClassName}`}>
                    <CardActionArea
                        onClick={handleClick}
                    >
                        <Box 
                            display="flex"
                            width="100%"
                        >
                            <Box className={`${cardClasses.titleBlock} ${cardClasses.alignLeft}`}>
                                <Typography>{card.cardTitle}</Typography>
                                <Typography>{card.subTitle}</Typography>
                            </Box>
                            <Box className={cardClasses.checkmarkBlock}>
                                <CardMedia
                                    className={cardClasses.checkMark}
                                    component="img"
                                    alt="checkSelected"
                                    src={cardCheckmark}
                                />
                            </Box>
                        </Box>
                        <CardMedia 
                            className={cardClasses.media} 
                            component="img"
                            alt={card.img}
                            src={namedImageSet[card.img]}
                        />
                        {
                            card.details.map((detail) => {
                               return ( 
                               <Box
                                    key={detail.key}
                                    className={cardClasses.alignLeft}
                                >
                                    <Typography>{detail.title}</Typography>
                                    <Typography>{detail.value}</Typography>
                                </Box>
                               )
                            })
                        }
                    </CardActionArea>
                </Card>
            )
        }   
        
        const renderInteractives = (el) => {

            const elementBuilders = {
                slider: (el) => {
                    return (
                        slider(el, (val) => {
                            onChange(`${collection} ${el.property} ${val}`)
                        }, collection, sliderClasses)
                    )
                }
            }

            return elementBuilders[el.type](el);
        }

        const renderingFunctions = {
            selectorCards: renderCards,
            interactive: renderInteractives
        }

        return (
            <Grid
                key={section.key}
                container
                justify="center"
                spacing={2}
            >
                {
                    section.elements.map((element) => {
                        const newElement = renderingFunctions[section.type](element);

                        return stuffIntoGridItem(newElement, element.key, element.scaleOverrides);
                    })
                }
            </Grid>
        );
        
    }

    const renderFieldGroup = (fieldGroup) => {
        return (
            <Box
                key={fieldGroup.key}
            >   
                {
                    fieldGroup.sections.map((section) => {
                        return renderElementsInSection(section);
                    })
                }
            </Box>

        )
    }

    const defaultRenderFunction = (data) => {
        return (
            <Box>
                {
                    data.map((fieldGroup) => {
                        return renderFieldGroup(fieldGroup);
                    })
                }
            </Box>
        )
    }

    const doRender = renderFunction || defaultRenderFunction;

    return doRender(fieldGroups);
}

export default InfrastructureH2Design;