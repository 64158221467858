import { createSlice } from "@reduxjs/toolkit";

// Ask Olivier if radio buttons should default to false or be blank

const initialState = {
  stationType: 'depot',
  vehicleType: '',
  timeAvailableStart: '',
  timeAvailableFinish: '',
  numVehicles: 0,
  depotMilesPerDayPerVehicle: 0,
  timeAtOnRouteStation: 0,
  onRouteVehiclesPerDay: 0,
  timeAvailableStartOnRoute: '',
  timeAvailableFinishOnRoute: '',
  maxVehiclesAtOnce: '',
  additionalMilesPerCharge: 0,
  siteAddress: '',
  zip: '', 
  stationSize: 'small',
  cleanWater: 'no',
  cheapElectricity: 'no',
  naturalGas: 'no',
  overheadInterest: 'no',
  wirelessInterest: 'no',
  plugsOverCapacity: 'no',
  h2MilesPerKilogram: 'N/A',
  deliveryMechanism: {},
  h2DispenserCount: 1,
  h2Quantity: 100
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {}; 
const reducers = {};

stateKeys.forEach((key) => {
  stateRetrievers[key] = (state) => {
    if(!state.GuidedPlanner) {
      return null;
    }
    return state.GuidedPlanner[key];
  }

  reducers[key] = (state, action) => {
    state[key] = action.payload;
  }
});

export const guidedPlannerSlice = createSlice({
  name: "guidedPlanner",
  initialState,
  reducers
});

export const updaters = guidedPlannerSlice.actions;

export const retrievers = stateRetrievers;

export default guidedPlannerSlice.reducer;