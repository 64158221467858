import React from 'react';
import { Grid } from "@material-ui/core";

const InsertIntoGridItem = (builtElement, section, key, scaleOverrides = {}) => {

    if(scaleOverrides === 'noGrid') {
        return builtElement;
    }

    const direction = section.direction || 'row';
    const numberOfElements = section.elements.length;
    const mdElementWidth = direction === 'row'
        ? Math.floor(12 / numberOfElements)
        : 12
    const smInputWidth = (mdElementWidth) * 2 > 6
        ? mdElementWidth
        : mdElementWidth * 2;
    const xsInputWidth = 12;

    return(
        <Grid
            item
            key={key}
            xs={scaleOverrides.xs || xsInputWidth}
            sm={scaleOverrides.sm || smInputWidth}
            md={scaleOverrides.md || mdElementWidth}
        >{builtElement}</Grid>
    );
};

export default InsertIntoGridItem;