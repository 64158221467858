import React from 'react';
import { Box, Card, Grid, Typography } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { InsertIntoGridItem } from "..";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { pushToMillions } from '../../../utils/numberFormatting';

const InfrastructureCost = (
    {
        componentMetadata,
        collection, 
        renderMethod = null
    }
) => {

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const renderKOrMRange = (element) => {
        const min = pushToMillions(collection[element.property].min * 1000);
        const max = pushToMillions(collection[element.property].max * 1000);

        return `$${min} - $${max}`;
    }

    const renderElementsInSection = (section) => {
          const noOp = () => { return <div/>; }
        
        const sectionTypeHandlers = {

            // Binding element type to component.
            costBreakdownRanges: (element) => {
                const elementMapping = {
                    mobileTotalCost: () => {
                        const outputRange = renderKOrMRange(element);

                        return (
                            <Grid item container xs={12} className="mobile-total">
                                <Grid item xs={4} className="total-title" >
                                    <Typography>Total</Typography>
                                </Grid>
                                <Grid item xs={8} className="total-amount" >
                                    <Typography>{outputRange}</Typography>
                                </Grid>
                                <Grid item xs={12} className="disclaimer">
                                    <Typography>{element.disclaimer}</Typography>
                                </Grid>
                            </Grid>
                        )
                    }, 

                    costBreakdownBullet: () => {
                        const outputRange = renderKOrMRange(element);

                        const elementToReturn = (
                            <Box
                                display="flex"
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                    >{element.title}</Typography>
                                    <Typography>{outputRange}</Typography>
                                </Box>
                                <Box>
                                    <ArrowRightIcon />                                
                                </Box>
                            </Box>
                        )            

                        return InsertIntoGridItem(elementToReturn, section, element.key, element.scaleOverrides);
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();                
            },

            costBreakdownTitle: (element) => {

                const elementMapping = {

                    // This one has a very clear layout need
                    title: () => {
                        return (
                            <Grid
                                item
                                key={element.key}
                                xs={6}
                                md={9}
                            >
                                <Typography
                                    variant="h3"
                                >{element.title}</Typography>
                            </Grid>
                        )
                    },

                    priceRange: () => {
                        const outputRange = renderKOrMRange(element);

                        return (
                            <Grid
                                className="price-range"
                                item
                                key={element.key}
                                xs={6}
                                md={3}
                            >
                                <Typography
                                    variant="h3"
                                >{outputRange}</Typography>
                            </Grid>
                        )
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();
            },
            costBreakdownContent: (element) => {
                
                const elementMapping = {
                    mobileSummary: () => {
                        const outputRange = renderKOrMRange(element);

                        const returnedElement = (
                            <React.Fragment>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="h5"
                                            >{element.title}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="h5"
                                            >{outputRange}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography><em>{element.text}</em></Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </React.Fragment>
                        )

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    }, 

                    summary: () => {

                        const outputRange = element.property ? renderKOrMRange(element) : null;

                        const renderSummary = () => {
                            const desktopSummary = (
                                <Box className="desktop-summary">
                                    <Typography className="desktop-summary-title">{element.title}</Typography>
                                    <Typography><em>{element.text}</em></Typography>
                                </Box>
                            )
                            const mobileSummary = (
                                <Box className="mobile-summary">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} className="mobile-summary-title-container">
                                            <Typography
                                                variant="h5"
                                                className="mobile-summary-title"
                                            >{element.title}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="mobile-summary-cost-container">
                                            <Typography
                                                variant="h5"
                                                className="mobile-summary-cost"
                                            >{outputRange}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className="mobile-summary-text-container">
                                            <Typography><em>{element.text}</em></Typography>
                                        </Grid>
                                    </Grid>
                                </Box>    
                            );

                            return isMobileDevice ? mobileSummary: desktopSummary;
                        }

                        const returnedElement = renderSummary();

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    },

                    tip: () => {
                        const gridClassName = `${element.className}${isMobileDevice && '-mobile'}`;

                        const renderTip = () => {
                            const tipContent = (
                                <React.Fragment>
                                    <Typography>{element.title}</Typography>
                                    <Typography>{element.text}</Typography>
                                </React.Fragment>
                            )

                            const desktopTip = (
                                <Card className={`tip-card-desktop`}>
                                    {tipContent}
                                </Card>
                            )

                            const mobileTip = (
                                <Card className={`tip-card-mobile`}>
                                    {tipContent}
                                </Card>
                            )

                            return isMobileDevice ? mobileTip : desktopTip;
                        }

                        const returnedElement = renderTip();

                        return (
                            <Grid 
                                className={gridClassName}
                                item
                                xs={6}
                                md={3}
                            >{returnedElement}</Grid>
                        )    
                    },

                    priceRange: () => {
                        const outputRange = renderKOrMRange(element);

                        const returnedElement = (
                            <Typography>{outputRange}</Typography>
                        );

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    },

                    hardwareRecap: () => {
                        const chargerCostRangeMin = collection[element.properties.totalChargerCost].min;
                        const chargerCostRangeMax = collection[element.properties.totalChargerCost].max;

                        const desktopHardwareRecap = (
                            <Box>
                                <Typography className="desktop-summary-title">{element.title}</Typography>
                                <Typography className="evse-costs-data">{collection[element.properties.type]}</Typography>
                                <Typography className="evse-costs-data">Quantity: {collection[element.properties.quantity]}</Typography>
                                <Typography className="evse-costs-data">Price per Unit: {collection[element.properties.cost]}</Typography>
                            </Box>
                        );

                        const mobileHardwareRecap = (
                            <Grid container className="mobile-hardware-recap">
                                <Grid item xs={6}>
                                    <Typography
                                        className="mobile-summary-title"
                                    >{element.title}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        className="mobile-summary-cost"
                                    >{`$${chargerCostRangeMin}k - $${chargerCostRangeMax}k`}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="evse-costs-data">{collection[element.properties.type]}</Typography>
                                    <Typography className="evse-costs-data">Quantity: {collection[element.properties.quantity]}</Typography>
                                    <Typography className="evse-costs-data">Price per Unit: {collection[element.properties.cost]}</Typography>
                                </Grid>
                            </Grid>
                        )

                        const returnedElement = isMobileDevice ? mobileHardwareRecap : desktopHardwareRecap;

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();
            },
            bottomLineBox: (element) => {
                const elementMapping = {
                    bottomLineTitleExp: () => {
                        return (
                            <Grid
                                item
                                xs={9}
                                key={element.key}
                            >
                                <Typography
                                    variant="h5"
                                >
                                    {element.title}
                                </Typography>
                                <Typography>
                                    {element.text}
                                </Typography>
                            </Grid>
                        )
                    },
                    priceRange: () => {
                        const outputRange = renderKOrMRange(element);

                        return (
                            <Grid
                                item
                                xs={3}
                                key={element.key}
                            >
                                <Typography
                                    variant="h5"
                                >
                                    {outputRange}
                                </Typography>
                            </Grid>
                        )
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler()
            }   
        }

        return (
            <Grid
                key={section.key}
                container
                justify="center"
                spacing={2}
            >
                {
                    section.elements.map((element) => {
                        const sectionTypeHandler = sectionTypeHandlers[section.type] || noOp;

                        let returnedElement = sectionTypeHandler(element);

                        if(element.desktopOnly) {
                            returnedElement = isMobileDevice ? null : returnedElement;
                        }

                        if(element.mobileOnly) {
                            returnedElement = isMobileDevice ? returnedElement : null;
                        }

                        return returnedElement;
                    })
                }
            </Grid>
        )
    }

    const defaultRenderMethod = (data) => {
        return (
            <Box>
                {data.map((fieldSet) => {
                    // So we can see the white arrows... not the permanent approach to styling
                    const colorSet = {
                        topbar: {
                            bg: 'black',
                            fg: 'white'
                        },
                        bottomBar: {
                            bg: 'black',
                            fg: 'white'
                        }
                    }

                    const boxColors = colorSet[fieldSet.type] || {
                        bg: 'white',
                        fg: 'black'
                    }

                    let returnedBox = (
                        <Box 
                            key={fieldSet.key}
                            bgcolor={boxColors.bg}
                            color={boxColors.fg}
                        >
                            {fieldSet.sections.map((section) => {
                                return renderElementsInSection(section);
                            })}
                        </Box>
                    )

                    if(fieldSet.desktopOnly) {
                        returnedBox = isMobileDevice ? null : returnedBox;
                    }

                    if(fieldSet.mobileOnly) {
                        returnedBox = isMobileDevice ? returnedBox : null;
                    }

                    return returnedBox;
                })}
            </Box>
        )
    }

    const doRender = renderMethod || defaultRenderMethod;

    return doRender(componentMetadata)
}

export default InfrastructureCost;