import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';

/** STYLES **/
const dropdownStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const sliderStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

/** HELPERS **/
const buildMenuItems = (options, property) => {
    return options.map((option) => {
        let name, value;

        if (typeof option === 'string') {
            value = option;
            name = option;
        }

        else {
            value = option.value;
            name = option.name;
        }

        return (
            <MenuItem
                value={value}
                key={`${property}_${value}`}
            >{name}</MenuItem>
        )
    });
}

const GuidedPlanner = ({
    componentMetadata,
    collection,
    onChange,
    renderFunction = null
}) => {

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = dropdownStyles();
    const sliderClasses = sliderStyles();

    const renderInputs = (section, collectionName) => {

        const stuffIntoGridItem = (builtInput, key, scaleOverrides = {}) => {

            const numberOfInputs = section.inputs.length;
            const mdInputWidth = direction === 'row'
                ? Math.floor(12 / numberOfInputs)
                : 12
            const smInputWidth = (mdInputWidth) * 2 > 6
                ? mdInputWidth
                : mdInputWidth * 2;
            const xsInputWidth = 12;

            return (
                <Grid
                    item
                    key={key}
                    xs={scaleOverrides.xs || xsInputWidth}
                    sm={scaleOverrides.sm || smInputWidth}
                    md={scaleOverrides.md || mdInputWidth}
                >{builtInput}</Grid>
            )
        }

        const inputBuilders = {
            dropdown: (input) => {
                const handleChange = (newValue) => {
                    onChange(input.property, newValue);
                }

                const baseLabel = `${collectionName}_${input.property}`;
                const label = `dropdown_${baseLabel}`;

                const mobileClassNames = isMobileDevice && input.mobileClassNames
                    ? input.mobileClassNames
                    : '';

                const returnedDropdown = (
                    <FormControl className={`${classes.formControl} ${input.classNames || ''} ${mobileClassNames}`}>
                        <InputLabel shrink id={`${label}_label`}>
                            {input.title}
                        </InputLabel>
                        <Select
                            labelId={`${label}_label`}
                            id={label}
                            value={collection[input.property]}
                            onChange={
                                (evt) => {
                                    handleChange(evt.target.value);
                                }
                            }
                            displayEmpty
                            className={classes.selectEmpty}
                            fullWidth
                        >
                            <MenuItem value="">
                                {input.placeholder || ''}
                            </MenuItem>
                            {
                                buildMenuItems(input.options, input.property)
                            }
                        </Select>
                    </FormControl>
                );

                return stuffIntoGridItem(returnedDropdown, input.property, input.scaleOverrides)
            },
            textField: (input) => {

                const defaultProcessingRule = () => true;
                const processingRule = input.processingRule || defaultProcessingRule;

                const fieldValue = collection[input.property];

                // @TODO shouldn't be a need to duplicate these across types
                const handleChange = (newText) => {
                    if(processingRule(newText)) {
                        onChange(input.property, newText);
                    }
                }

                const returnedTextField = (
                    <TextField
                        label={input.title}
                        style={{ margin: 8 }}
                        placeholder={input.placeholder}
                        fullWidth
                        value={fieldValue}
                        onChange={
                            (evt) => {
                                handleChange && handleChange(evt.target.value);
                            }
                        }
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )

                return stuffIntoGridItem(returnedTextField, input.property, input.scaleOverrides)
            },
            slider: (input) => {

                const sliderValue = collection[input.property];

                const handleChange = (newValue) => {
                    onChange(input.property, newValue);
                }

                const returnedSlider = (
                    <FormControl className={classes.formControl}>
                        <Typography>{input.title}</Typography>
                        <Slider
                            className={sliderClasses.root}
                            value={sliderValue}
                            onChange={(evt, newValue) => {
                                handleChange(newValue);
                            }}
                            min={input.min}
                            max={input.max}
                        />
                        <Typography>{`${sliderValue} ${input.unit}`}</Typography>
                    </FormControl>
                );

                return stuffIntoGridItem(returnedSlider, input.property, input.scaleOverrides);
            },
            bool: (input) => {

                // @TODO shouldn't be a need to duplicate these across types
                const handleChange = (newValue) => {
                    onChange(input.property, newValue);
                }

                return (
                    <Grid
                        container
                        justify="center"
                        direction="row"
                        key={input.property}
                    >
                        <Grid
                            item
                            xs={6} // @TODO make overridable
                        >
                            <Typography>{input.title}</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    value={collection[input.property]}
                                    onChange={(evt) => {
                                        handleChange(evt.target.value);
                                    }}
                                >
                                    <FormControlLabel value={input.answers[0].toLowerCase()} control={<Radio />} label={input.answers[0]} />
                                    <FormControlLabel value={input.answers[1].toLowerCase()} control={<Radio />} label={input.answers[1]} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            },
            doubleDropdown: (input) => {
                const clonedInput = { ...input };
                const boundInputDescriptorArray = [];

                for (let i = 0; i < clonedInput.options.length; i++) {
                    const dropdownInputObject = {
                        options: clonedInput.options[i],
                        placeholder: clonedInput.placeholders[i],
                        type: 'dropdown',
                        property: clonedInput.properties[i],
                        scaleOverrides: clonedInput.scaleOverrides[i],
                        title: clonedInput.title[i],
                        mobileClassNames: clonedInput.mobileClasses && clonedInput.mobileClasses[i]
                    }

                    boundInputDescriptorArray.push(dropdownInputObject);
                }

                // @TODO try not to iterate over this stuff twice.
                return (
                    boundInputDescriptorArray.map((dropdown) => {
                        return (
                            inputBuilders.dropdown(dropdown)
                        )
                    })
                );
            },
            toggleTabs: (input) => {

                const handleClick = (action) => {
                    onChange(input.property, action);
                }

                const returnedButtonGroup = (
                    <React.Fragment>
                        <Typography>{input.title}</Typography>
                        <ButtonGroup
                            variant="contained"
                        >
                            {
                                input.options.map((button) => {
                                    return (
                                        <Button
                                            onClick={
                                                () => {
                                                    handleClick(button.action)
                                                }
                                            }
                                            key={button.key}
                                            className={collection[input.property] === button.action ? 'activeButton' : ''}
                                        >{button.name}</Button>
                                    )
                                })
                            }
                        </ButtonGroup>
                    </React.Fragment>
                )

                return stuffIntoGridItem(returnedButtonGroup, input.property);
            }
        }

        const direction = section.direction || 'row';

        return (
            <Grid
                container
                justify="center"
                direction={direction}
                spacing={2}
                key={section.key}
            >

                {
                    section.inputs.map((input) => {
                        const noOp = () => {
                            return input.property
                        }
                        const inputBuilder = inputBuilders[input.type] || noOp;
                        return inputBuilder(input)
                    })
                }

            </Grid>
        )
    }

    const renderSections = (fieldGroup) => {
        return (
            <Box>
                {
                    fieldGroup.sections.map((section) => {
                        return (
                            renderInputs(section, fieldGroup.collection)
                        );

                    })
                }
            </Box>
        )
    }

    const defaultRenderFunction = (inputData) => {
        if (!inputData) {
            return null;
        }

        const targetInputFieldset = inputData;

        return (
            <Box>
                {
                    targetInputFieldset.fieldGroups.map((fieldGroup) => {
                        return (
                            <Box
                                key={`${fieldGroup.title}_box`}
                                className="boxShadowGroup"
                            >
                                <Typography>
                                    <span className="fieldgroup_title">
                                        {fieldGroup.title}
                                    </span>
                                    &nbsp;
                                    <span className="fieldgroup_subtitle">
                                        {fieldGroup.subtitle}
                                    </span>
                                </Typography>
                                {renderSections(fieldGroup)}

                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    const doRender = renderFunction || defaultRenderFunction;

    return doRender(componentMetadata);
}


export default GuidedPlanner;