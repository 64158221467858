import { Grid, Box, Link, Typography, Button } from "@material-ui/core"
import { TextField } from 'mui-rff';

const validate = values => {
    const errors = {};

    if (!values.activationCode) {
        errors.activationCode = 'Required';
    }
    return errors;
};


export const UserVerificationMetadataBuilder = () => (new _UserVerificationMetadataBuilder());
class _UserVerificationMetadataBuilder {

    constructor() {
        this.inputs = {
            verificationType:'',
            verificationAttributeValue:'',
            onSubmitFunction : () => {},  
            resendFunction: () =>  {},
            errorMessage: ''       
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return (
           ( this.inputs.verificationType === 'email')?
           UserEmailVerificationMetadata(this.inputs.verificationAttributeValue,this.inputs.onSubmitFunction,this.inputs.resendFunction, this.inputs.errorMessage ):
           UserPhoneVerificationMetadata(this.inputs.verificationAttributeValue,this.inputs.onSubmitFunction,this.inputs.resendFunction )            
        )
    }
}




export const UserEmailVerificationMetadata = (emailAddress, onSubmitFunction, onClickResendEmail, errorMessage) => {
    return (
        {
            onSubmit: onSubmitFunction,
            validateFunction: validate,
            
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "verifyYourEmail",
                        className: "verify_your_email",
                        container: true,
                        alignItems: "flex-start",
                        spacing: 2
                    },
                    elements: [
                        {
                            size: 12,
                            uiComponent:Typography,
                            props: {
                                text: errorMessage,
                                className: errorMessage ? 'error-message' : 'hidden-error-message',
                                children: errorMessage
                            }
                        },

                        {
                            size: 12,
                            uiComponent: Box,
                            props: {
                                className: "headline",
                                children: <Typography className="headline_text">Enter your confirmation code.</Typography>
                            }
                        },
                        {
                            size: 12,
                            uiComponent: Box,
                            props: {
                                className: "body_text",
                                component: "p",
                                children: ['We just sent a confirmation email to ', <span className="body_text_email">{emailAddress}.</span>, <br />, 'Please enter the provided code so that we may activate your account and save your project.']
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                type: "text",
                                label: "Activation Code",
                                placeholder: "",
                                name: "activationCode"
                            }
                        },
                        {
                            size: 6,
                            uiComponent: Box,
                            props: {
                                className: "resend_notification",
                                children: (
                                    <span className="did_not_recieve_email" >
                                        Didn’t receive it? <Link aria-label="Log In" className="did_not_recieve_email_resend_link" onClick={onClickResendEmail}>Resend confirmation email</Link>
                                    </span>
                                ),
                            }
                        },
                        {
                            size: 6,
                            uiComponent: Button,
                            props: {
                                type: "submit",
                                variant: "contained",
                                children: "Done",
                            }
                        },
                    ]
                }
            ]
        }
    )

}


export const UserPhoneVerificationMetadata = (phoneNumber, onSubmitFunction, onClickResendText) => {

    return (
        {

            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "verifyYourtext",
                        className: "verify_your_text",
                        container: true,
                        alignItems: "flex-start",
                        spacing: 2
                    },
                    elements: [
                        {
                            size: 12,
                            uiComponent: Box,
                            props: {
                                className: "headline",
                                children: <Typography className="headline_text">Verify your phone number </Typography>
                            }
                        },
                        {
                            size: 12,
                            uiComponent: Box,
                            props: {
                                className: "body_text",
                                component: "p",
                                children: `We just sent a confirmation text to <span className="body_text_text">${phoneNumber}</span>. <br />Please  enter the provided code so that we may activate your account and save your project.`
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                type: "text",
                                label: "Activation Code",
                                placeholder: "",
                                required: true,
                                name: "activationCode"
                            }
                        },
                        {
                            size: 6,
                            uiComponent: Box,
                            props: {
                                className: "resend_notification",
                                children: (
                                    <span className="did_not_recieve_text" >
                                        Didn’t receive it? <Link aria-label="Did not recieve notification" className="did_not_recieve_text_resend_link" onClick={onClickResendText}>Resend confirmation text</Link>
                                    </span>
                                ),
                            }
                        },
                        {
                            size: 6,
                            uiComponent: Button,
                            props: {
                                className: "done_button",
                                children: "Done",
                                onClick: () => {
                                    onSubmitFunction()
                                }
                            }
                        },
                    ]
                }
            ]
        }
    )

}