import React, { useState } from 'react';
import { Button, Grid, Paper, Box, Typography, TextField } from '@material-ui/core';
import { useGetUtilitiesQuery } from '../../services/unifiedAPIQuery';
import { spinner } from '../../assets/images';
import PageHeader from '../PageHeader';
import PageTitle, {LoadingPageTitle } from '../PageTitle';

const UtilityCards = ({ zip, data }) => {
    return (
        <>
            {
                data.suppliers.map((supplier) => {

                    let evPageURL = supplier.ev_page_url;
                    if(supplier.name === "SMUD" && supplier.ev_page_url === "https://www.smud.org/en/Going-Green/Electric-Vehicles/Residential") {
                        evPageURL = "https://www.smud.org/en/Going-Green/Electric-Vehicles/Business";
                    }

                    return (
                        <React.Fragment key={supplier.id}>
                            <Paper className="supplier_content" >
                                <Box className="supplier_content_image_div">
                                    <img
                                        className="supplier_logo"
                                        component="img"
                                        src={supplier.logo_url}
                                        title="supplier_logo"
                                        alt="supplier logo"
                                    />
                                </Box>

                                <Box className="supplier_content_text_div">
                                    <Typography className="supplier_content_text">The utility for <u>{zip}</u> is <br /><u><strong>{supplier.long_name}</strong></u>.</Typography>
                                </Box>
                                <Box className="supplier_content_button_div">
                                    <Button target="_blank" href={evPageURL} rel="noopener" className="supplier_content_button">
                                        Learn more
                                    </Button>
                                </Box>
                            </Paper>
                        </React.Fragment>
                    )
                })
            }
        </>
    )
}




const UtilityLookupWrapper = () => {

    const [skip, setSkip] = React.useState(true)

    const [postcodeInput, setPostcodeInput] = useState('');
    const [postcode, setPostcode] = useState('');

    function postCodeInputOnChangeHandler(event) {
        if (!isNaN(event.target.value) && event.target.value.length < 6) setPostcodeInput(event.target.value)
    }

    function onPostcodeSubmitClick() {
        setSkip(false)
        if (!isNaN(postcodeInput)) setPostcode(postcodeInput)
    }

    const { data, error, isLoading, isUninitialized } = useGetUtilitiesQuery(
        { postcode: postcode },
        { skip }
    )


    return (
        <>
            <PageHeader title="Utility Lookup" tagline="Talking to your utility early and often is key to any successful infrastructure project. See which utility services your area by entering your zip code." className="utility_lookup_header" />
            <Grid className="utility_lookup_grid" container spacing={3} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6} md={2} >
                    <Box className='utility_text_field_box'>
                        <TextField
                            className='utility_text_field'
                            value={postcodeInput}
                            onChange={(event) => postCodeInputOnChangeHandler(event)}
                            variant="outlined"
                            placeholder="Enter ZIP Code"
                            error={isNaN(postcodeInput)}
                            autoFocus
                            onKeyUp={(event) => { if (event.key === 'Enter') onPostcodeSubmitClick() }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} md={2} >
                    <Button className='utlity_submit_button' variant="contained" size='large' onClick={() => onPostcodeSubmitClick()}>Search</Button>
                </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} xl={5}>
                    {error ? (
                        <>Oh no, there was an error</>
                    ) : isUninitialized ? (
                        <></>
                    ) : isLoading ? (
                        <React.Fragment>
                            <LoadingPageTitle />
                            <center><img src={spinner} alt="waiting"/></center>
                        </React.Fragment>
                    ) : data ? (
                        <React.Fragment>
                            <PageTitle page="UtilityLookup" />
                            <UtilityCards data={data} zip={postcode} />
                        </React.Fragment>
                    ) : null}
                </Grid>
            </Grid>
        </>
    )
}

export default UtilityLookupWrapper;