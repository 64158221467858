import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import bangIcon from '../assets/images/bangIcon.png';

const alertContent = {
  image: bangIcon,
  title: 'Please design the infrastructure first',
  text: 'Prior to calculating the cost or the workplan for the infrastructure, please define the infrastructure in the Design page.',
  buttonText: "Design Page"
}

const AlertDialog = ({ page }) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(true);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <img src={bangIcon} className="alert-dialog-bang-icon" alt="alert icon"/>
        <DialogTitle id="alert-dialog-title">{alertContent.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>{alertContent.text}</>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <NavLink to={page} color="primary" autoFocus>
            {alertContent.buttonText}
          </NavLink>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;