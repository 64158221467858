import { utils, data, calculations } from '../cap';

const recommendedChargerCalculations = (collection) => {
    let requiredPlugCount, requiredPlugCapacity;

    const { vehicleArchetypes } = data;
    const targetVehicle = utils.sourceDataFilter(vehicleArchetypes, (val) => {
        return val.id === collection.vehicleType
    })[0];

    if (!targetVehicle) {
        return;
    }

    const dailyKWHPerVehicle = targetVehicle.kwhPerMile;
    const onRoute = collection.stationType !== 'depot';


    if (onRoute) {
        const { onRoutePlugCount, plugKilowattRequiredCapacityOnRoute } = calculations;

        const onRouteOpens = utils.timeStringToNumber(collection.timeAvailableStartOnRoute);
        const onRouteCloses = utils.timeStringToNumber(collection.timeAvailableFinishOnRoute);

        requiredPlugCount = onRoutePlugCount(collection.onRouteVehiclesPerDay, collection.timeAtOnRouteStation, onRouteOpens, onRouteCloses);
        requiredPlugCapacity = plugKilowattRequiredCapacityOnRoute(dailyKWHPerVehicle, collection.additionalMilesPerCharge, collection.timeAtOnRouteStation);
    } else {
        const { plugKilowattRequiredCapacityAtDepot, depotPlugCount } = calculations;

        const depotOpens = utils.timeStringToNumber(collection.timeAvailableStart);
        const depotCloses = utils.timeStringToNumber(collection.timeAvailableFinish);
        const vehicleCount = collection.numVehicles;
        const dailyMilesPerVehicle = collection.depotMilesPerDayPerVehicle;

        requiredPlugCount = depotPlugCount(vehicleCount);
        requiredPlugCapacity = plugKilowattRequiredCapacityAtDepot(vehicleCount, dailyKWHPerVehicle, dailyMilesPerVehicle, depotOpens, depotCloses);

    }
    const wireless = collection.wirelessInterest === 'yes';
    const overhead = collection.overheadInterest === 'yes';

    return calculations.getChargerRecommendation(onRoute, requiredPlugCapacity, requiredPlugCount, { wireless, overhead })
}

export default recommendedChargerCalculations;