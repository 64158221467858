import { Grid, Typography } from "@material-ui/core";

export const PasswordChangeSuccessMetadataBuilder = () => (new _PasswordChangeSuccessMetadataBuilder());
class _PasswordChangeSuccessMetadataBuilder {

    constructor() {
        this.inputs = {
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return PasswordChangeSuccessMetadata();
    }
}

export const PasswordChangeSuccessMetadata = () => {

    return (
        {
            onSubmit: ()=>{},
            validateFunction: ()=>{},

            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "newPasswordDialog",
                        className: "new_pasword_dialog",
                        container: true,
                        alignItems: "center",
                        spacing: 2
                    },

                    elements: [

                        {
                            size: 12,
                            uiComponent: Typography,
                            props: {
                                children: "Password changed successfully",
                                className: "headline"
                            }
                        }
                    ]
                }
            ]
        }

    )
}
