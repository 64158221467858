import React from 'react';

// @TODO make this FuelOptionsEVData?
const ElectricVehicle = (intl) => (
    {
        title: 'Electric Vehicle Basics',
        sections: [
            {
                title: 'Introduction to electric vehicles',
                sections: [
                    {
                        type: 'paragraph',
                        text: 'Electric vehicles derive their power from an onboard battery which is recharged in a variety of manners. Medium- and heavy-duty vehicle battery sizes vary as will their expected range. Environmental factors such as ambient temperature and route terrain can impact expected range. It is important to have an understanding of your duty cycle.'
                    }
                ]
            },
            {
                title: 'Charging options for commercial fleets',
                sections: [
                    {
                        type: 'paragraph',
                        text: 'Charging your commercial EV fleet is accomplished by the use of Electric Vehicle Supply Equipment (EVSE). Currently, there are 4 main types of vehicle chargers.'
                    },
                    {
                        type: 'cards',
                        title: 'Charger Types',
                        cards: [
                            {
                                img: "level2Wired",
                                cardTitle: 'Level 2 - Wired',
                                text: 'Low to moderate power, best for extended charging duration and/or short distance duty-cycle. Best for fleet operators with reliable access to overnight charging and 240-volt electrical service.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'Level 2 - Wired',
                                    subtitle: 'An Overview',
                                    text: 'Level 2 car chargers need a 208-240 Volt, 40 Amp circuit, and are capable of delivering more power (3 kW to 19 kW AC) to more rapidly charge a vehicle. They typically attach to a pedestal or wall mount and require electrician installation, unless they use a plug attached to an existing 240V outlet.',
                                    img: 'level2WiredLearnMore',
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            },
                            {
                                img: "dcfcWired",
                                cardTitle: 'DCFC - Wired',
                                text: 'Moderate to high power, best for vehicles with large batteries that need quick refueling. Best for fleet operators with on-site access to 480-volt electrical service.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'DCFC - Wired',
                                    subtitle: 'An Overview',
                                    text: 'Direct Current Fast Chargers (DCFC), also known as Level 3 chargers, operate at 400 – 1000V, 3-phase power to achieve a 50 - 350 kW charge rate. With this power, they can typically charge a vehicle around 80% in less than an hour. Maximum charger rates are limited by a vehicle’s onboard DC charger. For instance, if the EV has a 50kW acceptance rate and a 100kW DCFC is connected, the EV will only charge at 50kW.',
                                    img: 'dcfcWiredLearnMore',
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            },
                            {
                                img: "wireless",
                                cardTitle: 'Wireless',
                                text: 'Wireless is the most nascent of the four charging technologies. It uses an inductive technology and does not require a plug. Wireless charging is ideal for fleets on the move related to transit, shuttle services, and warehouse and distribution centers.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'Wireless',
                                    subtitle: 'An Overview',
                                    text: ['Wireless charging for EVs is relatively-new technology with unique advantages and challenges.',
                                        <br />, <br />,
                                        'Advantages include contact-free charging and more secure charger connections, since charging can be controlled both digitally and physically to support only authorized vehicles.',
                                        <br />, <br />,
                                        'However, wireless charging is on average much less efficient than conventional wired or “conductive” charging, given the physical limitations of induction. This lower efficiency leads to lower and less consistent charging rates and higher costs. It also requires additional charging parts on the receiving vehicle, and requires precise alignment of the vehicle charging pad to the charger itself.'],
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            },
                            {
                                img: "overhead",
                                cardTitle: 'Overhead',
                                text: 'Requires precise vehicle parking, often convenient for short stops and/or transit entities. Often retracts, and may be expanded to service multiple vehicles.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'Overhead',
                                    subtitle: 'An Overview',
                                    text: 'The term overhead refers to the installation method of a charger, rather than a unique charger technology. Overhead chargers are similar to traditional plug chargers, but are suspended up high above vehicles to be charged, enabling more space through what are usually retractable cables.',
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Why choose electric?',
                sections: [
                    {
                        type: 'cards',
                        title: '',
                        cards: [
                            {
                                img: "environmentalImpact",
                                cardTitle: 'Significant Positive Environmental Impact',
                                text: 'Medium- and heavy-duty vehicle options have grown and currently represent the majority of Zero-Emission Vehicle (ZEV) options. They offer a demonstrable, large reduction in wheel-to-well emissions, with instant removal of tailpipe emissions and reduced emissions over time from savings in generation and distribution of energy. Furthermore, electric vehicles continue to become more environmentally advantageous as the grid improves.',
                                className: 'tall-info-card'
                            },
                            {
                                img: "lowerCostOwnership",
                                cardTitle: 'Lower Total Cost of Ownership',
                                text: 'Lower maintenance, since EVs have fewer moving parts that can break compared with traditional engines and don’t require oil changes. Lower operating costs from charging instead of fueling, and federal, state, local, and utility incentives to offset costs.',
                                className: 'tall-info-card'
                            },
                            {
                                img: "techMaturity",
                                cardTitle: 'Technological Maturity',
                                text: 'Vehicle electrification has become mainstream, with increasing market options for medium and heavy-duty vehicles with greater torque, greater reliability, simpler maintenance, and lower operating noise.',
                                className: 'tall-info-card'
                            },
                        ]
                    }

                ]
            },
            {
                title: 'What to look out for...',
                sections: [
                    {
                        type: 'cards',
                        title: '',
                        cards: [
                            {
                                img: "demandCharges",
                                cardTitle: 'Demand Charges',
                                text: 'Demand charges are fees applied to the electric bills of commercial and industrial customers based upon the highest amount of power drawn during any interval during the billing period. These can amount to large fractions of electric bills. Minimizing peak demand by shifting EV charging to times when rates are lower can help reduce recharging costs.',
                                className: 'tall-info-card'
                            },
                            {
                                img: "utilityUpgrades",
                                cardTitle: 'Utility Upgrades',
                                text: 'Infrastructure upgrades on the utility side may be necessary before moving forward with your electrification project, due to the loads added by the new electric charging equipment. For a smoother transition, work with your local utility early and often to keep them informed of your project planning, timeline, and decisions.',
                                className: 'tall-info-card'
                            },
                            {
                                img: "subSurfaceUpgrades",
                                cardTitle: 'Sub-surface Upgrades',
                                text: 'Future-proof the site by making sub-surface infrastructure modifications once to avoid digging for each subsequent infrastructure project.',
                                className: 'tall-info-card'
                            }
                        ]
                    }

                ]
            }

        ]
    });

const H2Vehicle = (intl) => (
    {
        title: 'Hydrogen Fuel Cell Vehicle Basics',
        sections: [
            {
                title: 'Introduction to hydrogen fuel cell vehicles',
                sections: [
                    {
                        type: 'paragraph',
                        text: 'Fuel cell vehicles powered by hydrogen produce zero emissions and are more efficient than conventional internal combustion engines. Fuel cells produce electricity using a proton exchange membrane to generate onboard rather than relying solely on a battery. There are plans to expand hydrogen fuel cell inventory as the technology matures and infrastructure continues to grow.'
                    }
                ]
            },
            {
                title: 'Refueling options for hydrogen fuel cell vehicles',
                sections: [
                    {
                        type: 'paragraph',
                        text: 'Hydrogen dispensing stations have been designed to operate similar to a gasoline or diesel pump at a filling station.'
                    },
                    {
                        type: 'cards',
                        title: 'Dispensing type',
                        cards: [
                            {
                                img: 'tubeTrailer',
                                cardTitle: 'Tube Trailer',
                                text: 'Most commonly used for temporary pilot projects, gaseous tube trailers can come with a dispensing station mounted on the rear.'
                            },
                            {
                                img: 'permanentStation',
                                cardTitle: 'Permanent Dispensing Station',
                                text: 'Includes compression, storage and dispensing equipment that accepts gaseous or liquid hydrogen produced on-site or via delivery on a gaseous or liquid tube trailer.'
                            }
                        ]
                    },
                    {
                        type: 'cards',
                        title: 'Hydrogen procurement options',
                        cards: [
                            {
                                img: 'gasTubeTrailer',
                                cardTitle: 'Gaseous Delivery',
                                text: 'Via tube trailer.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'Gaseous Delivery',
                                    subtitle: 'An Overview',
                                    text: 'Level 2 car chargers need a 208-240 Volt, 40 Amp circuit, and are capable of delivering more power (3 kW to 19 kW AC) to more rapidly charge a vehicle. They typically attach to a pedestal or wall mount and require electrician installation, unless they use a plug attached to an existing 240V outlet.',
                                    img: 'gaseousDeliveryLearnMore',
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            },
                            {
                                img: 'liquidTubeTrailer',
                                cardTitle: 'Liquid Delivery',
                                text: 'Via tube trailer.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'Liquid Delivery',
                                    subtitle: 'An Overview',
                                    text: ['In the absence of hydrogen delivery pipelines, liquid delivery is the most common and practical way to transport large volumes of hydrogen. Though liquification requires very low temperatures of −253°C (−423°F) and significant amonts of energy (some 30% of the energy content of the fuel to be transported), it is still more economical than gaseous delivery for transporting large hydrogen volumes, since more mass of hydrogen is delivered. Additional losses are incurred in the re-gassification of the hydrogen.',
                                        <br />, <br />,
                                        'Liquid hydrogen arrives in super-insulated, cryogenic tanker trucks.'],
                                    img: 'liquidDeliveryLearnMore',
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            },
                            {
                                img: 'electrolysis',
                                cardTitle: 'On-site Electrolysis',
                                text: 'Best for sites with cost-effective utility rates.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'On-site electrolysis',
                                    subtitle: 'An Overview',
                                    text: ['Electrolysis is the process of using electricity to split water into hydrogen and oxygen in a chamber known as an electrolyzer.',
                                        <br />, <br />,

                                        'Of four main methods of producing (rather than just delivering) hydrogen, electrolysis is the least common and most expensive, but still plays an important role in special applications requiring smaller hydrogen volumes, or having access to limited production equipment and space.',
                                        <br />, <br />,
                                        'There are three types of electrolyzers: polymer electrolyte membrane (PEM) electrolyzer, alkaline, and solid oxide, each using different electrolyte materials appropriate for different applications.'
                                    ],
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            },
                            {
                                img: 'steamMethaneReforming',
                                cardTitle: 'On-site Steam Methane Reforming',
                                text: 'Requires access to natural gas.',
                                learnMore: {
                                    buttonText: 'Learn More',
                                    title: 'On-site steam methane reforming',
                                    subtitle: 'An Overview',
                                    text: ['Of four main methods of producing (rather than just delivering) hydrogen, methane reforming is the most common at large scale, producing most of the world’s hydrogen. A reactor uses high heat and pressure to convert incoming methane into carbon monoxide and hydrogen gas, later separated.',
                                        <br />, <br />,

                                        'Though efficiencies peak at about 75% for today’s available systems, steam methane reforming is a practical way to produce large volumes of hydrogen gas when methane is readily-available, as the natural gas is an ideal feedstock and source of hydrogen.',
                                        <br />, <br />,
                                        'While large-scale methane reforming is the least costly form of hydrogen production, costs increase greatly when using smaller, on-site reactors, making other options competitive.'
                                    ],
                                    exploreLinkURL: 'equipment_catalog',
                                    exploreLinkText: 'See Equipment Catalog'
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Why choose hydrogen?',
                sections: [
                    {
                        type: 'cards',
                        title: '',
                        cards: [
                            {
                                img: 'longerRange',
                                cardTitle: 'Longer Range',
                                text: ['Due to weight constraints, the power density of a hydrogen storage tank is greater than the latest battery technology. This allows for hydrogen fuel cell vehicles to add more energy than battery electric vehicles. Some fuel cell vehicles can get over 300 miles on one tank of hydrogen fuel —  greater than the distance from San Diego to Santa Barbara — and fuel economy close to 70 MPGe (miles per gasoline gallon equivalent).', <br />, <br />, <a href="https://www.epa.gov/greenvehicles/hydrogen-fuel-cell-vehicles" target="_blank" noopener noreferrer rel="noreferrer">Learn More</a>, <br />, <br />]
                            },
                            {
                                img: 'fasterRefueling',
                                cardTitle: 'Faster Refueling',
                                text: 'Most hydrogen vehicles can be refueled within ten minutes if refueling at a high pressure/high volume rate. If overnight refueling is preferred, low pressure/low volume can be utilized. CALSTART currently only covers high-pressure hydrogen installations.'
                            }
                        ]
                    }
                ]
            },
            {
                title: 'What to look out for...',
                sections: [
                    {
                        type: 'cards',
                        title: '',
                        cards: [
                            {
                                img: 'scarcity',
                                cardTitle: 'Viability',
                                text: 'Hydrogen is seeking to increase the availability of commercial vehicles and achieve a price point that will attract new consumers.'
                            },
                            {
                                img: 'viability',
                                cardTitle: 'Challenging',
                                text: 'Space, access to utilities, community support (or lack thereof) could make on-site refueling challenging. As the market matures there may be more investments in hydrogen fuel cells from the public and private sector, making them competitive with their electric counterparts.'
                            },
                            {
                                img: 'highlyRegulated',
                                cardTitle: 'Highly Regulated',
                                text: 'Certified training is required for the general contractor and their staff. The American Society of Mechanical Engineers (ASME), the agency having jurisdiction over all equipment, tools, and training, also regulates all of the components required to build a hydrogen refueling station.'
                            },
                            {
                                img: 'upfrontCosts',
                                cardTitle: 'Upfront Costs',
                                text: 'To help reduce the cost burden, federal and local funding is available to those who are interested in purchasing the required equipment to build a hydrogen refueling station.'
                            },

                        ]
                    }
                ]
            }
        ]
    })

export {
    ElectricVehicle,
    H2Vehicle
}
