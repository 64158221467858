import React, {useState} from 'react';
import { Grid, Paper } from "@material-ui/core"
import { Form } from 'react-final-form';

export { LoginMetadata, LoginMetadataBuilder } from './LoginMetadata';
export { SignUpMetadata, SignUpMetadataBuilder } from './SignUpMetadata';
export { UserVerificationMetadataBuilder,UserEmailVerificationMetadata, UserPhoneVerificationMetadata } from './UserVerificationMetadata';
export { ForgotPasswordMetadata,ForgotPasswordMetadataBuilder } from './ForgotPasswordMetadata';
export { NewPasswordMetadata, NewPasswordMetadataBuilder } from './NewPasswordMetadata'
export { PasswordChangeSuccessMetadata, PasswordChangeSuccessMetadataBuilder } from './PasswordChangeSuccessMetadata';
export { AccountRecoveryMetadata, AccountRecoveryMetadataBuilder } from './AccountRecoveryMetadata';

const UserAuthentication = (
    {
        className,
        componentMetadata,
        onSuccess = () => { },
        renderFunction = null,
        closeButton = null
    }
) => {

    const [errors, setErrors] = useState({});

    const onSubmit = async (values, validationFlags) => {
        if(Object.keys(validationFlags).length > 0) {
            setErrors(validationFlags);
        } else {
            setErrors({});
            componentMetadata.onSubmit(values);
            onSuccess();
        }   
    };

    const renderSections = (sections) => {
        const renderedSection = sections.map(item => {
            const elements = [];

            if ('sections' in item) elements.push(renderSections(item.sections));
            else if ('elements' in item) {
                item.elements.forEach((element, index) => {
                    elements.push(renderElement(element, index))
                });
            }

            item.props["children"] = elements;

            return React.createElement(
                item.uiComponent, item.props);
        });

        return renderedSection;
    }

    const renderElement = (element, idx) => {

        if(errors[element.props.name]) {
            element.props.error = true;
            element.props.helperText = errors[element.props.name];
        }

        return (
            <Grid item xs={element.size} key={idx}>
                {React.createElement(element.uiComponent, element.props)}
            </Grid>
        );
    }

    const defaultRenderFunction = (reactArrayofSections) => {
        return (
            <div className={className} style={{ padding: 16, margin: 'auto', maxWidth: 600 }}>
                <Form
                    onSubmit={(values) => onSubmit(values, componentMetadata.validateFunction(values))}
                    initialValues={componentMetadata.initialValues}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        return(

                        <form onSubmit={handleSubmit} >
                            <Paper style={{ padding: 16, position: 'relative' }}>
                                {closeButton}
                                {reactArrayofSections}
                            </Paper>
                        </form>
                    )}}
                />
            </div>
        )
    }

    const doRender = renderFunction || defaultRenderFunction;
    return doRender(renderSections(componentMetadata.sections));
}



export default UserAuthentication;
