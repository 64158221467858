import { vehicleArchetypes } from "../data";

const VehicleTypes = vehicleArchetypes.map((vehicle) => {
  return {
    name: vehicle.description,
    value: vehicle.id,
  };
});

const answers = ["Yes", "No"];
const TimesOfDay = [];
const SiteInputs = {
  title: "My Site",
  subtitle: "",
  collection: "site",
  sections: [
    {
      key: "site_section_0",
      inputs: [
        {
          property: "siteAddress",
          type: "textField",
          placeholder: "Enter an address",
          title: "Site address",
          scaleOverrides: {
            md: 9,
          },
        },
        {
          property: "zip",
          type: "textField",
          placeholder: "ZIP",
          title: "Zip code",
          processingRule: (newText) => {
            return !isNaN(newText) && newText.length < 6;
          },
          scaleOverrides: {
            md: 3,
          },
        },
      ],
    },
    {
      key: "site_section_1",
      inputs: [
        {
          property: "stationSize",
          classNames: "station-size-dropdown",
          type: "dropdown",
          options: [
            {
              name: "Less than 1/8 acre",
              value: "small",
            },
            {
              name: "1/8 - 1/4 acre",
              value: "mid",
            },
            {
              name: "More than 1/4 acre",
              value: "large",
            },
          ],
          title: "How much land is available for this project?",
          placeholder: "",
          scaleOverrides: {
            md: 6,
          },
        },
      ],
    },
    {
      key: "site_section_2",
      direction: "column",
      inputs: [
        {
          property: "cleanWater",
          title: "Does your site have access to clean water?",
          tooltip: "TBD",
          type: "bool",
          answers,
        },
        {
          property: "cheapElectricity",
          title: "Does your site have access to electricity?",
          tooltip: "TBD",
          type: "bool",
          answers,
        },
        {
          property: "naturalGas",
          title: "Does your site have access to natural gas?",
          tooltip: "TBD",
          type: "bool",
          answers,
        },
        {
          property: "overheadInterest",
          title: "Are you interested in overhead charging?",
          tooltip: "TBD",
          type: "bool",
          answers,
        },
        {
          property: "wirelessInterest",
          title: "Are you interested in wireless charging?",
          tooltip: "TBD",
          type: "bool",
          answers,
        },
      ],
    },
  ],
};
const StationType = {
  title: "",
  collection: "stationType",
  sections: [
    {
      key: "station_type_section_0",
      collection: "stationType",
      inputs: [
        {
          property: "stationType",
          type: "toggleTabs",
          options: [
            { name: "Depot", action: "depot", key: "stationtype_depot" },
            {
              name: "On-Route",
              action: "onRoute",
              key: "stationtype_on_route",
            },
          ],
          default: "Depot",
          title: "Station Type",
          tooltip: "TBD",
        },
      ],
    },
  ],
};

let i = 0;

while (i < 24) {
  TimesOfDay.push(`${i}:00`);
  TimesOfDay.push(`${i}:30`);
  i++;
}

const TimesOfDayOptions = TimesOfDay.map((time) => {
  const hour = parseInt(time.split(":")[0]);
  const minute = time.split(":")[1];
  const civilianHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

  const amPm = hour < 12 ? "am" : "pm";
  return {
    value: time,
    name: `${civilianHour}:${minute} ${amPm}`,
  };
});

export { VehicleTypes, TimesOfDay, TimesOfDayOptions, SiteInputs, StationType };
