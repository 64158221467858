import { YES, NO } from '../data/constants'

const chillers = [
  {
    "make": "Mydax",
    "model": "CryoDax 8",
    "model_variant": "",
    "cooling_capacity_kW": 5.7,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 8.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 8.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 8.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 12",
    "model_variant": "",
    "cooling_capacity_kW": 9,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 12.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 12.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 12.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 12X",
    "model_variant": "",
    "cooling_capacity_kW": 9,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 12X.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 12X.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 12X.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 16",
    "model_variant": "",
    "cooling_capacity_kW": 13.1,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 16.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 16.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 16.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 20",
    "model_variant": "",
    "cooling_capacity_kW": 15,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 20.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 20.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 20.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 20X",
    "model_variant": "",
    "cooling_capacity_kW": 15,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 20.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 20.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 20.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 25",
    "model_variant": "",
    "cooling_capacity_kW": 17.3,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 20.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 20.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 20.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 30",
    "model_variant": "",
    "cooling_capacity_kW": 25,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 30.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 30.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 30.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 30X",
    "model_variant": "",
    "cooling_capacity_kW": 25,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 30.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 30.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 30.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 40",
    "model_variant": "",
    "cooling_capacity_kW": 30.2,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 40.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 40.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 40.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 50",
    "model_variant": "",
    "cooling_capacity_kW": 34.6,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 50.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 50.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 50.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 60",
    "model_variant": "",
    "cooling_capacity_kW": 50,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 60.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 60.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 60.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 60X",
    "model_variant": "",
    "cooling_capacity_kW": 50,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 60.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 60.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 60.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 90",
    "model_variant": "",
    "cooling_capacity_kW": 75,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 90.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 90.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 90.png"
  },
  {
    "make": "Mydax",
    "model": "CryoDax 120",
    "model_variant": "",
    "cooling_capacity_kW": 100,
    "product_info_page": "https://mydax.com/liquid-chiller-products/cryodax-low-temp-chillers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Mydax__CryoDax 90.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Mydax__CryoDax 90.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Mydax__CryoDax 90.png"
  },
  {
    "make": "Orion Kikai",
    "model": "PEC10000A-WL",
    "model_variant": "",
    "cooling_capacity_kW": 10,
    "product_info_page": "https://www.orionkikai.co.jp/english/product/chiller/pec/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Orion Kikai_PEC10000A-WL.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Orion Kikai_PEC10000A-WL.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Orion Kikai_PEC10000A-WL.png"
  },
  {
    "make": "Orion Kikai",
    "model": "PEC22000A-WL",
    "model_variant": "",
    "cooling_capacity_kW": 22,
    "product_info_page": "https://www.orionkikai.co.jp/english/product/chiller/pec/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Orion Kikai_PEC10000A-WL.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Orion Kikai_PEC10000A-WL.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Orion Kikai_PEC10000A-WL.png"
  },
  {
    "make": "Orion Kikai",
    "model": "PEC40000A-WL",
    "model_variant": "",
    "cooling_capacity_kW": 44,
    "product_info_page": "https://www.orionkikai.co.jp/english/product/chiller/pec/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Chillers/Orion Kikai_PEC10000A-WL.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Chillers/Orion Kikai_PEC10000A-WL.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Chillers/Orion Kikai_PEC10000A-WL.png"
  }
];

const compressors = [
  {
    "make": "Howden",
    "model": "Reciprocating - API 618",
    "model_variant": "C series - ThomassenTM",
    "flow_rate_m3_hour": null,
    "pressure_PSI": 8702,
    "power_KW": 24800,
    "info_page": "https://www.howden.com/en-us/products/compressors",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Compressors/Howden_Reciprocating - API 618.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Compressors/Howden_Reciprocating - API 618.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Compressors/Howden_Reciprocating - API 618.png"
  },
  {
    "make": "Howden",
    "model": "Reciprocating - API 618",
    "model_variant": "P series - Burton CorblinTM",
    "flow_rate_m3_hour": 22000,
    "pressure_PSI": 5100,
    "power_KW": 33000,
    "info_page": "https://www.howden.com/en-us/products/compressors",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Compressors/Howden_Reciprocating - API 618 2.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Compressors/Howden_Reciprocating - API 618 2.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Compressors/Howden_Reciprocating - API 618 2.png"
  },
  {
    "make": "Howden",
    "model": "Diaphragm Compressor",
    "model_variant": "D Series",
    "flow_rate_m3_hour": 1200,
    "pressure_PSI": 43511,
    "power_KW": null,
    "info_page": "https://www.howden.com/en-us/products/compressors",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Compressors/Howden_Diaphragm Compressor.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Compressors/Howden_Diaphragm Compressor.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Compressors/Howden_Diaphragm Compressor.png"
  },
  {
    "make": "Howden",
    "model": "Hybrid",
    "model_variant": "H Series",
    "flow_rate_m3_hour": 1200,
    "pressure_PSI": 43511,
    "power_KW": null,
    "info_page": "https://www.howden.com/en-us/products/compressors",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Compressors/Howden_Hybrid.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Compressors/Howden_Hybrid.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Compressors/Howden_Hybrid.png"
  },
  {
    "make": "Kobe Steel",
    "model": "HyAC",
    "model_variant": "HYL30 (HyAC low stage compressor)\t",
    "flow_rate_m3_hour": 300,
    "pressure_PSI": 6527,
    "power_KW": 110,
    "info_page": "https://www.kobelco.co.jp/english/products/rotating_machinery/hyac/index.html",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Compressors/Kobe Steel_HyAC.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Compressors/Kobe Steel_HyAC.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Compressors/Kobe Steel_HyAC.png"
  },
  {
    "make": "Kobe Steel",
    "model": "HyAC",
    "model_variant": "HYH30 (HyAC high stage compressor)",
    "flow_rate_m3_hour": 1200,
    "pressure_PSI": 12691,
    "power_KW": 110,
    "info_page": "https://www.kobelco.co.jp/english/products/rotating_machinery/hyac/index.html",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Compressors/Kobe Steel_HyAC.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Compressors/Kobe Steel_HyAC.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Compressors/Kobe Steel_HyAC.png"
  },
  {
    "make": "Kobe Steel",
    "model": "HyACmini",
    "model_variant": "",
    "flow_rate_m3_hour": 340,
    "pressure_PSI": 12691,
    "power_KW": 110,
    "info_page": "https://www.kobelco.co.jp/english/products/rotating_machinery/hyac/index.html",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Compressors/Kobe Steel_HyACmini.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Compressors/Kobe Steel_HyACmini.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Compressors/Kobe Steel_HyACmini.png"
  }
];

const dispensers = [
  {
    "make": "Air Products",
    "model": "SmartFuel H70_H35",
    "model_variant": "",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://www.airproducts.com/equipment/retail-hydrogen-dispenser",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Air Products_SmartFuel H70_H35.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Air Products_SmartFuel H70_H35.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Air Products_SmartFuel H70_H35.png"
  },
  {
    "make": "Air Products",
    "model": "SmartFuel Mobile Fueler",
    "model_variant": "",
    "_70MPa_dispenser": NO,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://www.airproducts.com/services/portable-hydrogen-fueler",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Air Products_SmartFuel Mobile Fueler.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Air Products_SmartFuel Mobile Fueler.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Air Products_SmartFuel Mobile Fueler.png"
  },
  {
    "make": "Cummins",
    "model": "HySTAT™ Stations",
    "model_variant": "",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://www.cummins.com/new-power/technology/hydrogen-generation/fueling-stations",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Cummins_HySTAT™ Stations.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Cummins_HySTAT™ Stations.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Cummins_HySTAT™ Stations.png"
  },
  {
    "make": "Nel",
    "model": "HS004",
    "model_variant": "",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://nelhydrogen.com/product/h2station/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Nel_HS004.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Nel_HS004.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Nel_HS004.png"
  },
  {
    "make": "Nel",
    "model": "HS005",
    "model_variant": "",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": NO,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://nelhydrogen.com/product/h2station/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Nel_HS004.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Nel_HS004.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Nel_HS004.png"
  },
  {
    "make": "Nel",
    "model": "HSABB",
    "model_variant": "",
    "_70MPa_dispenser": NO,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://nelhydrogen.com/product/h2station/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Nel_HS004.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Nel_HS004.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Nel_HS004.png"
  },
  {
    "make": "Nel",
    "model": "HSAAA",
    "model_variant": "",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://nelhydrogen.com/product/h2station/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Nel_HS004.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Nel_HS004.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Nel_HS004.png"
  },
  {
    "make": "Nel",
    "model": "70 MPa US",
    "model_variant": "",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": NO,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://nelhydrogen.com/product/car-dispenser-di001/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Nel_35 MPa US.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Nel_35 MPa US.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Nel_35 MPa US.png"
  },
  {
    "make": "Nel",
    "model": "35 MPa US",
    "model_variant": "",
    "_70MPa_dispenser": NO,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://nelhydrogen.com/product/car-dispenser-di001/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Nel_35 MPa US.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Nel_35 MPa US.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Nel_35 MPa US.png"
  },
  {
    "make": "OneH2",
    "model": "700 Bar Refueling",
    "model_variant": "",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://www.oneh2.com/hardware.html",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/OneH2_350 Bar Refueling.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/OneH2_350 Bar Refueling.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/OneH2_350 Bar Refueling.png"
  },
  {
    "make": "OneH2",
    "model": "350 Bar Refueling",
    "model_variant": "",
    "_70MPa_dispenser": NO,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": NO,
    "product_info_page": "https://www.oneh2.com/hardware.html",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/OneH2_350 Bar Refueling.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/OneH2_350 Bar Refueling.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/OneH2_350 Bar Refueling.png"
  },
  {
    "make": "PDC Machines",
    "model": "SimpleFuel",
    "model_variant": "SF-35-5",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://www.pdcmachines.com/simplefuel/specifications/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png"
  },
  {
    "make": "PDC Machines",
    "model": "SimpleFuel",
    "model_variant": "SF-35-10",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://www.pdcmachines.com/simplefuel/specifications/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png"
  },
  {
    "make": "PDC Machines",
    "model": "SimpleFuel",
    "model_variant": "SF-70-5",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://www.pdcmachines.com/simplefuel/specifications/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png"
  },
  {
    "make": "PDC Machines",
    "model": "SimpleFuel",
    "model_variant": "SF-70-10",
    "_70MPa_dispenser": YES,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://www.pdcmachines.com/simplefuel/specifications/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/PDC Machines_SimpleFuel_SF-35-10.png"
  },
  {
    "make": "Plug Power",
    "model": "GenFuel - Station",
    "model_variant": "",
    "_70MPa_dispenser": NO,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://www.plugpower.com/hydrogen/hydrogen-electrolyzers/electrolyzer-products/",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/Plug Power_GenFuel - Station.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/Plug Power_GenFuel - Station.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/Plug Power_GenFuel - Station.png"
  },
  {
    "make": "PowerTech",
    "model": "700 Bar Dispenser",
    "model_variant": "",
    "_70MPa_dispenser": NO,
    "_35MPa_dispenser": YES,
    "integrated_point_of_sale": YES,
    "product_info_page": "https://www.greencarcongress.com/2016/01/20160111-powertech.html",
    "img_final (804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Dispensers/PowerTech_700 Bar Dispenser.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Dispensers/PowerTech_700 Bar Dispenser.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Dispensers/PowerTech_700 Bar Dispenser.png"
  }
]

const electrolyzers = [
  {
    "make": "Air Products",
    "model": "PRISM® Hydrogen Generator (PHG)",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 5000,
    "flow_rate": null,
    "product_info_page": "https://www.airproducts.com/equipment/hydrogen-onsite-generators#/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Air Products_PRISM® Hydrogen Generator (PHG).png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Air Products_PRISM® Hydrogen Generator (PHG).png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Air Products_PRISM® Hydrogen Generator (PHG).png"
  },
  {
    "make": "Bloom Energy",
    "model": "Electrolyzer",
    "model_variant": "",
    "type": "Solid Oxygen",
    "net_production_rate": 86.7828,
    "flow_rate": null,
    "product_info_page": "https://www.bloomenergy.com/wp-content/uploads/electrolyzer-data-sheet.pdf",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Bloom_Energy_Electrolyzer.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Bloom_Energy_Electrolyzer.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Bloom_Energy_Electrolyzer.png"
  },
  {
    "make": "Nel",
    "model": "AC150",
    "model_variant": "",
    "type": "Alkaline",
    "net_production_rate": 150,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_AC150.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_AC150.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_AC150.png"
  },
  {
    "make": "Nel",
    "model": "AC300",
    "model_variant": "",
    "type": "Alkaline",
    "net_production_rate": 300,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_AC300.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_AC300.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_AC300.png"
  },
  {
    "make": "Nel",
    "model": "A150",
    "model_variant": "",
    "type": "Alkaline",
    "net_production_rate": 150,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_A150.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_A150.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_A150.png"
  },
  {
    "make": "Nel",
    "model": "A300",
    "model_variant": "",
    "type": "Alkaline",
    "net_production_rate": 300,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_A150.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_A150.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_A150.png"
  },
  {
    "make": "Nel",
    "model": "A485",
    "model_variant": "",
    "type": "Alkaline",
    "net_production_rate": 485,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_A485.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_A485.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_A485.png"
  },
  {
    "make": "Nel",
    "model": "A1000",
    "model_variant": "",
    "type": "Alkaline",
    "net_production_rate": 970,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_A1000.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_A1000.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_A1000.png"
  },
  {
    "make": "Nel",
    "model": "A3880",
    "model_variant": "",
    "type": "Alkaline",
    "net_production_rate": 3880,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_A3880.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_A3880.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_A3880.png"
  },
  {
    "make": "Nel",
    "model": "MC100",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 103,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_MC250.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_MC250.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_MC250.png"
  },
  {
    "make": "Nel",
    "model": "MC200",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 207,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_MC250.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_MC250.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_MC250.png"
  },
  {
    "make": "Nel",
    "model": "MC400",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 413,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_MC250.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_MC250.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_MC250.png"
  },
  {
    "make": "Nel",
    "model": "MC250",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 246,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_MC250.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_MC250.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_MC250.png"
  },
  {
    "make": "Nel",
    "model": "MC500",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 492,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_MC250.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_MC250.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_MC250.png"
  },
  {
    "make": "Nel",
    "model": "M100",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 103,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_M200.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_M200.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_M200.png"
  },
  {
    "make": "Nel",
    "model": "M200",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 207,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_M200.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_M200.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_M200.png"
  },
  {
    "make": "Nel",
    "model": "M400",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 413,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_M200.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_M200.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_M200.png"
  },
  {
    "make": "Nel",
    "model": "M2000",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 2000,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_M2000.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_M2000.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_M2000.png"
  },
  {
    "make": "Nel",
    "model": "M3000",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 3000,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_M2000.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_M2000.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_M2000.png"
  },
  {
    "make": "Nel",
    "model": "M4000",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 4000,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_M2000.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_M2000.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_M2000.png"
  },
  {
    "make": "Nel",
    "model": "M5000",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 5000,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_M2000.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_M2000.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_M2000.png"
  },
  {
    "make": "Nel",
    "model": "C10",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 10,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_C10.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_C10.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_C10.png"
  },
  {
    "make": "Nel",
    "model": "C20",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 20,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_C10.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_C10.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_C10.png"
  },
  {
    "make": "Nel",
    "model": "C30",
    "model_variant": "",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 30,
    "flow_rate": null,
    "product_info_page": "https://nelhydrogen.com/water-electrolysers-hydrogen-generators/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Nel_C10.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Nel_C10.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Nel_C10.png"
  },
  {
    "make": "Plug Power",
    "model": "Allagash Electrolyzer",
    "model_variant": "32 Cells",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 50,
    "flow_rate": null,
    "product_info_page": "https://www.plugpower.com/hydrogen/hydrogen-electrolyzers/electrolyzer-products/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Plug Power_Allagash Electrolyzer.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Plug Power_Allagash Electrolyzer.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Plug Power_Allagash Electrolyzer.png"
  },
  {
    "make": "Plug Power",
    "model": "Allagash Electrolyzer",
    "model_variant": "65 Cells",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 100,
    "flow_rate": null,
    "product_info_page": "https://www.plugpower.com/hydrogen/hydrogen-electrolyzers/electrolyzer-products/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Plug Power_Allagash Electrolyzer.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Plug Power_Allagash Electrolyzer.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Plug Power_Allagash Electrolyzer.png"
  },
  {
    "make": "Plug Power",
    "model": "Allagash Electrolyzer",
    "model_variant": "129 Cells",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 200,
    "flow_rate": null,
    "product_info_page": "https://www.plugpower.com/hydrogen/hydrogen-electrolyzers/electrolyzer-products/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Plug Power_Allagash Electrolyzer.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Plug Power_Allagash Electrolyzer.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Plug Power_Allagash Electrolyzer.png"
  },
  {
    "make": "Plug Power",
    "model": "Merrimack Electrolyzer",
    "model_variant": "28 Cells",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 10,
    "flow_rate": null,
    "product_info_page": "https://www.plugpower.com/hydrogen/hydrogen-electrolyzers/electrolyzer-products/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Plug Power_Merrimack Electrolyzer.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Plug Power_Merrimack Electrolyzer.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Plug Power_Merrimack Electrolyzer.png"
  },
  {
    "make": "Plug Power",
    "model": "Merrimack Electrolyzer",
    "model_variant": "56 Cells",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 20,
    "flow_rate": null,
    "product_info_page": "https://www.plugpower.com/hydrogen/hydrogen-electrolyzers/electrolyzer-products/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Plug Power_Merrimack Electrolyzer.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Plug Power_Merrimack Electrolyzer.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Plug Power_Merrimack Electrolyzer.png"
  },
  {
    "make": "Plug Power",
    "model": "Merrimack Electrolyzer",
    "model_variant": "84 Cells",
    "type": "Proton Exchange Membrane (PEM)",
    "net_production_rate": 30,
    "flow_rate": null,
    "product_info_page": "https://www.plugpower.com/hydrogen/hydrogen-electrolyzers/electrolyzer-products/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Electrolyzers/Plug Power_Merrimack Electrolyzer.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Electrolyzers/Plug Power_Merrimack Electrolyzer.png",
    "img_final_mobile_(315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Electrolyzers/Plug Power_Merrimack Electrolyzer.png"
  }
]

const storage = [
  {
    "make": "Quantum Fuel Systems",
    "model": "VPLite-H45 40ft",
    "model_variant": "",
    "type": "Pipeline Trailer",
    "pressure": 3600,
    "capacity": 440400,
    "product_info_page": "https://www.qtww.com/product/virtual-pipeline-trailers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Storage/Quantum Fuel Systems_VPLite-H45 40ft.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Storage/Quantum Fuel Systems_VPLite-H45 40ft.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Storage/Quantum Fuel Systems_VPLite-H45 40ft.png"
  },
  {
    "make": "Quantum Fuel Systems",
    "model": "VP5000-H",
    "model_variant": "",
    "type": "Pipeline Trailer",
    "pressure": 5000,
    "capacity": 50292,
    "product_info_page": "https://www.qtww.com/product/virtual-pipeline-trailers/",
    "img_final_(804x536)": "https://assets.zappyride.com/img/H2_equipment/large/Storage/Quantum Fuel Systems_VPLite-H45 40ft.png",
    "img_final_th_(225x150)": "https://assets.zappyride.com/img/H2_equipment/thumbnails/Storage/Quantum Fuel Systems_VPLite-H45 40ft.png",
    "img_final_mobile (315x211)": "https://assets.zappyride.com/img/H2_equipment/mobile/Storage/Quantum Fuel Systems_VPLite-H45 40ft.png"
  }
]

export {
  chillers,
  compressors,
  dispensers,
  electrolyzers,
  storage
}