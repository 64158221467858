const timeStringToNumber = (timeString) => {
    if(!timeString) {
        return 0;
    }

    const hoursMinutes = timeString.split(':');
    const hours = parseInt(hoursMinutes[0]);
    const minutes = parseInt(hoursMinutes[1]);

    return hours + (minutes / 60);
}

export default timeStringToNumber;