const UtilityLookupData = [
    {
        key: 'ziplookup',
        sections: [
            {
                key: 'zipentry',
                collection: 'utility',
                type: 'zipEntry',
                elements: [
                    {
                        type: 'textField',
                        property: 'zip'
                    },
                    {
                        type: 'submitButton',
                        text: 'search'
                    }
                ]
            }
        ]
    },
    {
        key: 'utilityCard',
        sections: [
            {
                collection: 'utility',
                key: 'utilityCardSection', 
                type: 'utilityCards',
                direction: 'column',
                elements: [
                    {
                        type: 'utilities',
                        property: 'utilityData',
                        buttonText: 'Learn More'
                    }
                ]
            }
        ]
    }
]

export default UtilityLookupData;