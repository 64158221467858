import React from 'react';
import { Typography, Box, Paper } from "@material-ui/core"


const PageHeader = ({title, tagline, className}) => {
  return (
    <div className={`headerWrapper ${className}`}>
      <Box>
        <Paper>
          <Typography variant="h1" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography>
            {tagline}          
          </Typography>
        </Paper>
      </Box>
    </div>
  )
}

export default PageHeader;
