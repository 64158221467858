import React from 'react';
import { FlowComponentWrapper } from '@zappy-ride/react-flows';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Button, FormControl, InputLabel, Select, Typography, AppBar, Tabs, Tab, FormControlLabel } from '@material-ui/core';

import { useGetVendorsQuery } from '../../services/unifiedAPIQuery';
import vendorCatalogSlice, { retrievers, updaters } from '../../Slices/vendorCatalogSlice';
import h2Vendors from '../../data/h2Vendors';
import { ElectricVendorCard, H2VendorCard } from '../CatalogCards';
import { spinner } from '../../assets/images';
import air_liquide from '../../assets/images/logos/h2Vendors/air_liquide.png';
import air_Products from '../../assets/images/logos/h2Vendors/air_Products.png';
import baker_botts from '../../assets/images/logos/h2Vendors/baker_botts.png';
import bayotech_logo from '../../assets/images/logos/h2Vendors/BayoTech_Logo_Full.png';
import burnsmcdonnell from '../../assets/images/logos/h2Vendors/burnsmcdonnell_blog.png';
import bv from '../../assets/images/logos/h2Vendors/bv.png';
import epc_logo from '../../assets/images/logos/h2Vendors/epc_logo.png';
import fiedlergroup_logo from '../../assets/images/logos/h2Vendors/Fiedler_Group_logo.png';
import iwatani from '../../assets/images/logos/h2Vendors/Iwatani_Corporation_logo.png';
import Nel_Logo from '../../assets/images/logos/h2Vendors/Nel_Logo.png';
import oneh2 from '../../assets/images/logos/h2Vendors/oneh2.png';
import pebsal_linde from '../../assets/images/logos/h2Vendors/pebsal_linde.png';
import powertapfuels from '../../assets/images/logos/h2Vendors/powertapfuels.png';
import Shell_logo from '../../assets/images/logos/h2Vendors/Shell_logo.png';
import Sumitomo_Corp from '../../assets/images/logos/h2Vendors/Sumitomo_Corp.png';
import tlmpetrolaborforc from '../../assets/images/logos/h2Vendors/tlmpetrolaborforc.png';
import Trillium_271x81_iso from '../../assets/images/logos/h2Vendors/Trillium_271x81_iso.png';
import Worley_Logo_2019_1000x303_RGB from '../../assets/images/logos/h2Vendors/Worley_Logo_2019_1000x303_RGB.png';
import PageHeader from '../PageHeader';
import PageTitle, {LoadingPageTitle} from '../PageTitle';

const h2VendorLogos = {
    air_liquide,
    air_Products,
    baker_botts,
    bayotech_logo,
    burnsmcdonnell,
    bv,
    epc_logo,
    fiedlergroup_logo,
    iwatani,
    Nel_Logo,
    oneh2,
    pebsal_linde,
    powertapfuels,
    Shell_logo,
    Sumitomo_Corp,
    tlmpetrolaborforc,
    Trillium_271x81_iso,
    Worley_Logo_2019_1000x303_RGB
}

function compareOrderedArraysOfInts(array1, array2) {
    const outPutArray = []
    let i = 0, j = 0;
    while (array1.length > i && array2.length > j) {
        if (array1[i] === array2[j]) {
            outPutArray.push(array1[i]);
            i++;
            j++;
        } else if (array1[i] < array2[j]) {
            i++;
        }
        else {
            j++;
        }
    }
    return outPutArray;
}

/**************************************************
 *  
 *      CreateIndexValueKeys    
 *  
 * *************************************************/

const createIndexValueKeys = (
    catalog,
    keysOfInterest
) => {
    const valueKeys = new Map();

    catalog.forEach((item, index) => {
        keysOfInterest.forEach((key) => {
            //Check to see if  the value of key exists in valueKeys map 
            if (!valueKeys.has(key)) {
                valueKeys.set(key, new Map());
            }

            let vk = valueKeys.get(key)

            //Check to see if an array exists for the index
            if (!vk.has(item[key])) {
                vk.set(item[key], []);
            }

            vk.get(item[key]).push(index);
        })
    })

    return valueKeys;
}

/**************************************************
 *  
 *      Sorter Functions   - Selects
 *  
 * *************************************************/


class CatalogDropDownSorter { // Is the same as CatalogDropdownFilter?

    constructor() {
        this.inputs = {
            className: "", values: [], inputLabel: "",
            inputProps_name: "", inputProps_id: "", value: "",
            handleChangeFunction: ""
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }



    build() {
        const inputProps = {
            name: this.inputs.inputProps_name,
            id: this.inputs.inputProps_id
        }
        return (
            <FormControl component="fieldset"
                className={this.inputs.className}
            >
                <FormControlLabel
                    control={
                        <Select native displayEmpty value={this.inputs.value} onChange={this.inputs.handleChangeFunction} inputProps={inputProps}>
                            {this.inputs.values.map(value => <option value={value} >{value}</option>)}
                        </Select>
                    }
                    label={this.inputs.inputLabel}
                    labelPlacement="start"
                />

            </FormControl>
        )
    }
}

const createCatalogDropDownSorter = () => (new CatalogDropDownSorter());

/**************************************************
 *  
 *      Filter Functions   -  Buttons
 *  
 * *************************************************/

class CatalogBooleanButtonFilter {

    constructor() {
        this.inputs = {
            className: "", values: [null, false], inputLabel: "",
            inputProps_name: "", inputProps_id: "", value: "",
            handleChangeFunction: () => { }, buttonText: ""
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return (
            <FormControl className={this.inputs.className}>
                <InputLabel htmlFor={this.inputs.className}>{this.inputs.inputLabel}</InputLabel>
                <Button
                    name={this.inputs.value ? 'add' : 'doNotAdd'}
                    onClick={this.inputs.handleChangeFunction}
                    className={this.inputs.value ? "isActive" : ""}
                >
                    {this.inputs.buttonText}
                </Button>
            </FormControl>
        )
    }
}
const createCatalogBooleanButtonFilter = () => (new CatalogBooleanButtonFilter());

/**************************************************
 *  
 *      Wrapper component
 *  
 * *************************************************/
const VendorCatalogWrapper = () => {

    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    const showSpecialtyHandler = (event, targetState) => {
        const newValue = event.currentTarget.name === 'add' ? null : true;
        dispatch(updaters[targetState](newValue));
    }

    const sortDirection = useSelector(retrievers.sortDirection);
    const includeEngineering = useSelector(retrievers.includeEngineering);
    const includeConstruction = useSelector(retrievers.includeConstruction);
    const includeProcurement = useSelector(retrievers.includeProcurement);
    const includeProduction = useSelector(retrievers.includeProduction);
    const includeDispensingStorage = useSelector(retrievers.includeDispensingStorage);

    // Load Api Data
    const { data, isLoading } = useGetVendorsQuery();

    // Specialty mapping: additive logic
    const specialtyFieldName = ['engineering', 'construction', 'procurement'];
    const specialtyFieldNameToValueMapping = [includeEngineering, includeConstruction, includeProcurement];
    const h2SpecialtyFieldName = ['production', 'dispensing_storage'];
    const h2SpecialtyFieldNameToValueMapping = [includeProduction, includeDispensingStorage]

    // Filter mapping: subtractive logic
    const filterFieldName = ['placeholder'];
    const filterFieldNameToValueMapping = ['placeholder'];
    const h2FilterFieldName = ['placeholder'];
    const h2FilterFieldNameToValueMapping = ['placeholder'];

    let vendorSpecialistValueKeys, h2VendorSpecialistValueKeys, vendorFilterValueKeys, h2VendorFilterValueKeys;

    const vendorSortCoeffs = {
        'A-Z': 1,
        'Z-A': -1
    }

    const workingVendorSortCoeff = vendorSortCoeffs[sortDirection];

    const vendorSpecialtySet = new Set();
    const h2VendorSpecialtySet = new Set();

    const preprocessString = (str) => {
       return str.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
   }
   
   const vendorSortFunc = (v0, v1) => {
       const processedName0 = preprocessString(v0.name);
       const processedName1 = preprocessString(v1.name);
       
       if (processedName0 > processedName1) {
           return 1 * workingVendorSortCoeff;
       } else if (processedName0 < processedName1) {
           return -1 * workingVendorSortCoeff;
       }
       return 0;
   }

    if (!isLoading && data) {
        const vendorCatalogData = [...data.vendors];
        const h2VendorCatalogData = h2Vendors;

        vendorSpecialistValueKeys = createIndexValueKeys(vendorCatalogData, specialtyFieldName);
        h2VendorSpecialistValueKeys = createIndexValueKeys(h2VendorCatalogData, h2SpecialtyFieldName);

        vendorFilterValueKeys = createIndexValueKeys(vendorCatalogData, filterFieldName);
        h2VendorFilterValueKeys = createIndexValueKeys(h2VendorCatalogData, h2FilterFieldName);

        const azSorter = createCatalogDropDownSorter()
            .withClassName()
            .withValues(['A-Z', 'Z-A'])
            .withInputLabel('Sort By')
            .withInputProps_name()
            .withInputProps_id()
            .withValue(sortDirection)
            .withHandleChangeFunction((event) => { dispatch(updaters.sortDirection(event.target.value)) })
            .build();

        const engineeringRoleFilter = createCatalogBooleanButtonFilter()
            .withClassName()
            .withValues()
            .withButtonText('Engineering')
            .withInputProps_name()
            .withInputProps_id()
            .withValue(includeEngineering)
            .withHandleChangeFunction((event) => {
                showSpecialtyHandler(event, 'includeEngineering');
            })
            .build();

        const constructionRoleFilter = createCatalogBooleanButtonFilter()
            .withClassName()
            .withValues()
            .withButtonText('Construction')
            .withInputProps_name()
            .withInputProps_id()
            .withValue(includeConstruction)
            .withHandleChangeFunction((event) => {
                showSpecialtyHandler(event, 'includeConstruction');
            })
            .build();

        const procurementRoleFilter = createCatalogBooleanButtonFilter()
            .withClassName()
            .withValues()
            .withButtonText('Procurement')
            .withInputProps_name()
            .withInputProps_id()
            .withValue(includeProcurement)
            .withHandleChangeFunction((event) => {
                showSpecialtyHandler(event, 'includeProcurement');
            })
            .build();

        const productionRoleFilter = createCatalogBooleanButtonFilter()
            .withClassName()
            .withValues()
            .withButtonText('Production')
            .withInputProps_name()
            .withInputProps_id()
            .withValue(includeProduction)
            .withHandleChangeFunction((event) => {
                showSpecialtyHandler(event, 'includeProduction');
            })
            .build();

        const dispensingStorageRoleFilter = createCatalogBooleanButtonFilter()
            .withClassName()
            .withValues()
            .withButtonText('Dispensing & Storage')
            .withInputProps_name()
            .withInputProps_id()
            .withValue(includeDispensingStorage)
            .withHandleChangeFunction((event) => {
                showSpecialtyHandler(event, 'includeDispensingStorage');
            })
            .build();

        specialtyFieldName.forEach((key, index) => {
            let val = vendorSpecialistValueKeys.get(key).get(
                specialtyFieldNameToValueMapping[index]
            )

            if(val) {
                val.forEach((item) => vendorSpecialtySet.add(vendorCatalogData[item]));
            }
        })

        let vendorsSelectedSpecialties = vendorSpecialtySet.size > 0 
            ? Array.from(vendorSpecialtySet).sort(vendorSortFunc)
            : vendorCatalogData.sort(vendorSortFunc);

        let electricVendorsFiltered = filterFieldName.map(
            (key, index) => {
                let val = vendorFilterValueKeys.get(key).get(
                    typeof filterFieldNameToValueMapping[index] === 'boolean' ? filterFieldNameToValueMapping[index] :
                        isNaN(filterFieldNameToValueMapping[index]) ? filterFieldNameToValueMapping[index] :
                            (Number.isInteger(filterFieldNameToValueMapping[index]) ? parseInt(filterFieldNameToValueMapping[index]) :
                                parseFloat(filterFieldNameToValueMapping[index])))        
                if (typeof val === 'undefined') {
                    return [...Array(vendorsSelectedSpecialties.length).keys()]
                } else return val
            }).reduce(compareOrderedArraysOfInts)    

        h2SpecialtyFieldName.forEach((key, index) => {
            let val = h2VendorSpecialistValueKeys.get(key).get(
                h2SpecialtyFieldNameToValueMapping[index]
            )

            if(val) {
                val.forEach((item) => h2VendorSpecialtySet.add(h2VendorCatalogData[item]));
            }
        })

        let h2VendorsSelectedSpecialties = h2VendorSpecialtySet.size > 0 
            ? Array.from(h2VendorSpecialtySet).sort(vendorSortFunc)
            : h2VendorCatalogData.sort(vendorSortFunc);

        let h2VendorsFiltered = h2FilterFieldName.map(
            (key, index) => {
                let val = h2VendorFilterValueKeys.get(key).get(
                    typeof h2FilterFieldNameToValueMapping[index] === 'boolean' ? h2FilterFieldNameToValueMapping[index] :
                        isNaN(h2FilterFieldNameToValueMapping[index]) ? h2FilterFieldNameToValueMapping[index] :
                            (Number.isInteger(h2FilterFieldNameToValueMapping[index]) ? parseInt(h2FilterFieldNameToValueMapping[index]) :
                                parseFloat(h2FilterFieldNameToValueMapping[index])))
                if (typeof val === 'undefined') {
                    return [...Array(h2VendorsSelectedSpecialties.length).keys()]
                } else return val
            }).reduce(compareOrderedArraysOfInts)    

        const electricVendorCatalog = () => {
            return (
                <Grid container spacing={3}>
                    {
                        electricVendorsFiltered.map((vendorIndex) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={`vendor_${vendorIndex}`}
                                >
                                    <ElectricVendorCard catalogItem={vendorsSelectedSpecialties[vendorIndex]} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            )
        };

        const h2VendorCatalog = () => {
            return (
                <Grid container spacing={3}>
                    {
                        h2VendorsFiltered.map((vendorIndex) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={`vendor_${vendorIndex}`}
                                >
                                    <H2VendorCard catalogItem={h2VendorsSelectedSpecialties[vendorIndex]} logos={h2VendorLogos} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            )
        };

        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            {children}
                        </Box>
                    )}
                </div>
            );
        }

        function a11yProps(index) {
            return {
                id: `full-width-tab-${index}`,
                'aria-controls': `full-width-tabpanel-${index}`,
            };
        }

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };


        return (
            <>
                <PageHeader
                    title="vendor catalog"
                    tagline="It takes engineering, construction, and procurement partners to turn your infrastructure plan into a reality. Find them here."
                />
                <PageTitle page="VendorCatalog" />

                <AppBar position="static" color="default" className="tabs-bar">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        className="full-width-tabs"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Electric" {...a11yProps(0)} />
                        <Tab label="Hydrogen" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <React.Fragment>

                    <TabPanel value={value} index={0} dir='ltr'>
                        <Box className="vendor_catalog_controls">
                            <Typography>Vendor Type</Typography>
                            <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={6} md={2}>
                                    {engineeringRoleFilter}
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    {constructionRoleFilter}
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    {procurementRoleFilter}
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Box className="vendor_sorter_dropdown">
                                        {azSorter}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {electricVendorCatalog()}
                    </TabPanel>
                    <TabPanel value={value} index={1} dir="ltr">
                        <Box className="vendor_catalog_controls">
                            <Typography>Vendor Type</Typography>
                            <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} md={2}>
                                    {productionRoleFilter}
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    {dispensingStorageRoleFilter}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className="vendor_sorter_dropdown">
                                        {azSorter}
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                        {h2VendorCatalog()}
                    </TabPanel>
                </React.Fragment>
            </>
        )
    }




    return (<React.Fragment>
        <LoadingPageTitle />
        <center><img src={spinner} alt="waiting"/></center>
    </React.Fragment>)
}

const WrappedVendorCatalog = FlowComponentWrapper('VendorCatalog', vendorCatalogSlice)(VendorCatalogWrapper);

export default WrappedVendorCatalog;