import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { FlowComponentWrapper } from "@zappy-ride/react-flows";
import { useSelector } from "react-redux";

import h2CostsSlice from "../../Slices/h2CostsSlice";
import { retrievers as H2DesignRetrievers } from "../../Slices/h2DesignSlice";
import { retrievers as guidedPlannerRetrievers } from "../../Slices/guidedPlannerSlice";
import { CostEstimatesMetdataBuilder } from "../../CostEstimates/CostEstimatesMetadata";
import CostEstimates from "../../CostEstimates/CosteEstimates";
import {
  createCostTopBar,
  createMobileCostTopBar,
  createLaborTips,
  createPermitTips,
} from "../H2CostsHeader";
import {
  EquipmentCatalog,
  FuelCellWorkPlan,
  FuelCellDesign,
  FuelCellCostEstimate,
} from "../Pages";
import AlertDialog from "../AlertDialog";
import PageButtons from "../PageButtons";
import PageTitle from "../PageTitle";
import CostWorkPlanPageTitle from "../CostWorkPlanPageTitle";
import { pushToMillions } from "../../utils/numberFormatting";
import CSTooltip from "../CSTooltip";
import { useCheckLogin } from "../UserAuthentication/hooks/useCheckLogin";

const H2CostsWrapper = () => {
  useCheckLogin();

  let h2HardwareInstallationCost = { min: 0, max: 0 };
  let h2EngDesignCost = { name: "Engineering Design Cost", min: 20, max: 50 };
  let h2PermitFeesCost = { name: "Permit and Fees Cost", min: 10, max: 30 };
  let tubeTrailerCost = { name: "No Hardware Needed", min: 0, max: 0 };
  let supplyStorageTankCost_liquid = {
    name: "Supply Storage Tank Cost (liquid)",
    min: 600,
    max: 1000,
  };
  let liquidPumpCost = { name: "Liquid Pump Cost", min: 125, max: 250 };
  let gasCompressorCost = { name: "Gas Compressor Cost", min: 125, max: 125 };
  let h2DispenserCount = Math.ceil(
    useSelector(guidedPlannerRetrievers.h2DispenserCount)
  );
  let dispensersCost = {
    name: "Dispensers Cost",
    tooltip: {
      text: [
        <div className="tooltipText">
          Equivalent to gas pumps, dispensers measure hydrogen by mass at two
          different pressures, H70/700bar and H35/350bar.
        </div>,
      ],
    },
    min: h2DispenserCount * 50,
    max: h2DispenserCount * 75,
  };
  let humanMachineInterfaceCost = {
    name: "Human Machine Interface Cost",
    tooltip: {
      text: [
        <div className="tooltipText">
          Refers more broadly to the controls cabinet and interface provided to
          run and administer a hydrogen fueling system.
        </div>,
      ],
    },
    min: 200,
    max: 300,
  };
  let electrolyzerCost = { name: "Electrolyzer Cost", min: 1800, max: 1800 };
  let smrModuleCost = {
    name: "Steam Methane Reformer Module Cost",
    min: 1300,
    max: 1300,
  };
  let h2SolutionType = useSelector(
    H2DesignRetrievers.selectedDeliveryMethodValue
  );

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const h2PageButtons = [
    { text: "View Cost Estimates", link: FuelCellCostEstimate },
    { text: "See Workplan", link: FuelCellWorkPlan.link },
    { text: "Equipment Catalog", link: EquipmentCatalog.link },
  ];
  const title = "Fuel Cell Cost Estimates";

  const h2CostArrays = {
    tubeTrailer: [undefined, undefined],
    gasDelivery: [dispensersCost, humanMachineInterfaceCost],
    liquidDelivery: [
      supplyStorageTankCost_liquid,
      gasCompressorCost,
      liquidPumpCost,
      dispensersCost,
      humanMachineInterfaceCost,
    ],
    electrolysisDelivery: [
      supplyStorageTankCost_liquid,
      gasCompressorCost,
      liquidPumpCost,
      dispensersCost,
      humanMachineInterfaceCost,
      electrolyzerCost,
    ],
    smr: [
      supplyStorageTankCost_liquid,
      gasCompressorCost,
      liquidPumpCost,
      dispensersCost,
      humanMachineInterfaceCost,
      smrModuleCost,
    ],
  };

  h2HardwareInstallationCost = () => {
    const costArray = h2CostArrays[h2SolutionType];
    const totalCost = {
      min: 0,
      max: 0,
    };

    costArray.forEach((costItem) => {
      totalCost.min += costItem.min;
      totalCost.max += costItem.max;
    });

    return totalCost;
  };

  try {
    let metadata,
      engTotalCost,
      permitTotalCost,
      hardTotalCost,
      totalCost,
      engCostCardArrary,
      permitCostCardArrary;
    if (h2SolutionType === "tubeTrailer") {
      engTotalCost = (
        <>
          ${pushToMillions(h2EngDesignCost.min * 1000)} - $
          {pushToMillions(h2EngDesignCost.max * 1000)}{" "}
        </>
      );
      engCostCardArrary = <>{createLaborTips(engTotalCost, isMobileDevice)}</>;
      permitTotalCost = (
        <>
          ${pushToMillions(h2PermitFeesCost.min * 1000)} - $
          {pushToMillions(h2PermitFeesCost.max * 1000)}{" "}
        </>
      );
      permitCostCardArrary = (
        <>{createPermitTips(permitTotalCost, isMobileDevice)}</>
      );
      hardTotalCost = (
        <>
          ${tubeTrailerCost.min}K - ${tubeTrailerCost.max}K{" "}
        </>
      );
      totalCost = (
        <>
          ${pushToMillions(h2EngDesignCost.min + h2PermitFeesCost.min)}K - $
          {pushToMillions(h2EngDesignCost.max + h2PermitFeesCost.max)}K
        </>
      );

      metadata = CostEstimatesMetdataBuilder()
        .withEngineeringDesignTotalCost(engTotalCost)
        .withPermittingFeesTotalCost(permitTotalCost)
        .withHardwareInstallationTotalCost(hardTotalCost)
        .withTotalProjectCost(totalCost)
        .withEngineeringDesignDescriptionCardArray(engCostCardArrary)
        .withPermittingFeesDescriptionCardArray(permitCostCardArrary)
        .withHardwareInstallationCostArray([])
        .build();
    } else {
      let itemizedCosts = h2CostArrays[h2SolutionType].map((item, idx) => {
        return (
          <React.Fragment>
            <Grid item xs={8} key={idx} className="itemized-cost-h2-item">
              <Typography>{item.name}</Typography>
              {item.tooltip && <CSTooltip details={item.tooltip.text} />}
            </Grid>
            <Grid item xs={4} key={idx}>
              <Typography>
                ${pushToMillions(item.min * 1000)} - $
                {pushToMillions(item.max * 1000)}
              </Typography>
            </Grid>
          </React.Fragment>
        );
      });

      engTotalCost = (
        <>
          ${pushToMillions(h2EngDesignCost.min * 1000)} - $
          {pushToMillions(h2EngDesignCost.max * 1000)}{" "}
        </>
      );
      engCostCardArrary = <>{createLaborTips(engTotalCost, isMobileDevice)}</>;
      permitTotalCost = (
        <>
          ${pushToMillions(h2PermitFeesCost.min * 1000)} - $
          {pushToMillions(h2PermitFeesCost.max * 1000)}{" "}
        </>
      );
      permitCostCardArrary = (
        <>{createPermitTips(permitTotalCost, isMobileDevice)}</>
      );
      hardTotalCost = (
        <>
          ${pushToMillions(h2HardwareInstallationCost().min * 1000)} - $
          {pushToMillions(h2HardwareInstallationCost().max * 1000)}{" "}
        </>
      );
      totalCost = (
        <>
          $
          {pushToMillions(
            h2EngDesignCost.min * 1000 +
              h2PermitFeesCost.min * 1000 +
              h2HardwareInstallationCost().min * 1000
          )}{" "}
          - $
          {pushToMillions(
            h2EngDesignCost.max * 1000 +
              h2PermitFeesCost.max * 1000 +
              h2HardwareInstallationCost().max * 1000
          )}
        </>
      );

      metadata = CostEstimatesMetdataBuilder()
        .withEngineeringDesignTotalCost(engTotalCost)
        .withEngineeringDesignDescriptionCardArray(engCostCardArrary)
        .withPermittingFeesTotalCost(permitTotalCost)
        .withPermittingFeesDescriptionCardArray(permitCostCardArrary)
        .withHardwareInstallationTotalCost(hardTotalCost)
        .withTotalProjectCost(totalCost)
        .withHardwareInstallationCostArray(itemizedCosts)
        .build();
    }

    const renderTopCost = () => {
      const desktopTopCost = (
        <Grid
          className="tco_top_summary"
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <PageTitle page="FuelCellCostEstimate" />
          {createCostTopBar(
            engTotalCost,
            permitTotalCost,
            hardTotalCost,
            totalCost
          )}
        </Grid>
      );

      const mobileTopCost = (
        <Grid
          className="tco_top_summary tco_mobile"
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <PageTitle page="FuelCellCostEstimate" />
          {createMobileCostTopBar(totalCost)}
        </Grid>
      );

      return isMobileDevice ? mobileTopCost : desktopTopCost;
    };

    return (
      <React.Fragment>
        <CostWorkPlanPageTitle title={title} />
        {renderTopCost()}
        <CostEstimates className="" componentMetadata={metadata} />
        {PageButtons(h2PageButtons)}
      </React.Fragment>
    );
  } catch (error) {
    return (
      <>
        <AlertDialog title text buttonText page={FuelCellDesign.link} />
      </>
    );
  }
};

const WrappedH2Costs = FlowComponentWrapper(
  "h2Costs",
  h2CostsSlice
)(H2CostsWrapper);
export default WrappedH2Costs;
