export class InfrastructureSite {
 
    constructor() {
        this.inputs = {
            landSize: 0,
            water: false,
            electricity: false,
            electricityPrice: 0
        };
        // //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") 
            ? `has${input.charAt(0).toUpperCase() + input.slice(1)}` 
            :  `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

}