import React from 'react';
import { FlowComponentWrapper } from '@zappy-ride/react-flows';
import { useDispatch, useSelector } from "react-redux";
import { Components, ComponentMetadata, calculations, data } from '../../cap';
import evCostSlice, { updaters, retrievers } from "../../Slices/evCostSlice";
import { retrievers as evDesignRetrievers } from "../../Slices/evDesignSlice";
import { retrievers as guidedPlannerRetrievers } from "../../Slices/guidedPlannerSlice";
import PageButtons from '../PageButtons';
import { EquipmentCatalog, EvWorkPlan } from '../Pages';
import PageTitle from '../PageTitle';
import recommendedChargerCalculations from '../../coreCalcs/recommendedChargerCalculations';
import AlertDialog from '../AlertDialog';
import { EvDesign } from '../Pages';
import { CHARGER_DETAILS } from '../../data/evChargers/evChargerTypesAndPowers';
import CostWorkPlanPageTitle from '../CostWorkPlanPageTitle'
import { pushToMillions } from '../../utils/numberFormatting';
import { useCheckLogin } from '../UserAuthentication/hooks/useCheckLogin';

const EVCostsWrapper = () => {

  useCheckLogin();

  const evPageButtons = [
    { text: "View Workplan", link: EvWorkPlan.link },
    { text: "View Design", link: EvDesign.link },
    { text: "View Equipment Catalog", link: EquipmentCatalog.link },
  ]
  const title = "EV Cost Estimates"
  const collection = {
    engDesignCost: useSelector(retrievers.engDesignConst),
    permitFeesCost: useSelector(retrievers.permitFeesCost),
    hardwareCost: useSelector(retrievers.hardwareCost),
    chargerCost: useSelector(retrievers.hardwareCost),
    installationCost: useSelector(retrievers.installationCost),
    totalProjectCost: useSelector(retrievers.totalProjectCost),
    chargerType: 'Charger Type',
    chargerQuantity: 'Quantity',
    chargerCostPerUnit: 'Price per unit',
    h2HardwareInstallationCost: '$??k - $??k',
    h2EngDesignCost: '$20k - $50k',
    h2PermitFeesCost: '$10k - $30k',
    supplyStorageTankCost: '$??k - $??k',
    fuelingStorageCost: '$??k - $??k',
    liquidPumpCost: '$??k - $??k',
    gasCompressorCost: '$??k - $??k',
    dispensersCost: '$??k - $??k',
    humanMachineInterfaceCost: '$??k - $??k',
    electrolyzerCost: '$??k - $??k',
    smrModuleCost: '$??k - $??k',
    h2SolutionType: 'tubeTrailer'
  }

  const gpRetrievers = {
    stationType: useSelector(guidedPlannerRetrievers.stationType),
    onRouteVehiclesPerDay: useSelector(guidedPlannerRetrievers.onRouteVehiclesPerDay),
    timeAvailableStart: useSelector(guidedPlannerRetrievers.timeAvailableStart),
    timeAvailableFinish: useSelector(guidedPlannerRetrievers.timeAvailableFinish),
    additionalMilesPerCharge: useSelector(guidedPlannerRetrievers.additionalMilesPerCharge),
    timeAtOnRouteStation: useSelector(guidedPlannerRetrievers.timeAtOnRouteStation),
    numVehicles: useSelector(guidedPlannerRetrievers.numVehicles),
    vehicleType: useSelector(guidedPlannerRetrievers.vehicleType),
    depotMilesPerDayPerVehicle: useSelector(guidedPlannerRetrievers.depotMilesPerDayPerVehicle),
  }

  const fuelType = 'electric';
  const dispatch = useDispatch();
  const { InfrastructureCost } = Components;
  const { EVCostsData } = ComponentMetadata;
  const hardwareCostValue = useSelector(retrievers.hardwareCost);
  const installationCostValue = useSelector(retrievers.installationCost);
  const trenching = useSelector(evDesignRetrievers.trenching);
  const preCalculatedTotalKWCapacity = useSelector(evDesignRetrievers.totalKWCapacity);
  const { installationCost } = calculations;

  const chargerDetails = data.RECOMMENDED_CHARGER_DETAILS;
  const selectedChargerID = useSelector(evDesignRetrievers.chargerID);
  const isRecommendation = useSelector(evDesignRetrievers.isRecommendation);

  const selectedCharger = isRecommendation ? recommendedChargerCalculations(gpRetrievers) : chargerDetails[selectedChargerID];

  const chargerQuantity = useSelector(evDesignRetrievers.chargerQuantity);
  if (typeof selectedCharger === "undefined") { return (
    <>  
      <AlertDialog title text buttonText page={EvDesign.link} /> 
    </>);
  }

  const chargerCostPerUnit = selectedCharger.chargerCost || {min: 0, max: 0};

  collection.chargerQuantity = chargerQuantity;
  collection.chargerType = `${selectedCharger.chargerType} ${selectedCharger.chargerPower} kW`;
  collection.chargerCostPerUnit = `$${pushToMillions(chargerCostPerUnit.min * 1000)} - $${pushToMillions(chargerCostPerUnit.max * 1000)}`

  const totalKws = (charger, chargerQuantity) => {
    if (charger in CHARGER_DETAILS) {
      return `${Math.floor(CHARGER_DETAILS[charger].chargerPower * chargerQuantity)}`;
    }
  }

  const refreshCalculations = () => {

    let costItems = [];
    let totalKWCapacity = preCalculatedTotalKWCapacity;
    if (fuelType === 'electric') {
      // EV only. Data for chargers is hard coded.
      if (selectedCharger && Object.keys(selectedCharger).length !== 0) {


        if(!totalKWCapacity) {
          totalKWCapacity = totalKws(selectedCharger.chargerID, selectedCharger.chargerCount);
        }

        const evChargerCost = {
          min: chargerCostPerUnit.min * chargerQuantity,
          max: chargerCostPerUnit.max * chargerQuantity
        }
        if (hardwareCostValue.min !== evChargerCost.min || hardwareCostValue.max !== evChargerCost.max) {
          dispatch(updaters.hardwareCost(evChargerCost));
        }
      }

      if (trenching && totalKWCapacity) {
        const evInstallationCost = installationCost(trenching, totalKWCapacity);
        if (installationCostValue.min !== evInstallationCost.min || installationCostValue.max !== evInstallationCost.max) {
          dispatch(updaters.installationCost(evInstallationCost));
        }
      }
      costItems = [collection.engDesignCost, collection.permitFeesCost, collection.hardwareCost, collection.installationCost];
    }

    const total = {
      min: 0,
      max: 0
    }

    costItems.forEach((costItem) => {
      total.min += costItem.min;
      total.max += costItem.max;
    });

    if (total.min !== collection.totalProjectCost.min || total.max !== collection.totalProjectCost.max) {
      dispatch(updaters.totalProjectCost(total));
    }
  }

  refreshCalculations();

  return (
    <div className="infrastructure-cost">
      {/* Should not be necessary, will be addressed with https://zappyride.atlassian.net/browse/FLEET-572 */}
      <CostWorkPlanPageTitle title={title} />
      <PageTitle page="EvCostEstimate" />
      <InfrastructureCost
        componentMetadata={EVCostsData}
        collection={collection}
      />
      {PageButtons(evPageButtons)}
    </div>
  )
}

const WrappedEVCosts = FlowComponentWrapper('EVCost', evCostSlice)(EVCostsWrapper);

export default WrappedEVCosts;
