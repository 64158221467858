const CHARGER_TYPE_AC_WIRELESS = "Level 2 AC Wireless Charging";
const CHARGER_TYPE_AC_WIRED = "Level 2 AC Wired Charging";
const CHARGER_TYPE_DC_WIRED = "Wired DC Fast Charging";
const CHARGER_TYPE_DC_OVERHEAD = "Overhead DC Fast Charging";

const CHARGER_POWER_7_2 = 7.2;
const CHARGER_POWER_7_7 = 7.7;
const CHARGER_POWER_16_8 = 16.8;
const CHARGER_POWER_19_2 = 19.2;
const CHARGER_POWER_50 = 50;
const CHARGER_POWER_150 = 150;
const CHARGER_POWER_350 = 350;

const CHARGER_TYPES_POWER_COST = [
    {
        name: `${CHARGER_TYPE_AC_WIRELESS} ${CHARGER_POWER_7_2} kW`,
        value: `${CHARGER_TYPE_AC_WIRELESS} ${CHARGER_POWER_7_2}`,
        min: 2,
        max: 5
    },
    {
        name: `${CHARGER_TYPE_AC_WIRED} ${CHARGER_POWER_7_7} kW`,
        value: `${CHARGER_TYPE_AC_WIRED} ${CHARGER_POWER_7_7}`,
        min: 2,
        max: 5
    },
    {
        name: `${CHARGER_TYPE_AC_WIRED} ${CHARGER_POWER_16_8} kW`,
        value: `${CHARGER_TYPE_AC_WIRED} ${CHARGER_POWER_16_8}`,
        min: 2,
        max: 5
    },
    {
        name: `${CHARGER_TYPE_AC_WIRED} ${CHARGER_POWER_19_2} kW`,
        value: `${CHARGER_TYPE_AC_WIRED} ${CHARGER_POWER_19_2}`,
        min: 2,
        max: 5
    },
    {
        name: `${CHARGER_TYPE_DC_WIRED} ${CHARGER_POWER_50} kW`,
        value: `${CHARGER_TYPE_DC_WIRED} ${CHARGER_POWER_50}`,
        min: 30,
        max: 60
    },
    {
        name: `${CHARGER_TYPE_DC_WIRED} ${CHARGER_POWER_150} kW`,
        value: `${CHARGER_TYPE_DC_WIRED} ${CHARGER_POWER_150}`,
        min: 50,
        max: 100
    },
    {
        name: `${CHARGER_TYPE_DC_WIRED} ${CHARGER_POWER_350} kW`,
        value: `${CHARGER_TYPE_DC_WIRED} ${CHARGER_POWER_350}`,
        min: 128,
        max: 150
    },
    {
        name: `${CHARGER_TYPE_DC_OVERHEAD} ${CHARGER_POWER_150} kW`,
        value: `${CHARGER_TYPE_DC_OVERHEAD} ${CHARGER_POWER_150}`,
        min: 300,
        max: 1200
    },
    {
        name: `${CHARGER_TYPE_DC_OVERHEAD} ${CHARGER_POWER_350} kW`,
        value: `${CHARGER_TYPE_DC_OVERHEAD} ${CHARGER_POWER_350}`,
        min: 300,
        max: 1200
    },
]

const COST_PER_FT_TRENCHING = 3.5;
const COST_PER_KW_CAPACITY = 0.3;
const RANGE_FROM_BASE = 0.8;
const MINIMUM_INSTALL_COST = 10;

const YES = "Yes"
const NO = "No"
export {
    CHARGER_TYPE_AC_WIRELESS,
    CHARGER_TYPE_AC_WIRED,
    CHARGER_TYPE_DC_WIRED,
    CHARGER_TYPE_DC_OVERHEAD,
    CHARGER_TYPES_POWER_COST,
    COST_PER_FT_TRENCHING,
    COST_PER_KW_CAPACITY,
    RANGE_FROM_BASE,
    MINIMUM_INSTALL_COST, 
    YES,
    NO
}
