
// @TODO need to fill out for the other 4 delivery methods.
const cardSets = {
    design: {
        electrolysis: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'electrolysis',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Talk to Your Utility',
                    text: 'Early and frequent communication with your utility is the best way to avoid delays later on.',
                    key: 'designTalkUtility'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Talk to the Public',
                    text: 'City Council will review and vote on the design. Community outreach may be required.',
                    key: 'designTalkToPublic'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Faster than Storage',
                    text: 'Electrolysis hardware is closer to a pre-packaged system than local storage, which speeds up the design process.',
                    key: 'designFasterStorage'
                },
                {
                    // @TODO should this card be here? UX documentation says we should assume private fleet
                    type: 'workplanDetailCard',
                    title: 'Public vs. Private',
                    text: 'Public fleets typically have more constraints than private fleets.',
                    key: 'designPublicPrivate'
                },
            ]
        },
        smr: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'smr',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Talk to Your Utility',
                    text: 'Early and frequent communication with your utiility is the best way to avoid delays later on.',
                    key: 'designTalkUtility'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Talk to the Public',
                    text: 'City Council will review and vote on the design. Community outreach may be required.',
                    key: 'designTalkToPublic'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Public vs Private',
                    text: 'Public fleets typically have more constraints than private fleets.',
                    key: 'publicVPrivate'
                },
            ]
        },
        liquidDelivery: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'liquidDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Talk to the Public',
                    text: 'City Council will review and vote on the design. Community outreach may be required.',
                    key: 'designTalkToPublic'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Public vs Private',
                    text: 'Public fleets typically have more constraints than private fleets.',
                    key: 'publicVPrivate'
                },
            ]
        },
        gasDelivery: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'gasDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Talk to the Public',
                    text: 'City Council will review and vote on the design. Community outreach may be required.',
                    key: 'designTalkToPublic'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Public vs Private',
                    text: 'Public fleets typically have more constraints than private fleets.',
                    key: 'publicVPrivate'
                },
            ]
        }
    },
    permit: {
        electrolysis: {
            key: 'engPermittingDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'electrolysis',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Required to Dig',
                    text: 'In order to break ground, a permit must be acquired from the state, county, or local authority having jurisdiction.',
                    key: 'permittingRequiredToDig',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Discharge',
                    text: 'Depending on what\'s in the waste water, you may be required to pay a Discharge Fee.',
                    key: 'permittingExperienceHelps',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Rural Goes Faster',
                    text: 'Projects in smaller towns and counties may complete permitting in as little as six months.',
                    key: 'permittingRuralFaster'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Inspection',
                    text: 'Besides obtaining a building permit, your design must adhere to relevent codes. Requirements vary by location.',
                    key: 'permittingInspection'
                }
            ]
        },
        smr: {
            key: 'engPermittingDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'smr',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Required to Dig',
                    text: 'In order to break ground, a permit must be acquired from the state, county, or local authority having jurisdiction.',
                    key: 'permittingRequiredToDig',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Rural Goes Faster',
                    text: 'Projects in smaller towns and counties may complete permitting in as little as six months.',
                    key: 'permittingRuralFaster'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Inspection',
                    text: 'Besides obtaining a building permit, your design must adhere to relevent codes. Requirements vary by location.',
                    key: 'permittingInspection'
                }
            ]
        },
        liquidDelivery: {
            key: 'engPermittingDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'liquidDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Required to Dig',
                    text: 'In order to break ground, a permit must be acquired from the state, county, or local authority having jurisdiction.',
                    key: 'permittingRequiredToDig',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Inspection',
                    text: 'Besides obtaining a building permit, your design must adhere to relevent codes. Requirements vary by location.',
                    key: 'permittingInspection'
                }
            ]
        },
        gasDelivery: {
            key: 'engPermittingDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'gasDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Required to Dig',
                    text: 'In order to break ground, a permit must be acquired from the state, county, or local authority having jurisdiction.',
                    key: 'permittingRequiredToDig',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Rural Goes Faster',
                    text: 'Projects in smaller towns and counties may complete permitting in as little as six months.',
                    key: 'permittingRuralFaster'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Inspection',
                    text: 'Besides obtaining a building permit, your design must adhere to relevent codes. Requirements vary by location.',
                    key: 'permittingInspection'
                }
            ]
        },
        tubeTrailer: {
            key: 'engPermittingDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'tubeTrailer',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Required',
                    text: 'Permit is required from the city before delivery can be performed.',
                    key: 'permittingRequired',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Delivery',
                    text: 'Delivery can start immediately after permit is issued.',
                    key: 'permittingDelivery'
                },
            ]
        }
    },
    construction: {
        electrolysis: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'electrolysis',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Plug-and-Play',
                    text: 'Electrolysis systems are pre-packaged. Just move them from their shipping container to your site.',
                    key: 'constructionPlugPlay',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Prep Work',
                    text: 'Before installation day, pour a concrete slab and prepare electricity and water discharge for connection.',
                    key: 'constructionPrepWork',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Long Lead Times',
                    text: 'It can take 9-12 months for equipment to arrive. To avoid delays, order before permitting begins.',
                    key: 'constructionLeadTime',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Water Requirements',
                    text: 'You will be using a lot of water. It takes 15 gallons of water to produce 1 kg of hydrogen.',
                    key: 'constructionWater',
                }
            ]
        }, 
        smr: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'smr',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Plug-and-Play',
                    text: 'SMR systems are pre-packaged. Just move them from their shipping container to your site.',
                    key: 'constructionPlugPlay',
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Prep Work',
                    text: 'Before installation day, pour a concrete slab and prepare electricity and gas for connection.',
                    key: 'constructionPrepWork',
                }
            ]
        }, 
        liquidDelivery: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'liquidDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Complexity',
                    text: 'Time required for construction will vary and depends on complexity of the system design.',
                    key: 'constructionPlugPlay',
                }
            ]
        },  
        gasDelivery: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'gasDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Complexity',
                    text: 'Time required for construction will vary and depends on complexity of the system design.',
                    key: 'constructionPlugPlay',
                }
            ]
        } 
    },
    commissioning: {
        electrolysis: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'electrolysis',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Test the equipment',
                    text: 'Testing that each piece of equipment works individually and that the system works as a whole.',
                    key: 'testEquipment'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Test Fueling',
                    text: 'Testing the system with a few vehicles, to ensure that hydrogen is transferred safely.',
                    key: 'testFueling'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Ready to Use!',
                    text: 'After successful commissioning, feel confident everything works and is ready to start using on a daily basis.',
                    key: 'readyToUse'
                }
            ]
        },
        smr: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'smr',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Test the equipment',
                    text: 'Testing that each piece of equipment works individually and that the system works as a whole.',
                    key: 'testEquipment'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Test Fueling',
                    text: 'Testing the system with a few vehicles, to ensure that hydrogen is transferred safely.',
                    key: 'testFueling'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Ready to Use!',
                    text: 'After successful commissioning, feel confident everything works and is ready to start using on a daily basis.',
                    key: 'readyToUse'
                }
            ]
        },
        liquidDelivery: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'liquidDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Test the equipment',
                    text: 'Testing that each piece of equipment works individually and that the system works as a whole.',
                    key: 'testEquipment'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Test Fueling',
                    text: 'Testing the system with a few vehicles, to ensure that hydrogen is transferred safely.',
                    key: 'testFueling'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Ready to Use!',
                    text: 'After successful commissioning, feel confident everything works and is ready to start using on a daily basis.',
                    key: 'readyToUse'
                }
            ]
        },
        gasDelivery: {
            key: 'engDesignDetailsCards',
            type: 'timelineBreakdownCards',
            collection: 'workplan',
            filterHook: 'gasDelivery',
            elements: [
                {
                    type: 'workplanDetailCard',
                    title: 'Test the equipment',
                    text: 'Testing that each piece of equipment works individually and that the system works as a whole.',
                    key: 'testEquipment'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Test Fueling',
                    text: 'Testing the system with a few vehicles, to ensure that hydrogen is transferred safely.',
                    key: 'testFueling'
                },
                {
                    type: 'workplanDetailCard',
                    title: 'Ready to Use!',
                    text: 'After successful commissioning, feel confident everything works and is ready to start using on a daily basis.',
                    key: 'readyToUse'
                }
            ]
        }
    }
}

const H2WorkplanData = [
    {
        key: 'costBreakdown',
        type: 'topbar',
        sections: [
            {
                key: 'workplanBreakdownSection',
                type: 'costBreakdownRanges',
                collection: 'workplan', // Think of collection as being more like tags rather than folders
                elements: [
                    {   
                        type: 'costBreakdownBullet',
                        title: 'Design',
                        property: 'h2DesignTimeline', 
                        key: 'designTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Permitting',
                        property: 'h2PermitTimeline', 
                        key: 'permitTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Construction',
                        property: 'h2ConstructionTimeline', 
                        key: 'constructionTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Commissioning',
                        property: 'h2CommissioningTimeline', 
                        key: 'commissioningTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Total', 
                        property: 'h2TotalProjectTimeline',
                        key: 'totalProjectTimeline'
                    }
                ]
            }
        ] 
    },
    { 
        key: 'timelineDesignDetails',
        type: 'timelineDetails',
        sections: [
            {
                key: 'engDesignDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'DesignPublicReview'
                    },
                    {
                        type: 'title',
                        title: 'Design & Public Design Review',
                        link: {
                            text: 'Find a design partner',
                            href: '/vendor_catalog'
                        },
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2DesignTimeline',
                        key: 'engDesignTimeline'
                    }
                ]
            }, 
            cardSets.design.electrolysis,
            cardSets.design.smr,
            cardSets.design.liquidDelivery,
            cardSets.design.gasDelivery
        ]
    },
    { 
        key: 'timelinePermittingDetails',
        type: 'timelineDetails',
        sections: [
            {
                key: 'engPermittingDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'Permitting'
                    },
                    {
                        type: 'title',
                        title: 'Permitting',
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2PermitTimeline',
                        key: 'permittingTimeline'
                    }
                ]
            }, 
            cardSets.permit.electrolysis,
            cardSets.permit.smr,
            cardSets.permit.liquidDelivery,
            cardSets.permit.gasDelivery,
            cardSets.permit.tubeTrailer 
        ]
    },
    { 
        key: 'timelineConstructionDetails',
        type: 'timelineDetails',
        sections: [
            {
                key: 'constructionTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'Construction'
                    },
                    {
                        type: 'title',
                        title: 'Construction',
                        link: {
                            text: 'Find a construction partner',
                            href: '/vendor_catalog'
                        },
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2ConstructionTimeline',
                        key: 'constructionTimeline'
                    }
                ]
            }, 
            cardSets.construction.electrolysis,
            cardSets.construction.smr,
            cardSets.construction.liquidDelivery,
            cardSets.construction.gasDelivery
        ]
    },
    { 
        key: 'timelineCommissioningDetails',
        type: 'timelineDetails',
        sections: [
            {
                key: 'commissioningTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'CommissioningAndInspection'
                    },
                    {
                        type: 'title',
                        title: 'Commissioning & Inspection',
                        link: {
                            text: 'Find a construction partner',
                            href: '/vendor_catalog'
                        },
                        key: 'comissioningTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2CommissioningTimeline',
                        key: 'commissioningTimeline'
                    }
                ]
            }, 
            cardSets.commissioning.electrolysis,
            cardSets.commissioning.smr,
            cardSets.commissioning.liquidDelivery,
            cardSets.commissioning.gasDelivery
        ]
    }
]
 
export default H2WorkplanData;
