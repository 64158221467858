import React from 'react';
import { Components, ComponentMetadata, utils } from '../../cap';
import h2Timelines from '../../tempScaffolding/h2Timelines.json';

import permitting from '../../assets/images/permitting.svg';
import designPublicReview from '../../assets/images/designPublicReview.svg';
import construction from '../../assets/images/construction.svg';
import commissioningAndInspection from '../../assets/images/commissioningAndInspection.svg';

import PageButtons from '../PageButtons';
import { EvCostEstimate, EvWorkPlan, EquipmentCatalog } from '../Pages';
import PageTitle from '../PageTitle';
import CostWorkPlanPageTitle from '../CostWorkPlanPageTitle'

const icons = {
    Permitting: permitting,
    DesignPublicReview: designPublicReview,
    Construction: construction,
    CommissioningAndInspection: commissioningAndInspection
};

const collection = {
    filteredH2WorkplanData: [],
    designTimeline: '1 - 8 months',
    permitTimeline: '1 - 2 months',
    constructionTimeline: '1 - 18 months',
    commissioningTimeline: '2 - 4 weeks',
    totalProjectTimeline: '3.5 - 29 months',
    h2DesignTimeline: '3 - 6 months',
    h2PermitTimeline: '12 months',
    h2ConstructionTimeline: '1 - 3 months',
    h2CommissioningTimeline: '1 - 2 months',
    h2TotalProjectTimeline: '17 - 23 months',
    h2SolutionType: 'tubeTrailer',
}

const EVWorkplanWrapper = () => {

    const evPageButtons = [
        { text: "View Cost Estimates", link: EvCostEstimate.link },
        { text: "View Workplan", link: EvWorkPlan.link },
        { text: "Equipment Catalog", link: EquipmentCatalog.link },
    ]

const title ="EV Workplan"
    const getWorkplan = () => {

        const { InfrastructureWorkplan } = Components;
        const { EVWorkplanData, H2WorkplanData } = ComponentMetadata;
        const filteredH2WorkplanData = [];
        const timelines = h2Timelines[collection.h2SolutionType];
        const timelineKeys = Object.keys(timelines);

        timelineKeys.forEach((key) => {
            collection[key] = timelines[key];
        });

        const h2WorkplanComparator = (val) => {
            if (!val.filterHook) {
                return true;
            }

            if (collection.h2SolutionType === val.filterHook) {
                return true;
            }

            return false;
        }

        H2WorkplanData.forEach((fieldGroup) => {
            const newFieldGroup = { ...fieldGroup };
            newFieldGroup.sections = utils.sourceDataFilter(fieldGroup.sections, h2WorkplanComparator);
            filteredH2WorkplanData.push(newFieldGroup);
        });

        const selector = (val) => {
            const dataOptions = {
                'electric': EVWorkplanData,
                'hydrogen': filteredH2WorkplanData
            }

            return (
                <React.Fragment>
                    <PageTitle page="EvWorkPlan" />
                    <div className="infrastructure-workplan">
                     <CostWorkPlanPageTitle title={title}/>
                        <InfrastructureWorkplan
                            componentMetadata={dataOptions[val]}
                            collection={collection}
                            icons={icons}
                            sourceDataFilter={utils.sourceDataFilter}
                        />
                        {PageButtons(evPageButtons)}
                    </div>
                </React.Fragment>
            )
        }

        return selector('electric');
    }

    return getWorkplan();
}

export default EVWorkplanWrapper;