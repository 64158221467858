import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  h2Demand: 0,
  h2DeliveryTubeTrailer: false,
  h2DeliveryGaseous: false,
  h2DeliveryLiquid: false,
  h2DeliveryElectrolysis: false,
  h2DeliverySteamMethaneReforming: false,
  selectedMethod: '',
  isSelectedH2DeliveryTubeTrailer: false,
  isSelectedH2DeliveryGaseous: false,
  isSelectedH2DeliveryLiquid: false,
  isSelectedH2DeliveryElectrolysis: false,
  isSelectedH2DeliverySteamMethaneReforming: false,
  selectedDeliveryMethodValue: ''
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {};
const reducers = {};

stateKeys.forEach((key) => {
  stateRetrievers[key] = (state) => {
    if (!state.H2Design) {
      return null;
    }
    return state.H2Design[key];
  }
  reducers[key] = (state, action) => {
       state[key] = action.payload;
  
}
});

export const H2DesignSlice = createSlice({
  name: "h2Design",
  initialState,
  reducers
});

export const updaters = H2DesignSlice.actions;

export const retrievers = stateRetrievers;

export default H2DesignSlice.reducer;
