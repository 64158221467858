import { PageTitleAndLinks } from './Pages';
import { Helmet } from 'react-helmet';

const PageTitle = ({ page, overrideTitle }) => {
    if( !PageTitleAndLinks.has(page)){
        throw new Error(`Cannot find page mapping for ${page}`)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Infrastructure Insite</title>
            </Helmet>
        </>
    )
}

export default PageTitle;


export const LoadingPageTitle = ({ message }) => {
     return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> {message ? message :'Loading...'}</title>
            </Helmet>
        </>
    )
}
