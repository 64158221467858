import React from 'react';
import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Slider, Typography, TextField } from "@material-ui/core";

const buildMenuItems = (options) => {
    return options.map((option, i) => {

        let name, value;

        if(option.name && option.value) {
            value = option.value;
            name = option.name;
        }

        else {
            value = option;
            name = option;
        }

        return (
            <MenuItem
                value={value}
                key={`${i}_${name}`}
            >{name}</MenuItem>
        )
    });
}

const toggleButton = (input, updateProperty, collection, classes) => {
    const workingProperty = collection[input.property];
    const buttonText = input.names[workingProperty];
    const toggleReverse = input.actions[workingProperty];

    const returnedElement = (
        <Button
            variant="contained"
            onClick={
                () => {
                    updateProperty(toggleReverse);
                }
            }
        >
            {buttonText}
        </Button>
    )

    return returnedElement;
};

const toggleTabs = (input, updateProperty, collection, classes) => {

    // When it's not really a boolean but we're filtering for one, the other, or neither.
    const hasNullState = input.hasNullState;

    const handleClick = (action) => {
        if(hasNullState) {
            action = collection[input.property]
        }

        updateProperty(action);
    }
        
    const returnedButtonGroup = (
        <React.Fragment>
            <Typography>{input.title}</Typography>
            <ButtonGroup 
                variant="contained"
            >
            {
                input.options.map((button) => {
                    return (
                        <Button
                            onClick={
                                () => {
                                    handleClick(button.action)
                                }
                            }
                            key={button.key} 
                        >{button.name}</Button>
                    )
                })
            }
            </ButtonGroup>
        </React.Fragment>
    )


    return returnedButtonGroup;
}

const buttonCluster = (input, updateProperty, collection, classes) => {
    const returnedElement = (
        <React.Fragment>
            {input.title}
            <Box 
                display="flex"
                flexWrap="wrap"
            >
                {
                    input.buttons.map((button, i) => {
                        return (
                            <Box
                                key={`${button.name}_${i}`}
                                width={input.buttonWidth || '50%'}
                            >
                                <Button
                                    className={classes.withinToggleDisplayButton}
                                    variant="contained"
                                    onClick={() => {
                                        const workingFilters = new Set(collection[input.property]);
                                        const action = input.action;

                                        if(workingFilters.has(action)) {
                                            workingFilters.delete(action);
                                        } else {
                                            workingFilters.add(action);
                                        }

                                        updateProperty(workingFilters);
                                    }}
                                >
                                    {button.name}
                                </Button>
                            </Box>
                        );
                    })
                }
            </Box>
        </React.Fragment>
    );
    
    return returnedElement;
};

const dropdown = (input, updateProperty, collection, optionsData, classes) => {
        
    const handleChange = (newValue) => {
        updateProperty(newValue);
    }

    const optionsToArray = (opts) => {
        const newArr = []
        const keys = Object.keys(opts);
        keys.forEach((key) => {
            const newObj = {
                name: opts[key],
                value: key
            }

            newArr.push(newObj);
        })

        return newArr;
    }

    const optionSource = optionsData || input.options;

    const options = Array.isArray(optionSource)
        ? optionSource
        : optionsToArray(input.options)

    const baseLabel = `${input.key}_${input.property}`;
    const label = `dropdown_${baseLabel}`;

    // Double dropdown - try putting InputLabel above the two form controls
    const returnedDropdown = (
        <FormControl className={classes.formControl}>
            {
                input.title &&
                (
                    <InputLabel shrink id={`${label}_label`}>
                        {input.title}
                    </InputLabel>
                )
            }
            <Select
                labelId={`${label}_label`}
                id={label}
                value={collection[input.property]}
                onChange={
                    (evt) => {
                        handleChange(evt.target.value);
                    }
                }
                displayEmpty
                className={classes.selectEmpty}
                fullWidth
            >
                <MenuItem value=''>
                    {input.placeholder || ''}
                </MenuItem>
                {
                    buildMenuItems(options)
                }
            </Select>
        </FormControl>
    );

    return returnedDropdown;
};

const catalogDropdown = (options, currentValue, onChange, name, label) => {
    const id = `${name}-id`;

    const inputProps = {
        name, 
        id
    }

    return (
        <FormControl >
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                native
                value={currentValue}
                onChange={onChange}
                inputProps={inputProps}>
                <option aria-label="None" value="" />
                {options.map( value =>  <option value={value}>{value}</option> )}
            </Select>
        </FormControl>        
    )
}

const slider = (input, updateProperty, collection /* value */, classes) => {
    let sliderValue = collection[input.property]; // should just be given the value

    const step = (input.max - input.min) / 100;

    const sliderMarks = Array.isArray(sliderValue) 
        ? (
            [
                {
                    value: sliderValue[0],
                    label: `${sliderValue[0]} ${input.unit}`
                },
                {
                    value: sliderValue[1],
                    label: `${sliderValue[1]} ${input.unit}`
                }
            ]
        )
        : (
            [
                {
                    value: sliderValue,
                    label: `${sliderValue} ${input.unit}`
                }
            ]
        )

    const typographyID = input.key;

    const handleChange = (evt, newValue) => {
        updateProperty(newValue)                
    }         

    return (
        <React.Fragment>
            <Typography 
                id={typographyID}
                gutterBottom
            >{input.title}</Typography>
            <Slider
                min={input.min}
                max={input.max}
                value={sliderValue}
                marks={sliderMarks}
                step={step}
                aria-labelledby={typographyID}
                valueLabelDisplay="off"   
                onChange={ handleChange }         
            />
        </React.Fragment>
    )
}

// pass in onblur, onchange - object called event handlers
const textfield = (input, updateProperty, collection, classes) => {
    const handleChange = (newText) => {
        updateProperty(newText);
    }

    const fieldValue = collection[input.property] || '';

    return (
        <TextField
            label={input.title}
            style={{ margin: 8 }}
            placeholder={input.placeholder}
            fullWidth
            value={fieldValue}
            onChange={
                () => {} // handle validation here.
                // create object that consists of onchange handlers depending on what the change is
                /*
                {
                    onChange: func,
                    onBlur: func,
                }
                */
            }
            onBlur={
                () => {
                    handleChange(fieldValue);  
                }            
            }
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
        />            

    );
}

export {
    toggleButton, buttonCluster, toggleTabs, dropdown, catalogDropdown,slider, textfield
}