import { Box, Button, Grid, Link, Typography } from "@material-ui/core";
import { TextField } from 'mui-rff';


const validate = values => {
    const errors = {};
    if (!values.userName) {
        errors.userName = 'Required';
    }

    return errors;
};

export const ForgotPasswordMetadataBuilder = () => (new _ForgotPasswordMetadata());
class _ForgotPasswordMetadata {
    constructor() {
        this.inputs = {
            onSubmitFunction: () => { },
            onCancelClick: () => { },
            errorMessage: ''
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return (
            ForgotPasswordMetadata(this.inputs.onSubmitFunction, this.inputs.onShowRegistrationClick, this.inputs.onCancelClick, this.inputs.errorMessage)
        )
    }
}

export const ForgotPasswordMetadata = (onSubmitFunction, onShowRegistrationClick, onCancelClick, errorMessage) => {
    return (
        {
            onSubmit: onSubmitFunction,
            validateFunction: validate,

            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "forgotPassword",
                        className: "forgot_password",
                        container: true,
                        alignItems: "center",
                        spacing: 2
                    },

                    elements: [
                        {
                            size: 12,
                            uiComponent:Typography,
                            props: {
                                text: errorMessage,
                                className: errorMessage ? 'error-message' : 'hidden-error-message',
                                children: errorMessage
                            }
                        },
                        {
                            size: 8,
                            uiComponent: Typography,
                            props: {
                                children: "Forgot Password?",
                                className: "headline"
                            }
                        },
                        {
                            size: 4,
                            uiComponent: Link,
                            props: {
                                className: "cancel_forgot_password",
                                children: "Cancel",
                                onClick: () => {
                                    onCancelClick();
                                }
                            }
                        },
                        {
                            size: 12,
                            uiComponent: Box,
                            props: {
                                className: "dont_have_account",
                                children: (
                                    <span>
                                    Enter your email and we’ll send you a code to set a new password.
                                    </span>
                                ),
                            }
                        },

                        {
                            size: 8,
                            uiComponent: TextField,
                            props: {
                                type: "text",
                                label: "Email",
                                placeholder: "",
                                InputLabelProps: { shrink: true },
                                name: "userName"
                            }
                        },
                        {
                            size: 4,
                            uiComponent: Button,
                            props: {
                                type: "submit",
                                variant: "contained",
                                className: "password_reset_submit_button",
                                children: "Send code",
                            }
                        },
                  
                    ]
                }
            ]
        }

    )
}
