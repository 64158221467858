import React from 'react';
import WifiIcon from '@material-ui/icons/Wifi';
import CheckIcon from '@material-ui/icons/Check';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Typography } from '@material-ui/core'
import emptyLogo from '../../src/assets/images/logos/emptyVendorLogo.png'
import { commafyNumber } from '../utils/numberFormatting';
import { YES } from '../data/constants'

const BottomFiller = ({
    bottomSpaceChecks
}) => {
    return (
        <>
            {bottomSpaceChecks.map((row) => {
                return row ?
                    null :
                    (<Box className="checked_item"></Box>);
            })
            }
        </>
    )
}

const ChargerCard = ({ catalogItem }) => {
    const cardAction = catalogItem.wifi && <WifiIcon />;
    const smartCharging = catalogItem.wifi && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>Smart Charging</Typography>
        </Box>
    )

    const plugTypePlural = (catalogItem.plug_types.split(',').length > 1) && 's';

    const plugTypes = (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>Plug Type{plugTypePlural}: {catalogItem.plug_types}</Typography>
        </Box>
    )

    const vgi = catalogItem.vgi_capable && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>Vehicle Grid Integration</Typography>
        </Box>
    )

    const variant = catalogItem.model_variant || [<br />];
    const make = (<Typography className="equip_make">{catalogItem.make}</Typography>)
    const model = (
        <>
            <Typography className="equip_model">{catalogItem.model}</Typography>
            <Typography className="equip_model">{variant}</Typography>
        </>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={make}
                subheader={model}
                action={cardAction}
                className="ev-cardheader"
            />
            <Box className="above_photo_text">
                <Typography className="charger_power">{catalogItem.current} | {catalogItem.kw_ac || catalogItem.kw_dc} KW </Typography>
                <Typography className="charger_price">${commafyNumber(catalogItem.price)}</Typography>
            </Box>
            <CardMedia
                className="equip_photo"
                component="img"
                src={catalogItem.img}
                title="Equipment photo"
            />
            <CardContent className="charger_card_content">
                <Box className="checked_item">
                    <CheckIcon />
                    <Typography>{catalogItem.num_of_ports} Plug{catalogItem.num_of_ports > 1 && 's'}</Typography>
                </Box>
                {plugTypes}
                {smartCharging}
                {vgi}
                <BottomFiller
                    bottomSpaceChecks={[smartCharging, vgi]}
                />

                <Button target="_blank" href={catalogItem.details_link} rel="noopener" className="catalog_card_button">
                    See details
                </Button>
            </CardContent>
        </Card>
    )
}

const H2ChillerCard = ({ catalogItem }) => {

    const make = (<Typography className="equip_make">{catalogItem.make}</Typography>)
    const model = (<Typography className="equip_model">{catalogItem.model}</Typography>)

    const productionRate = catalogItem.cooling_capacity_kW && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{catalogItem.cooling_capacity_kW} kW cooling capacity</Typography>
        </Box>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={make}
                subheader={model}
            />
            <CardMedia
                className="equip_photo"
                component="img"
                src={catalogItem['img_final_th_(225x150)']}
                title="Equipment photo"
            />
            <CardContent className="charger_card_content">
                {productionRate}
                <Button target="_blank" href={catalogItem.product_info_page} rel="noopener" className="catalog_card_button">
                    See details
                </Button>
            </CardContent>
        </Card>
    )
}

const H2CompressorCard = ({ catalogItem }) => {

    const make = (<Typography className="equip_make">{catalogItem.make}</Typography>)
    const model = (<Typography className="equip_model">{catalogItem.model}</Typography>)

    const flowRate = catalogItem.flow_rate_m3_hour && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{commafyNumber(catalogItem.flow_rate_m3_hour)} cubic meters per hour</Typography>
        </Box>
    )

    const pressure = catalogItem.pressure_PSI && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{commafyNumber(catalogItem.pressure_PSI)} psi</Typography>
        </Box>
    )

    const power = catalogItem.power_KW && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{commafyNumber(catalogItem.power_KW)} kW</Typography>
        </Box>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={make}
                subheader={model}
            />
            <Box className="above_photo_text">
                <Typography className="equip_variant">{catalogItem.model_variant}</Typography>
            </Box>
            <CardMedia
                className="equip_photo"
                component="img"
                src={catalogItem['img_final_th_(225x150)']}
                title="Equipment photo"
            />
            <CardContent className="charger_card_content">
                {flowRate}
                {pressure}
                {power}
                <BottomFiller
                    bottomSpaceChecks={[flowRate, pressure, power]}
                />

                <Button target="_blank" href={catalogItem.info_page} rel="noopener" className="catalog_card_button">
                    See details
                </Button>

            </CardContent>
        </Card>
    )
}

const H2DispenserCard = ({ catalogItem }) => {

    const make = (<Typography className="equip_make">{catalogItem.make}</Typography>)
    const model = (<Typography className="equip_model">{catalogItem.model}</Typography>)

    const d70MPa = catalogItem._70MPa_dispenser === YES && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>70 MPa dispensing</Typography>
        </Box>
    )

    const d35MPa = catalogItem._35MPa_dispenser === YES && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>35 MPa dispensing</Typography>
        </Box>
    )

    const iPOS = catalogItem.integrated_point_of_sale === YES && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>Integrated Point of Sale</Typography>
        </Box>
    )

    const abovePhotoText = (
        <>
            {
                catalogItem.model_variant ?
                    (<Typography className="equip_variant">{catalogItem.model_variant}</Typography>) :
                    (<div className="equip_variant">&nbsp;</div>)
            }
        </>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={make}
                subheader={model}
            />
            <Box className="above_photo_text">
                {abovePhotoText}
            </Box>
            <CardMedia
                className="equip_photo"
                component="img"
                src={catalogItem['img_final_th_(225x150)']}
                title="Equipment photo"
            />
            <CardContent className="charger_card_content">
                {d70MPa}
                {d35MPa}
                {iPOS}
                <BottomFiller
                    bottomSpaceChecks={[d70MPa, d35MPa, iPOS]}
                />
                <Button target="_blank" href={catalogItem.product_info_page} rel="noopener" className="catalog_card_button">
                    See details
                </Button>
            </CardContent>
        </Card>
    )
}

const H2ElectrolyzerCard = ({ catalogItem }) => {

    const make = (<Typography className="equip_make">{catalogItem.make}</Typography>)
    const model = (<Typography className="equip_model">{catalogItem.model}</Typography>)

    const productionRate = catalogItem.net_production_rate && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{catalogItem.net_production_rate} cubic meters / hour</Typography>
        </Box>
    )

    const flowRate = catalogItem.flow_rate && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{commafyNumber(catalogItem.flow_rate)} cc / hour</Typography>
        </Box>
    )

    const abovePhotoText = (
        <>
            {
                catalogItem.model_variant ?
                    (<Typography className="equip_variant">{catalogItem.model_variant}</Typography>) :
                    (<div className="equip_variant">&nbsp;</div>)
            }
        </>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={make}
                subheader={model}
            />
            <Box className="above_photo_text">
                {abovePhotoText}
            </Box>
            <CardMedia
                className="equip_photo"
                component="img"
                src={catalogItem['img_final_th_(225x150)']}
                title="Equipment photo"
            />
            <CardContent className="charger_card_content">
                {productionRate}
                {flowRate}
                <Button target="_blank" href={catalogItem.product_info_page} rel="noopener" className="catalog_card_button">
                    See details
                </Button>
            </CardContent>
        </Card>
    )
}

const H2StorageCard = ({ catalogItem }) => {

    const make = (<Typography className="equip_make">{catalogItem.make}</Typography>)
    const model = (<Typography className="equip_model">{catalogItem.model}</Typography>)

    const storageType = catalogItem.type && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{catalogItem.type}</Typography>
        </Box>
    )

    const capacity = catalogItem.capacity && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{commafyNumber(catalogItem.capacity)} cubic meters</Typography>
        </Box>
    )

    const pressure = catalogItem.pressure && (
        <Box className="checked_item">
            <CheckIcon />
            <Typography>{commafyNumber(catalogItem.pressure)} MPa</Typography>
        </Box>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={make}
                subheader={model}
            />
            <Box className="above_photo_text">
                <Typography className="equip_variant">{catalogItem.model_variant}</Typography>
            </Box>
            <CardMedia
                className="equip_photo"
                component="img"
                src={catalogItem['img_final_th_(225x150)']}
                title="Equipment photo"
            />
            <CardContent className="charger_card_content">
                {storageType}
                {capacity}
                {pressure}
                <Button target="_blank" href={catalogItem.product_info_page} rel="noopener" className="catalog_card_button">
                    See details
                </Button>
            </CardContent>
        </Card>
    )
}

const ElectricVendorCard = ({ catalogItem }) => {

    const doTypography = (inStr) => {
        return <Typography>{inStr}</Typography>
    }

    const roles = (
        <>
            {catalogItem.engineering && doTypography('engineering')}
            {catalogItem.construction && doTypography('construction')}
            {catalogItem.procurement && doTypography('procurement')}
        </>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={roles}
            />
            <CardMedia
                className="vendor_logo"
                component="img"
                src={catalogItem.img || emptyLogo}
                title="Vendor logo"
            />
            <CardContent className="charger_card_content">
                <p><strong>{catalogItem.name}</strong></p>
                <Button target="_blank" href={catalogItem.vendor_url} rel="noopener" className="catalog_card_button">
                    See Website
                </Button>
            </CardContent>
        </Card>
    )
}

const H2VendorCard = ({ catalogItem, logos }) => {

    const doTypography = (inStr) => {
        return <Typography>{inStr}</Typography>
    }

    const roles = (
        <>
            {catalogItem.production && doTypography('production')}
            {catalogItem.dispensing_storage && doTypography('dispensing & storage')}
        </>
    )

    return (
        <Card className="catalog_card">
            <CardHeader
                title={roles}
            />
            <CardMedia
                className="vendor_logo"
                component="img"
                src={logos[catalogItem.img] || emptyLogo}
                title="Vendor logo"
            />
            <CardContent className="charger_card_content">
                <p><strong>{catalogItem.name}</strong></p>
                <Button target="_blank" href={catalogItem.vendor_url} rel="noopener" className="catalog_card_button">
                    See Website
                </Button>
            </CardContent>
        </Card>
    )
}

export {
    ChargerCard,
    H2ChillerCard,
    H2CompressorCard,
    H2DispenserCard,
    H2ElectrolyzerCard,
    H2StorageCard,
    ElectricVendorCard,
    H2VendorCard
}