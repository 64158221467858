

import React from "react";
import { Card, Box, Grid, Typography } from "@material-ui/core"
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';


const WhichCheckBox = ({ isViable, isSelected }) => {
    if (isViable && isSelected)
        return <CircleCheckedFilled style={{ color: '#3277B9' }} className="h2DeliveryMethod_card_checkBox" />
    else if (isViable)
        return <CircleCheckedFilled style={{ color: '#EBEBEB' }} className="h2DeliveryMethod_card_checkBox" />
    return <React.Fragment />
}



export const H2DeliveryMethodCard = (cardObject) => {
    return (
        <Grid item xs >
            <Card elevation={(cardObject.checkBox ? 10 : 0)}
                className={"h2DeliveryMethod_card " + (cardObject.checkBox ? "is_selected " : "") + (cardObject.viability ? "" : "not_viable")}
                onClick={cardObject.onClick}>
                <Box className="h2DeliveryMethod_card_titleCheckBox">
                    <Box>{cardObject.title}</Box>
                    <WhichCheckBox isViable={cardObject.viability} isSelected={cardObject.checkBox} />
                </Box>
                <p className="subtitle-text">{cardObject.subTitle}</p>
                <img src={cardObject.image} className="h2DeliveryMethod_card_image" alt="delivery method icon"/>
                <ul className="h2Design_costs_breakdown_list">
                    <li><Box className="h2Design_costs_breakdown_title">Upfront Cost</Box><Box className="h2Design_costs_breakdown_value">{cardObject.upfrontCosts}</Box></li>
                    <li><Box className="h2Design_costs_breakdown_title">Daily Ongoing Cost</Box><Box className="h2Design_costs_breakdown_value">{cardObject.ongoingCosts}</Box></li>
                    <li><Box className="h2Design_costs_breakdown_title">Timeline</Box><Box className="h2Design_costs_breakdown_value">{cardObject.timeline}</Box></li>
                </ul>
            </Card>
            <Box className="h2DeliveryMethod_viability">{cardObject.viability ? <br /> : <Typography className="h2DeliveryMethod_viability_text"> &mdash; may not be viable &mdash;</Typography>}</Box>
        </Grid>
    )
}