import React from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import FuelingAlternativesCard  from '../FuelingAlternativesCard';

// @TODO article on sass with material-ui: https://www.markmakesstuff.com/posts/mui-css-modules 
const useStyles = makeStyles((theme) => ({ // write a theme rather than overriding it, yeah?
    root: {
      textAlign: 'left',
      fontSize: theme.typography.pxToRem(16)
    },
    heading: {
      fontWeight: 700,
      fontSize: '1rem',
      margin: '2rem 0 1rem 0',
      textTransform: 'uppercase'
    }
  }));  

  const accordionTheme = createMuiTheme({
    overrides: {
      MuiTypography: {
        h1: {
          fontWeight: 400,
          fontSize: '1.25em' 
        }, 
        h2: {
          fontWeight: 700,
          fontSize: '1em',
          marginBottom: '2em'
        },
        body2: {
          color: '#183B56',
          lineHeight: '1.5rem',
          fontSize: '1rem'
        }
      },

    }
  })


const AccordionInstance = (
  {
    accordionContent,
    cardImages
  }
) => {

  return (
    <ThemeProvider
      theme={accordionTheme}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
            <Typography variant="h1">{accordionContent.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justify="center"
            direction="column"
            spacing={3}
          >
          {
            accordionContent.sections &&
            accordionContent.sections.map((section) => {
              const sectionComponent = {
                paragraph: () => (
                  <Typography variant="body2">{section.text}</Typography>
                ),
                cards: () => {
                  if(!section.cards || !section.cards.length) {
                    return null;
                  }

                  const numberOfCards = section.cards.length;
                  const mdCardWidth = Math.floor(12 / numberOfCards);
                  const smCardWidth = (mdCardWidth) * 2 > 6
                    ? mdCardWidth
                    : mdCardWidth * 2;
                  const xsCardWidth = 12;

                  return (
                    <Box>
                      <Typography
                        variant="h2"
                      >{section.title}</Typography>
                      <Grid
                        container
                        justify="center"
                        direction="row"
                        spacing={2} 
                      >
                        {
                          section.cards.map((card) => {
                          return (
                            <Grid
                              item
                              key={ `${card.cardTitle}_${accordionContent.title}`}
                              xs={xsCardWidth}
                              sm={smCardWidth}
                              md={mdCardWidth}      
                            >
                              <FuelingAlternativesCard
                                data={{
                                    img: cardImages[card.img],
                                    title: card.cardTitle,
                                    text: card.text,
                                    learnMore: card.learnMore,
                                    className: card.className
                                  }   
                                }
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  )
                }
              }

              const returnedSection = sectionComponent[section.type]();
              const returnedElement=(
                <Grid
                  key={`${accordionContent.title}_${section.title}`}
                  item
                  xs={12}
                >
                  {returnedSection}
                </Grid>
              );

              return returnedElement;
            })
          }
          </Grid>
        </AccordionDetails>
    </Accordion>
  </ThemeProvider>
  );
}

// @TODO move to separate module?
const FuelingAlternatives = ( // Switching to this approach so we can use standard React props passing. Enables us to more conveniently set a key.
  {  
    fuelingContent, 
    cardImages,
    renderFunction = null 
  }
) => {

    const classes = useStyles();

    const defaultRenderFunction = (fuelingAlternativeContent) => {

        return (
            <div className={classes.root}>
              <div className={classes.heading}>
                {fuelingAlternativeContent.title}
              </div>
              <Box
                className="boxShadowGroup"
              >
                {
                  fuelingAlternativeContent.sections 
                  && fuelingAlternativeContent.sections.map((accordion) => {
                    return (
                      <AccordionInstance
                        accordionContent={accordion}
                        cardImages={cardImages}
                        key={`${accordion.title}_${fuelingAlternativeContent.title}`}
                      />
                    )
                  })
                } 
              </Box>           
            </div>
        );
    }

    const doRender = renderFunction || defaultRenderFunction;

    return doRender(fuelingContent);
  }

// @TODO index.js should call FuelingAlternatives, not app .js.  

export default FuelingAlternatives;