import {
    CHARGER_TYPE_AC_WIRELESS,
    CHARGER_TYPE_AC_WIRED,
    CHARGER_TYPE_DC_WIRED,
    CHARGER_TYPE_DC_OVERHEAD
} from '../constants';
import { RECOMMENDED_CHARGER_DETAILS } from '../data';

const roundUpPlugSize = (initialSize) => {
    const breakpoints = [7.2, 7.7, 16.8, 19.2, 50, 150, 350]

    if(initialSize < breakpoints[0]) {
        return breakpoints[0];
    }

    for(let i = 1; i < breakpoints.length; i++) {
        if(initialSize > breakpoints[i - 1] && initialSize <= breakpoints[i]) {
            return breakpoints[i];
        }
    }
}

const getChargerRecommendation = (onRoute, neededPlugCapacity, plugCount, otherInterests = {
    wireless: false,
    overhead: false
}) => {
    const recommendedCharger = {
        chargerType: null,
        chargerCount: plugCount,
        chargerPower: 0,
        checkWireless: false,
        checkOverhead: false
    }

    const multiPlugChargers = new Set([CHARGER_TYPE_AC_WIRED, CHARGER_TYPE_DC_WIRED])

    const roundedPlugSize = roundUpPlugSize(neededPlugCapacity);

    recommendedCharger.chargerPower = roundedPlugSize;

    if(roundedPlugSize === 7.2) {
        recommendedCharger.checkWireless = true;
        if(otherInterests.wireless) {
            recommendedCharger.chargerType = CHARGER_TYPE_AC_WIRELESS;
        } else {
            recommendedCharger.chargerPower = 7.7;
            recommendedCharger.chargerType = CHARGER_TYPE_AC_WIRED;
            recommendedCharger.chargerCount = plugCount / 2;
        }
    } else if(roundedPlugSize >= 150 && onRoute) {
        recommendedCharger.checkOverhead = true;
        if(otherInterests.overhead) {
            recommendedCharger.chargerType = CHARGER_TYPE_DC_OVERHEAD;
        } else {
            recommendedCharger.chargerType = CHARGER_TYPE_DC_WIRED;
        }
    } else if(roundedPlugSize < 50) {
        recommendedCharger.chargerType = CHARGER_TYPE_AC_WIRED;
        recommendedCharger.chargerCount = plugCount / 2;
    } else {
        recommendedCharger.chargerType = CHARGER_TYPE_DC_WIRED;
    }

    const divisor = multiPlugChargers.has(recommendedCharger.chargerType) ? 2 : 1;

    recommendedCharger.chargerCount = Math.ceil(recommendedCharger.chargerCount / divisor);

    const chargerDetailsKeys = Object.keys(RECOMMENDED_CHARGER_DETAILS);

    for(let i = 0; i < chargerDetailsKeys.length; i++) {
        const testCharger = RECOMMENDED_CHARGER_DETAILS[chargerDetailsKeys[i]];

        const chargerTypeMatches = testCharger.chargerType.replace(' 2 plugs', '') === recommendedCharger.chargerType;

        if(chargerTypeMatches && testCharger.chargerPower === recommendedCharger.chargerPower) {
            recommendedCharger.chargerID = chargerDetailsKeys[i];
            recommendedCharger.chargerCost = testCharger.chargerCost;
            break;
        }
    }

    return recommendedCharger;
}

export default getChargerRecommendation;