import React from 'react';
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import {InsertIntoGridItem } from '../../Components'

const dropdownStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const buttonStyles = makeStyles((theme) => ({
    withinToggleDisplay: {
        minWidth: '50%'
    },
    withinToggleDisplayButton: {
        marginTop: '0.5rem',
        minWidth: '80%'
    }
}))

const CatalogFilters = (
    {
        componentMetadata,
        renderingFunction,
        toggleButton, 
        buttonCluster, 
        toggleTabs,
        dropdown,
        slider,
        collection, 
        selectOptions,
        onChange
    }
) => {
    const classes = dropdownStyles();
    const buttonClasses = buttonStyles();

    const renderElementsInSection = (section, sectionIterator) => {
        const noOp = () => { return; }

        const interactiveHandlers = {
            toggleButton: (input) => {
                const updateProperty = (newVal) => {
                    onChange(input.property, newVal)
                }    

                return toggleButton(input, updateProperty, collection, classes)
            },

            buttonCluster: (input) => {
                const updateProperty = (newVal) => {
                    onChange(input.property, newVal)
                }

                return buttonCluster(input, updateProperty, collection, buttonClasses)
            },

            toggleTabs: (input) => {
                const updateProperty = (newVal) => {
                    onChange(input.property, newVal)
                }

                return toggleTabs(input, updateProperty, collection, classes)
            },
            buttonGroupStack: (input) => {
                const returnedButtonGroup = (
                    <Box
                        key={input.key}
                    >
                        {                            

                            input.buttonGroups.map((buttonGroup) => {
                                return interactiveHandlers.toggleTabs(buttonGroup);
                            })
                        }
                    </Box>
                )

                return returnedButtonGroup;
            }, 
            dropdown: (input) => {
                const updateProperty = (newVal) => {
                    onChange(input.property, newVal)
                }

                const dropdownOptions = selectOptions[input.property];

                return dropdown(input, updateProperty, collection, dropdownOptions, classes)                
            },
            rangeSlider: (input) => {
                const updateProperty = (newVal) => {
                    onChange(input.property, newVal)
                }

                return slider(input, updateProperty, collection, classes)                                
            }
        }

        const sectionTypeHandlers = {
            
            interactive: (element, elIterator) => {

                const elementHandler = interactiveHandlers[element.type] || noOp;
                const interactiveElement = elementHandler(element);

                return InsertIntoGridItem(interactiveElement, section, `section${sectionIterator}_element${elIterator}`, element.scaleOverrides);
            }
        }

        const sectionTypeHandler = sectionTypeHandlers[section.type] || noOp;
        

        return(
            <Grid
                key={section.key}
                spacing={section.spacing || 2}
                container
            >
                {
                    section.elements.map((element, i) => {
                        return sectionTypeHandler(element, i);
                    })
                }
            </Grid>
        )
    };
    
    const defaultRenderer = () => {    
        const defaultFieldGroupRenderer = (fieldGroup) => {
            return fieldGroup.sections.map((section, i) => {
                return renderElementsInSection(section, i);
            })
        }

        const fieldGroupRenderers = {
            visibilityToggleable: (fieldGroup) => {

                if(collection[fieldGroup.property] === 'hidden') {
                    return null;
                }

                return defaultFieldGroupRenderer(fieldGroup);
            }
        }

        return (
            <Box>
                {componentMetadata.map((fieldGroup) => {
                    const groupRender = fieldGroupRenderers[fieldGroup.type] || defaultFieldGroupRenderer;

                    return groupRender(fieldGroup)
                })}
            </Box>
        )
    }

    const doRender = renderingFunction || defaultRenderer;

    return doRender();
}

export default CatalogFilters;