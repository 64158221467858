import H2DepotFueling from './h2DepotFueling'

export default class H2OnRouteFueling extends H2DepotFueling {
  constructor() {
    super()
    this.inputs = Object.assign({}, this.inputs, { VehicleFlow: 0, AdditionalMilesPerFuelingSession: 0})

     // //dynamically generating setters for inputs in constructor
     Object.keys(this.inputs).forEach(input =>{
      this[`with${input}`] = (value) => {
        this.inputs[`${input}`] = value;
        return this
   }})
  }
 
  calculateOnRoute() {
    var _dailyH2FleetDemand = super.calculate().vehicleKGH2PerMile;
    var _h2KgRequiredPerOnRouteFueling = this.inputs.AdditionalMilesPerFuelingSession * _dailyH2FleetDemand;
    var _h2OnRouteDemand = this.inputs.VehicleFlow * _h2KgRequiredPerOnRouteFueling;
    var _vehiclesFueledPerDispenser = super.calculate().vehiclesFueledPerDispenser
    var _dispenserDemandCount = this.inputs.VehicleFlow / super.calculate().vehiclesFueledPerDispenser;

    return{
    
     dailyH2FleetDemand: _dailyH2FleetDemand,
     h2KgRequiredPerOnRouteFueling: _h2KgRequiredPerOnRouteFueling,
     h2OnRouteDemand: _h2OnRouteDemand,
     vehiclesFueledPerDispenser: _vehiclesFueledPerDispenser,
     dispenserDemandCount: _dispenserDemandCount
    }
  }
}
