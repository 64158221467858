import {timeDifference} from '../utils';

const kwhPerChargePerVehicle = (kwHPerMile, additionalMilesPerCharge) => {
    return kwHPerMile * additionalMilesPerCharge; 
}

const dailyMaxSessionCount = (chargeDuration, opens, closes) => {
    const chargingWindowDuration = timeDifference(opens, closes);

    return (chargingWindowDuration * 60) / chargeDuration;
}

const onRoutePlugCount = (vehiclesPerDay, chargeDuration, opens, closes) => {
    const plugCount = vehiclesPerDay / dailyMaxSessionCount(chargeDuration, opens, closes);

    return plugCount;
}

const plugKilowattRequiredCapacityOnRoute = (kwHPerMile, additionalMilesPerCharge, chargeDuration, maxPlugLoad = 350) => {
    const capacity = kwhPerChargePerVehicle(kwHPerMile, additionalMilesPerCharge) / (chargeDuration / 60);

    if(capacity > maxPlugLoad) {
        throw new Error('Required capacity per plug exceeds 350 kW');
    }

    return capacity;
}

export {
    plugKilowattRequiredCapacityOnRoute,
    kwhPerChargePerVehicle,
    dailyMaxSessionCount,
    onRoutePlugCount
}