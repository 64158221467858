import React from 'react';
import { Grid, Box } from '@material-ui/core';
import TitleComponentBox from '../TitleComponentBox';
import Dropdown from '../DropDowns';


const TitleDropDown = ({ title, className = "", options, formControlProps, inputLabelProps, selectProps, }) => {
    return (
        <TitleComponentBox title={title} className={className}>
            <Dropdown options={options}
                formControlProps={formControlProps}
                inputLabelProps={inputLabelProps}
                selectProps={selectProps}
            />
        </TitleComponentBox>
    )
}

export function EvDesignMetadataBuilder() { return (new _EvDesignMetadataBuilder()) }

export class _EvDesignMetadataBuilder {

    constructor() {
        this.inputs =
        {
            chargerDropDownOptions: null,
            chargerValue: null,
            onChargerValueChange: () => { },
            chargerQuantityDropDownOptions: null,
            chargerQuantityValue: 0,
            onChargerQuantityValueChange: () => { },
            totalCapacityValue: 0,
            recommendation: false,
            editButton: false,
            trenchingSliderValue: null,
            trenchingSlider: {},
            editModeSetTo: true
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            let functionNameValue = `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return EvDesignMetadata(
            this.inputs.chargerDropDownOptions,
            this.inputs.chargerValue,
            this.inputs.onChargerValueChange,
            this.inputs.chargerQuantityDropDownOptions,
            this.inputs.chargerQuantityValue,
            this.inputs.onChargerQuantityValueChange,
            this.inputs.totalCapacityValue,
            this.inputs.trenchingSlider,
            this.inputs.recommendation,
            this.inputs.editButton,
            this.inputs.editModeSetTo
        )
    }

}

export const EvDesignMetadata = (
    chargerDropDownOptions,
    chargerValue,
    onChargerValueChange,
    chargerQuantityDropDownOptions,
    chargerQuantityValue,
    onChargerQuantityValueChange,
    totalCapacityValue,
    trenchingSlider) => {


    return (
        {
            sections: [
                {
                    uiComponent: Box,
                    props: {
                        id: "evDesignMetadataCharger",
                        className: "ev_design_charger section",
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                id: "",
                                className: "ev_design",
                                container: true,
                                alignItems: "flex-start",
                                //  width: '100%',
                                height: '300px',
                                spacing: 2,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 12
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        title: "Equipment",
                                        tooltip: "We assume one vehicle & charger type, one charging window per day."
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 6
                                    },
                                    uiComponent: TitleDropDown,
                                    props: {
                                        options: chargerDropDownOptions,
                                        title: "Charger",
                                        formControlProps: {
                                            variant: "outlined",
                                            className: "ev_design_chager_select_formControl",
                                            fullWidth: true,
                                        },
                                        inputLabelProps: {
                                            id: "evDesignChargerSelectLabel",
                                            className: "ev_design_chager_select_label inputLabel",
                                            shrink: false,
                                        },
                                        selectProps: {
                                            labelId: "evDesignChargerSelectLabel",
                                            id: "evDesignChargerSelect",
                                            value: chargerValue,
                                            onChange: onChargerValueChange,
                                            displayEmpty: true,
                                            className: "ev_design_chager_select select",
                                            inputProps: {
                                                name: 'charger',
                                                id: 'evDesignChargerSelectCharger',
                                            }
                                        },
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 3
                                    },
                                    uiComponent: TitleDropDown,
                                    props: {
                                        title: "Quantity",
                                        options: chargerQuantityDropDownOptions,
                                        formControlProps: {
                                            variant: "outlined",
                                            className: "ev_design_chager_select_formControl",
                                            fullWidth: true,
                                        },
                                        inputLabelProps: {
                                            id: "evDesignChargerSelectLabel",
                                            className: "ev_design_chager_select_label inputLabel",
                                        },
                                        selectProps: {
                                            labelId: "evDesignChargerSelectLabel",
                                            id: "evDesignChargerSelect",
                                            value: chargerQuantityValue,
                                            onChange: onChargerQuantityValueChange,
                                            displayEmpty: true,
                                            className: "ev_design_chager_select select",
                                            inputProps: {
                                                name: 'chargerQuantity',
                                                id: 'evDesignChargerSelectChargerQuantity',
                                            }
                                        }
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 3,
                                        lg: 2
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        className: "capacity",
                                        title: "Total Capacity",
                                        children: totalCapacityValue
                                    }
                                },
                            ]
                        },
                    ]
                },
                {
                    uiComponent: Box,
                    props: {
                        id: "evDesignMetadataTrenching",
                        className: "ev_design_trenching section",
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                id: "",
                                className: "ev_design",
                                container: true,
                                alignItems: "flex-start",
                                height: '300px',
                                spacing: 2,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 12
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        className: "trenching_title",
                                        title: "Trenching",
                                        tooltip: "Based on CALSTART data for similar projects, cost of trenching includes ancillary equipment, piping, electrical work, and other associated construction costs."
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 5
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        children: trenchingSlider
                                    }
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    )
}