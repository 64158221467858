import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  idManagementSession: null,
  isLoggedIn: false,
  isModalOpen: false,
  settingsID: null, 
  version: 0,
  hydrated: false,
  userSettings: {}
}

const stateRetrievers = {};
const reducers = {};

const stateKeys = Object.keys(initialState);

stateKeys.forEach((key) => {
  stateRetrievers[key] = (state) => {
    if (!state.AuthenticatedUser) {
      return null;
    }
    return state.AuthenticatedUser[key];
  }

  reducers[key] = (state, action) => {
    state[key] = action.payload;
  }
});

export const AuthenticatedUserSlice = createSlice({
  name: 'AuthenticatedUser',
  initialState,
  reducers
});

export const authenticatedUserReducers = AuthenticatedUserSlice.actions;

export const authenticatedUserRetrievers = stateRetrievers;

export default AuthenticatedUserSlice.reducer;