import { Grid, Box } from '@material-ui/core';
import TitleComponentBox from '../TitleComponentBox';

export function EvDesignRecommendationMetadataBuilder() { return (new _EvDesignRecommendationMetadataBuilder()) }

export class _EvDesignRecommendationMetadataBuilder {

    constructor() {
        this.inputs =
        {
            chargerValue: null,
            chargerQuantityValue: 0,
            totalCapacityValue: 0,
            trenchingSliderValue: null,
            trenchingSlider: {},
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            let functionNameValue = `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return EvDesignRecommendationMetadata(
            this.inputs.chargerValue,
            this.inputs.chargerQuantityValue,
            this.inputs.totalCapacityValue,
            this.inputs.trenchingSlider,
        )
    }

}

export const EvDesignRecommendationMetadata = (
    chargerValue,
    chargerQuantityValue,
    totalCapacityValue,
    trenchingSlider) => {

    return (
        {
            sections: [
                {
                    uiComponent: Box,
                    props: {
                        id: "EvDesignRecommendationMetadataCharger",
                        className: "ev_design_charger section",
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                id: "",
                                className: "ev_design",
                                container: true,
                                alignItems: "flex-start",
                                //  width: '100%',
                                height: '300px',
                                spacing: 2,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 12
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        title: "Equipment",
                                        tooltip: "We assume one vehicle & charger type, one charging window per day."
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 6
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        title: "Charger",
                                        children: chargerValue
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 3
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        title: "Quantity",
                                        children: chargerQuantityValue
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 3,
                                        lg: 2
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        className: "capacity",
                                        title: "Total Capacity",
                                        children: totalCapacityValue
                                    }
                                },
                            ]
                        },
                    ]
                },
                {
                    uiComponent: Box,
                    props: {
                        id: "EvDesignRecommendationMetadataTrenching",
                        className: "ev_design_trenching section",
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                id: "",
                                className: "ev_design",
                                container: true,
                                alignItems: "flex-start",
                                height: '300px',
                                spacing: 2,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 12
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        className: "trenching_title",
                                        title: "Trenching",
                                    }
                                },
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        md: 5
                                    },
                                    uiComponent: TitleComponentBox,
                                    props: {
                                        children: trenchingSlider
                                    }
                                },

                            ]
                        }
                    ]
                }
            ]
        }
    )
}