import { Box, Typography} from '@material-ui/core';
import CSTooltip from './CSTooltip';

const TitleComponentBox = ({ title, children, className = "", tooltip }) => {
    return (
        <Box className={`title_component_box ${className}`}>
            <Box display="flex">
                <Typography className="title">{title}</Typography>
                {tooltip &&
                    <CSTooltip 
                        buttonClassName="title-component-tooltip info-icon"
                        details={tooltip}
                    />
                }
            </Box>
            <Box className="component">{children}</Box>
        </Box>
    )
}

export default TitleComponentBox;