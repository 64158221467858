export default class AuthConfigBuilder {    
    
    withIdentityPoolId(){  return this;}
    withRegion(){ return this;}
    withIdentityPoolRegion(){return this;}
    withUserPoolId(){return this;}
    withUserPoolWebClientId(){return this;}
    hasMandatorySignIn(){return this;}
    withCookieStorage() {return this;}
    withStorage(){return this;}
    withAuthenticationFlowType(){return this;}
    withClientMetadata(){return this;}
    withOauth(){return this;}
    
    constructor() {
        this.inputs = {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID  
            //Example: XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab
            identityPoolId: '',

            // REQUIRED - Amazon Cognito Region 
            //Example: XX-XXXX-X
            region: '',

            // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
            // Required only if it's different from Amazon Cognito Region
            // Example: XX-XXXX-X
            identityPoolRegion: '',

            // OPTIONAL - Amazon Cognito User Pool ID = 
            // Example: XX-XXXX-X_abcd1234
            userPoolId: '',

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            // Example: a1b2c3d4e5f6g7h8i9j0k1l2m3
            userPoolWebClientId: '',

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,

            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            cookieStorage: null,
           
            // OPTIONAL - customized storage object
            storage: null,

            // OPTIONAL - Manually set the authentication flow type. 
            // Default is 'USER_SRP_AUTH' or USER_PASSWORD_AUTH
            authenticationFlowType: '',

            // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            // Example : { myCustomKey: 'myCustomValue' }
            clientMetadata: null,

            // OPTIONAL - Hosted UI configuration
            oauth: null
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return this.inputs
    }
}

export class AuthOauthUiBuilder {
    constructor() {

        this.inputs = {
            //Example: 'your_cognito_domain',
            domain: '',
            // Example: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            scope: null,
            // Example: http://localhost:3000/
            redirectSignIn: '',
            // Example: http://localhost:3000/
            redirectSignOut: '',
            // Example: 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
            responseType: ''
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })

    }
    build() {
        return this.inputs
    }
}
export class AuthCookieStorageBuilder {
    constructor() {

        this.inputs = {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            // Example: '.yourdomain.com'
            domain: '',
            // OPTIONAL - Cookie path
            // Example: '/'
            path: '',
            // OPTIONAL - Cookie expiration in days
            // Example: 365 
            expires: 0,
            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            // Example: "strict" | "lax",
            sameSite: '',
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })

    }
    build() {
        return { Auth: {...this.inputs}}
    }
}


