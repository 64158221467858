import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    h2EngDesignCost: {
        min: 10,
        max: 30
    },
    h2PermitFeesCost: {
        min: 20,
        max: 50
    },
    h2HardwareInstallationCost: {
        min: 0,
        max: 0
    },
    totalProjectCost: {
        min: 0,
        max: 0
    },
    dispenserCost: {
        min: 50,
        max: 75,
    },
    humanMachineInterface: {
        min: 200,
        max: 300,
    },
    liquidPump: {
        min: 125,
        max: 250,
    },
    storageSupplyTank: {
        min: 600,
        max: 1000,
    },
    preCoolerCost: {
        min: 200,
        max: 200
    },
    tankVolumnPerH2Kg:{
        min: 1.4,
        max: 1.4
    }, 
    fuelingStorageCostByTankVolumn: {
        min: 850,
        max: 850,
    },
    steamMethaneReformingModule: {
        min: 1300,
        max: 1300,
    },
    electrolyzer: {
        min: 1800,
        max: 1800,
    },
    kgRemovedFromGasCompressor:{
        min: 85,
        max: 85,
    }, 
    perH2KgGasCompressor: {
        min: 1.26,
        max: 1.26,
    },
    gasCompressorMinimum: {
        min: 125,
        max: 125,
    },
}

const stateKeys = Object.keys(initialState);
const stateRetrievers = {}; 
const reducers = {};
stateKeys.forEach((key) => {
    stateRetrievers[key] = (state) => {     
      return state.h2Costs[key];
    }
    reducers[key] = (state, action) => {
      state[key] = action.payload;
    }
    
});

export const h2CostsSlice = createSlice({
    name: "h2Costs",
    initialState,
    reducers
});

export const updaters = h2CostsSlice.actions;

export const retrievers = stateRetrievers;

export default h2CostsSlice.reducer;