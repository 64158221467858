const InfrastructureH2DesignData = [
    {
        key: 'h2Demand',
        sections: [
            {
                collection: 'h2InfrastructureDesign',
                type: 'interactive',
                key: 'demandSlider',
                elements: [
                    {
                        type: 'slider',
                        min: 0,
                        max: 1000,
                        unit: 'kg / day',
                        title: 'Hydrogen demand',
                        key: 'h2DemandSlider',
                        property: 'h2Demand',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            }
        ]
    },
]

export default InfrastructureH2DesignData;
