const H2CostsData = [
    {
        key: 'costBreakdown',
        type: 'topbar',
        sections: [
            {
                key: 'costBreakdownSection',
                type: 'costBreakdownRanges',
                collection: 'totalCosts', // Think of collection as being more like tags rather than folders
                elements: [
                    {   
                        type: 'costBreakdownBullet',
                        title: 'Engineering & Design',
                        property: 'h2EngDesignCost', 
                        key: 'engDesignCost'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Permitting & Fees',
                        property: 'h2PermitFeesCost', 
                        key: 'permitFeesCost'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Hardware & Installation',
                        property: 'h2HardwareInstallationCost', // Based on user input
                        key: 'hardwareInstallationCost'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Total', 
                        property: 'totalProjectCost',
                        key: 'totalProjectCost'
                    }
                ]
            }
        ] 
    },
    { // If we wanted to get really fancy we could use these sections to fill out the black box on top
        key: 'costBreakdownEngDesignDetails',
        sections: [
            {
                key: 'engDesignDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'title',
                        title: 'Engineering & Design',
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2EngDesignCost',
                        key: 'engDesignCost'
                    }
                ]
            }, 
            {
                key: 'engDesignDetailsContent',
                type: 'costBreakdownContent',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'summary',
                        title: 'Labor',
                        text: 'Design of your hydrogen infrastructure',
                        key: 'detailsSummary'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #1',
                        text: 'Consult the <a href="/vendor_catalog">Vendor catalog</a> for a list of engineering firms.',
                        key: 'detailsTip1'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #2',
                        text: 'Cost depends on the complexity of your site',
                        key: 'detailsTip2'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2EngDesignCost',
                        key: 'detailsPriceRange'
                    }
                ]
            }
        ]
    },
    { 
        key: 'costBreakdownPermitFeesDetails',
        sections: [
            {
                key: 'permitFeesDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'title',
                        title: 'Permitting & Fees',
                        key: 'permittingTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2PermitFeesCost',
                        key: 'permittingPrice'
                    }
                ]
            }, 
            {
                key: 'permitFeesDetailsContent',
                type: 'costBreakdownContent',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'summary',
                        title: 'Permitting & Fees',
                        text: 'You may need to obtain permits from multiple local authorities',
                        key: 'permittingSummary'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #1',
                        text: 'Permitting fees depend on project complexity.',
                        key: 'permittingTip1'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #2',
                        text: 'Public H2 fueling projects may require additional permits.',
                        key: 'permittingTip2'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2PermitFeesCost',
                        key: 'priceRamgePermit'
                    }
                ]
            }
        ]
    },
    { 
        key: 'costBreakdownHardwareDetails',
        sections: [
            {
                filterHook: 'hardwareDetailsTitle',
                key: 'hardwareDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'title',
                        title: 'Hardware & Installation',
                        key: 'evChargersTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'h2HardwareInstallationCost',
                        key: 'h2PriceRange'
                    }
                ]
            }, 
            {
                filterHook: 'liquidStorage',
                key: 'supplyStorageSection',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'Supply storage tank (liquid)',
                        key: 'supplyStorageTankSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'supplyStorageTankCost',
                        key: 'chargerCostPriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            },
            {
                filterHook: 'gasStorage',
                key: 'fuelingStorageSection',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'Fueling storage (gas)',
                        key: 'fuelingStorageSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'fuelingStorageCost',
                        key: 'fuelingStoragePriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            },
            {
                filterHook: 'liquidPumpSelection',
                key: 'liquidPumpSection',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'Liquid pump',
                        key: 'liquidPumpSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'liquidPumpCost',
                        key: 'liquidPumpPriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            },
            {
                filterHook: 'gasCompressor',
                key: 'gasCompressor',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'Gas compressor',
                        key: 'gasCompressorSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'gasCompressorCost',
                        key: 'gasCompressorPriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            },
            {
                filterHook: 'dispensers',
                key: 'dispensers',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'Dispensers',
                        key: 'supplyStorageTankSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'dispensersCost',
                        key: 'dispensersPriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            },
            {
                filterHook: 'humanMachineInterface',
                key: 'humanMachineInterface',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'Human-machine interface',
                        key: 'hmiSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'humanMachineInterfaceCost',
                        key: 'hmiPriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            },
            {
                filterHook: 'electrolyzer',
                key: 'electrolyzer',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'Electrolyzer',
                        key: 'electrolyzerSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'electrolyzerCost',
                        key: 'electrolyzerPriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            },
            {
                filterHook: 'smrModule',
                key: 'smrModule',
                type: 'costBreakdownContent',
                collection: 'h2InfrastructureDesign',
                elements: [
                    {
                        type: 'summary',
                        title: 'SMRModule',
                        key: 'smrModuleSummary',
                        scaleOverrides: {
                            md: 9
                        }
                    },
                    {
                        type: 'priceRange',
                        property: 'smrModuleCost',
                        key: 'smrCostPriceRange',
                        scaleOverrides: {
                            md: 3
                        }
                    }
                ]
            }
        ]
    },
    {
        key: 'totalCost',
        type: 'bottomBar',
        sections: [
            {
                key: 'bottomLineBox',
                type: 'bottomLineBox',
                collection: 'totalCosts',
                elements: [
                    {
                        key: 'bottomLineTitleExp',
                        type: 'bottomLineTitleExp',
                        title: 'Total',
                        text: 'This estimate is intended to be used for planning purposes only. Actual costs depend on many factors including system design, existing infrastructure, etc.<br/><br/>Please contact contractors for precise estimates and quotes.<br/><br/>Based on CALSTART data for similar projects. We show a range of +/- 50% of the cost calculated above.'
                    },
                    {
                        type: 'priceRange',
                        property: 'totalProjectCost',
                        key: 'totalProjectPriceRange'
                    }
                ]
            }
        ]
    }
]

export default H2CostsData;