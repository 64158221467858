import { Box, Button, Card, CardContent, CardMedia, Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { InsertIntoGridItem } from '../../Components'

const UtilityLookup = (
    {
        fieldGroups,
        renderingFunction,
        reduxState,
        setReduxState,
        lookupZip,
        textfield,
    }        
) => {

    const useStyles = makeStyles({
        root: {
            height: '100%'
        },
        media: { 
            height: 150,
            width: 300
        },
    });

    const classes = useStyles();  

    const updateState = (collection, property, value) => {
        const newState = { ...reduxState };

        newState[collection][property] = value;

        setReduxState(newState);
    }

    const renderElementsInSection = (section, sectionIterator) => {
        const sectionTypeHandlers = {
            zipEntry: (element, elIterator) => {

                const elementTypeHandlers = {
                    textField: () => {
                        return textfield(element, (val) => {
                            updateState(section.collection, element.property, val)
                        }, reduxState[section.collection])
                    },

                    submitButton: () => {
                        return (
                            <Button
                                onClick={lookupZip}
                            >{element.text}</Button>
                        )
                    }
                }

                const newElement = elementTypeHandlers[element.type]();

                return InsertIntoGridItem(newElement, section, `section${sectionIterator}_element${elIterator}`, element.scaleOverrides);
            },
            utilityCards: (element, elIterator) => {
                const utilities = reduxState[section.collection][element.property];   

                const cards = utilities.map((utility) => {
                    const card = (
                        <Card>
                            <Box
                                display="flex"
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={utility.logo_url}
                                    title="Utility logo"
                                />
                                <CardContent>
                                    <Typography>{`The utility for ${utility.zip} is`}</Typography>
                                    <Link
                                        href={utility.ev_page_url}
                                        target="_blank"
                                        rel="noopener"                                        
                                    >{utility.long_name}</Link>
                                </CardContent>
                                <CardContent>
                                    <Button>
                                        <Link
                                            href={utility.ev_page_url}
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            {element.buttonText}
                                        </Link>
                                    </Button>
                                </CardContent>
                            </Box>
                        </Card>
                    )

                    return InsertIntoGridItem(card, section, `section${sectionIterator}_element${elIterator}`, element.scaleOverrides);
                });

                return cards;
            }
        }

        return (
            <Grid
                container
                justify="center"
                direction={section.direction || 'row'}
                spacing={2} 
                key={section.key}
            >
                {
                    section.elements.map((element, i) => {
                        return sectionTypeHandlers[section.type](element, i)
                    })
                }
            </Grid>

        )
    }

    const defaultRenderer = () => {
        return (
            <Box>
                {
                    fieldGroups.map((fieldGroup) => {
                        return(
                             <Box
                                key={fieldGroup.key}
                             >
                                {
                                    fieldGroup.sections.map((section, i) => {
                                        return renderElementsInSection(section, i);
                                    })
                                }
                            </Box>
                        );
                    })
                }
            </Box>
        )
    }

    const doRender = renderingFunction || defaultRenderer;

    return doRender();
}

export default UtilityLookup;