import React, { useState, useEffect, useCallback } from "react";
import { FlowComponentWrapper } from "@zappy-ride/react-flows";
import { Components, ComponentMetadata, utils, data } from "../../cap";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import {
  H2InfrastructureSite,
  H2DepotFueling,
  H2OnRouteFueling,
} from "../../cap";
import PageHeader from "../PageHeader";
import RecommendationResults, {
  recommendationMetaDataSectionTemplate,
} from "../RecommendationResults";
import { FuelCellDesign, EvDesign } from "../Pages";
import guidedPlannerSlice, {
  updaters,
  retrievers,
} from "../../Slices/guidedPlannerSlice";
import PageTitle from "../PageTitle";
import recommendedChargerCalculations from "../../coreCalcs/recommendedChargerCalculations";
import { updaters as H2DesignUpdaters } from "../../Slices/h2DesignSlice";
import { updaters as EvDesignUpdaters } from "../../Slices/evDesignSlice";
import {
  authenticatedUserRetrievers,
  authenticatedUserReducers,
} from "../UserAuthentication/authenticatedUserSlice";
import { userSettingsApi } from "../UserAuthentication/services/userSettingsApi";
import { useCheckLogin } from "../UserAuthentication/hooks/useCheckLogin";

const buildGuidedPlannerQuery = (collection) => {
  // We *may* only need the guided planner slice retriever. Probably will need to bring on the EVDesignSlice though...
  const guidedPlannerKeys = new Set(Object.keys(retrievers));
  const queryData = {};

  Object.keys(collection).forEach((item) => {
    if (guidedPlannerKeys.has(item)) {
      queryData[item] = collection[item];
    }
  });

  const existingSettings = { ...collection.existingSettings };

  const mergedQueryData = Object.assign(existingSettings, queryData);

  let stringified = JSON.stringify(mergedQueryData);
  stringified = stringified.replace(/"/g, '\\"');

  return stringified;
};

const useCollection = () => ({
  stationType: useSelector(retrievers.stationType),
  vehicleType: useSelector(retrievers.vehicleType),
  timeAvailableStart: useSelector(retrievers.timeAvailableStart),
  timeAvailableFinish: useSelector(retrievers.timeAvailableFinish),
  timeAvailableStartOnRoute: useSelector(retrievers.timeAvailableStartOnRoute),
  timeAvailableFinishOnRoute: useSelector(
    retrievers.timeAvailableFinishOnRoute
  ),
  numVehicles: useSelector(retrievers.numVehicles),
  depotMilesPerDayPerVehicle: useSelector(
    retrievers.depotMilesPerDayPerVehicle
  ),
  timeAtOnRouteStation: useSelector(retrievers.timeAtOnRouteStation),
  onRouteVehiclesPerDay: useSelector(retrievers.onRouteVehiclesPerDay),
  additionalMilesPerCharge: useSelector(retrievers.additionalMilesPerCharge),
  siteAddress: useSelector(retrievers.siteAddress),
  zip: useSelector(retrievers.zip),
  stationSize: useSelector(retrievers.stationSize),
  cleanWater: useSelector(retrievers.cleanWater),
  cheapElectricity: useSelector(retrievers.cheapElectricity),
  naturalGas: useSelector(retrievers.naturalGas),
  overheadInterest: useSelector(retrievers.overheadInterest),
  wirelessInterest: useSelector(retrievers.wirelessInterest),
  plugsOverCapacity: useSelector(retrievers.plugsOverCapacity),
  h2Quantity: useSelector(retrievers.h2Quantity),
  hydrogenInfrastructureSite: new H2InfrastructureSite(),
  hydrogenDepotFueling: new H2DepotFueling(),
  hydrogenOnRouteFueling: new H2OnRouteFueling(),
  h2MilesPerKilogram: useSelector(retrievers.h2MilesPerKilogram),
  deliveryMechanism: useSelector(retrievers.deliveryMechanism),
  settingsID: useSelector(authenticatedUserRetrievers.settingsID),
  idManagementSession: useSelector(
    authenticatedUserRetrievers.idManagementSession
  ),
  version: useSelector(authenticatedUserRetrievers.version),
  existingSettings: useSelector(authenticatedUserRetrievers.userSettings),
});

const _GuidedPlannerWrapper = () => {
  let result;
  let recommendedCharger = {};

  const [errorStatus, setErrorStatus] = useState("");

  const siteRecommendationResults = (
    recommendedCharger,
    landSize,
    h2MilesPerKilogram
  ) => {
    let doesChargerExist;
    let options = [];
    doesChargerExist = Object.keys(recommendedCharger).length > 0;
    if (doesChargerExist) {
      options.push("evTrue");
    } else {
      options.push("evFalse");
    }

    if (
      (landSize === "mid" || landSize === "large") &&
      h2MilesPerKilogram !== "N/A"
    ) {
      options.push("h2True");
    } else {
      options.push("h2False");
    }

    result = electricWorks
      .filter(
        (recommendation) =>
          recommendation.type === options[0] ||
          recommendation.type === options[1]
      )
      .map((item) => {
        return recommendationMetaDataSectionTemplate(
          item.section,
          item.title,
          (item.titleClassName = ""),
          item.rationale,
          item.rationaleClassName,
          item.url,
          item.buttonText,
          item.tooltips
        );
      });
    return result;
  };

  const { GuidedPlanner } = Components;
  const { FleetAndSiteDepotData, FleetAndSiteOnRouteData } = ComponentMetadata;
  let electricWorks = [
    {
      type: "evTrue",
      section: "Recommendation_ev_yes",
      title: "Electric may work for you.",
      rationale: ["Vehicle type available as EV", "Duty cycle is compatible"],
      rationaleClassName: "Recommendation_yes_rational",
      buttonText: "Recommended EV Infrastructure",
      url: EvDesign.link,
    },
    {
      type: "evFalse",
      section: "Recommendation_ev_no",
      title: "Electric may not work for you.",
      rationale: [
        "Vehicle type not available as EV",
        "Duty cycle is not compatible",
      ],
      tooltips: [
        null,
        "Daily range cannot be met by charging window for this type of vehicle.",
      ],
      rationaleClassName: "Recommendation_no_rational",
      buttonText: "Proceed To EV Infrastructure",
      url: EvDesign.link,
    },
    {
      type: "h2True",
      section: "Recommendation_h2_yes",
      title: "Hydrogen may work for you.",
      rationale: [
        "Vehicle type available as hydrogen",
        "Enough space for equipment",
      ],
      rationaleClassName: "Recommendation_yes_rational",
      url: FuelCellDesign.link,
      buttonText: "Recommended H2 Infrastructure",
    },
    {
      type: "h2False",
      section: "Recommendation_h2_No",
      title: "Hydrogen may not work for you.",
      rationale: [
        "Vehicle type not available as hydrogen",
        "Not enough space for equipment",
      ],
      rationaleClassName: "Recommendation_no_rational",
      url: FuelCellDesign.link,
      buttonText: "Proceed To H2 Infrastructure",
    },
  ];

  const dispatch = useDispatch();

  const dataOptions = {
    depot: FleetAndSiteDepotData,
    onRoute: FleetAndSiteOnRouteData,
  };

  const collection = useCollection();

  const updateUserSettings = useCallback(
    (stringifiedRedux, version, settingsID) => {
      const query = `
        mutation MyMutation {
            updateSettings(input: {id: "${settingsID}", _version: ${version}, settings: "${stringifiedRedux}"}) {
              id
              settings,
              _version
            }
          }
        `;

      const ep = userSettingsApi.endpoints;

      dispatch(ep.changeUserData.initiate(query))
        .then((response) => {
          const newVersion = response.data.updateSettings._version;
          const newSettings = JSON.parse(response.data.updateSettings.settings);

          dispatch(authenticatedUserReducers.version(newVersion));
          dispatch(authenticatedUserReducers.userSettings(newSettings));
        })
        .catch((error) => {});
    },
    [dispatch]
  );

  const createUserSettings = useCallback(
    async (stringifiedRedux) => {
      const query = `mutation MyMutation {
            createSettings(input: {settings: "${stringifiedRedux}"}) {
                id
                settings
                _version
            }
        }`;

      const ep = userSettingsApi.endpoints;

      dispatch(ep.changeUserData.initiate(query))
        .then((response) => {
          const newVersion = response.data.createSettings._version;
          const newSettingsID = response.data.createSettings.id;
          const newSettings = JSON.parse(response.data.createSettings.settings);

          dispatch(authenticatedUserReducers.version(newVersion));
          dispatch(authenticatedUserReducers.settingsID(newSettingsID));
          dispatch(authenticatedUserReducers.userSettings(newSettings));
        })
        .catch((error) => {});
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      if (!collection.idManagementSession) {
        return;
      }
      const stringifiedRedux = buildGuidedPlannerQuery(collection);
      if (collection.version && collection.settingsID) {
        updateUserSettings(
          stringifiedRedux,
          collection.version,
          collection.settingsID
        );
      } else {
        createUserSettings(stringifiedRedux);
      }
    };
  }, [createUserSettings, updateUserSettings, collection]);

  useCheckLogin();

  const checkMinimalRequirementsForRecommendedCharger = () => {
    if (!collection.vehicleType) {
      return false;
    }

    if (collection.stationType === "depot") {
      return (
        collection.timeAvailableStart !== "" &&
        collection.timeAvailableFinish !== ""
      );
    } else {
      return (
        collection.timeAvailableStartOnRoute !== "" &&
        collection.timeAvailableFinishOnRoute !== ""
      );
    }
  };

  const refreshRecommendationCalculations = () => {
    const { vehicleArchetypes } = data;
    const targetVehicle = utils.sourceDataFilter(vehicleArchetypes, (val) => {
      return val.id === collection.vehicleType;
    })[0];

    if (!targetVehicle) {
      return;
    }

    const mpkg = isNaN(targetVehicle.h2MilesPerKilogram)
      ? targetVehicle.h2MilesPerKilogram
      : parseFloat(targetVehicle.h2MilesPerKilogram);

    dispatch(updaters.h2MilesPerKilogram(mpkg));

    const onRoute = collection.stationType !== "depot";

    if (onRoute) {
      collection.hydrogenOnRouteFueling = new H2OnRouteFueling()
        .withVehicleType(targetVehicle.h2MilesPerKilogram)
        .withVehicleCount(collection.numVehicles)
        .withVehicleMilesPerDay(collection.depotMilesPerDayPerVehicle)
        .withStartTime(
          utils.timeStringToNumber(collection.timeAvailableStartOnRoute)
        )
        .withEndTime(
          utils.timeStringToNumber(collection.timeAvailableFinishOnRoute)
        )
        .withVehicleFlow(collection.onRouteVehiclesPerDay)
        .withAdditionalMilesPerFuelingSession(
          collection.additionalMilesPerCharge
        )
        .calculateOnRoute();

      dispatch(
        updaters.h2DispenserCount(
          collection.hydrogenOnRouteFueling.dispenserDemandCount
        )
      );
      dispatch(
        H2DesignUpdaters.h2Demand(
          collection.hydrogenOnRouteFueling.h2OnRouteDemand
        )
      );
    } else {
      collection.hydrogenDepotFueling = new H2DepotFueling()
        .withVehicleType(targetVehicle.h2MilesPerKilogram)
        .withVehicleCount(collection.numVehicles)
        .withVehicleMilesPerDay(collection.depotMilesPerDayPerVehicle)
        .withStartTime(utils.timeStringToNumber(collection.timeAvailableStart))
        .withEndTime(utils.timeStringToNumber(collection.timeAvailableFinish))
        .calculate();

      dispatch(
        updaters.h2DispenserCount(
          collection.hydrogenDepotFueling.dispenserDemandCount
        )
      );
      dispatch(
        H2DesignUpdaters.h2Demand(
          collection.hydrogenDepotFueling.dailyH2FleetDemand
        )
      );
    }

    recommendedCharger = checkMinimalRequirementsForRecommendedCharger()
      ? recommendedChargerCalculations(collection)
      : {};

    dispatch(EvDesignUpdaters.chargerID(recommendedCharger.chargerID));
    dispatch(EvDesignUpdaters.chargerQuantity(recommendedCharger.chargerCount));
    dispatch(
      EvDesignUpdaters.totalKWCapacity(
        recommendedCharger.chargerID * recommendedCharger.chargerCount
      )
    );

    if (Object.keys(recommendedCharger).length > 0) {
      dispatch(EvDesignUpdaters.isRecommendation(true));
    } else {
      dispatch(EvDesignUpdaters.isRecommendation(false));
    }
  };
  try {
    refreshRecommendationCalculations();
  } catch (error) {
    if (errorStatus !== error.message) {
      setErrorStatus(error.message);
    }
  }

  collection.hydrogenInfrastructureSite = new H2InfrastructureSite()
    .withH2Quantity(collection.hydrogenDepotFueling.dailyH2FleetDemand)
    .withLandSize(collection.stationSize)
    .hasWater(collection.cleanWater)
    .hasNaturalGas(collection.naturalGas)
    .hasElectricity(collection.cheapElectricity);

  siteRecommendationResults(
    recommendedCharger,
    collection.hydrogenInfrastructureSite.inputs.landSize,
    collection.h2MilesPerKilogram
  );

  const createWarningText = (warning) => {
    return <Typography className="warning">{warning}</Typography>;
  };

  return (
    <>
      <PageTitle page="MyFleetAndSite" />
      <div className="guided-planner">
        <PageHeader
          title="My Commercial Fleet &amp; Site"
          tagline="Tell us about your commercial fleet and location so we can recommend infrastructure to match."
        />
        {createWarningText(errorStatus)}
        <GuidedPlanner
          onChange={(prop, newVal) => {
            setErrorStatus("");
            dispatch(updaters[prop](newVal));
          }}
          componentMetadata={dataOptions[useSelector(retrievers.stationType)]}
          collection={collection}
        />
      </div>
      {React.createElement(RecommendationResults, {
        props: { componentMetadata: result },
      })}
    </>
  );
};

const GuidedPlannerWrapper = FlowComponentWrapper(
  "GuidedPlanner",
  guidedPlannerSlice
)(_GuidedPlannerWrapper);

export default GuidedPlannerWrapper;
