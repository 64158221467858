import { 
    CHARGER_TYPES_POWER_COST,  
    COST_PER_FT_TRENCHING,
    COST_PER_KW_CAPACITY,
    RANGE_FROM_BASE,
    MINIMUM_INSTALL_COST
} from "../constants/constants"
import sourceDataFilter from "../utils/sourceDataFilter";

const installationCost = (trenching, kwCapacity) => {
    const trenchCost = trenching * COST_PER_FT_TRENCHING;
    const kwCost = kwCapacity * COST_PER_KW_CAPACITY;
    const baseCost = trenchCost + kwCost;
    const variance = baseCost * RANGE_FROM_BASE;

    const minCost = Math.max(MINIMUM_INSTALL_COST, baseCost - variance);
    const maxCost = baseCost + variance;

    return {
        min: Math.floor(minCost),
        max: Math.floor(maxCost)
    }
}

const hardwareCost = (charger) => {
    const chargerData = sourceDataFilter(CHARGER_TYPES_POWER_COST, (chargerOption) => {
        return chargerOption.value === charger;
    })[0]

    return {
        min: chargerData.min,
        max: chargerData.max
    }
}

export { installationCost, hardwareCost } 