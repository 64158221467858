import React from 'react';
import { useSelector } from "react-redux";

import { retrievers as H2DesignRetrievers } from "../Slices/h2DesignSlice"
import AlertDialog from './AlertDialog'
import H2WorkplanTubeTrailerWrapper from './Wrappers/H2WorkplanTubeTrailerWrapper';
import H2WorkplanGasDeliveryWrapper from './Wrappers/H2WorkplanGasDeliveryWrapper';
import H2WorkplanLiquidDeliveryWrapper from './Wrappers/H2WorkplanLiquidDeliveryWrapper';
import H2WorkplanElectrolysisWrapper from './Wrappers/H2WorkplanElectrolysisWrapper';
import H2WorkplanSMRWrapper from './Wrappers/H2WorkplanSMRWrapper';
import { FuelCellDesign } from './Pages';
import { useCheckLogin } from './UserAuthentication/hooks/useCheckLogin';

function SwitchComponents({ active, children }) {
    // Switch all children and return the "active" one
    return children.filter(child => child.props.name === active)
}

export const H2WorkPlan = () => {

    let h2SolutionType = useSelector(H2DesignRetrievers.selectedDeliveryMethodValue);
    useCheckLogin();

    try {
        if (h2SolutionType === null || h2SolutionType === "") throw new Error('A hydrogen solution was not defined')
        return (
            <SwitchComponents active={h2SolutionType} >
                <H2WorkplanTubeTrailerWrapper name="tubeTrailer" />
                <H2WorkplanGasDeliveryWrapper name="gasDelivery" />
                <H2WorkplanLiquidDeliveryWrapper name="liquidDelivery" />
                <H2WorkplanElectrolysisWrapper name="electrolysisDelivery" />
                <H2WorkplanSMRWrapper name="smr" />
            </SwitchComponents>
        )
    } catch (e) {
        return (
            <>
                <AlertDialog title text buttonText page={FuelCellDesign.link} />
            </>
        )    
    }
}
