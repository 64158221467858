import React from 'react';
import { Components, ComponentMetadata } from '../../cap';
import {
  genericCardImage,
  dcfcWired,
  electrolysis,
  level2Wired,
  overhead,
  permanentStation,
  steamMethaneReforming,
  tubeTrailer,
  gasTubeTrailer,
  liquidTubeTrailer,
  wireless,
  lowerCostOwnership,
  moreVehicleOptions,
  techMaturity,
  demandCharges,
  subSurfaceUpgrades,
  utilityUpgrades,
  thriveAnyTerrain,
  fasterRefueling,
  longerRange,
  upfrontCosts,
  scarcity,
  viability,
  environmentalImpact,
  highlyRegulated
} from '../../assets/images';

import PageHeader from '../PageHeader';
import PageTitle from '../PageTitle';
 


const FuelingAlternativesWrapper = () => {

  const { VehicleBasicsInfo } = ComponentMetadata;
  const { FuelingAlternatives } = Components;

  const cardImages = {
    genericCardImage,
    dcfcWired,
    electrolysis,
    level2Wired,
    overhead,
    permanentStation,
    steamMethaneReforming,
    tubeTrailer,
    gasTubeTrailer,
    liquidTubeTrailer,
    wireless,
    lowerCostOwnership,
    moreVehicleOptions,
    techMaturity,
    demandCharges,
    subSurfaceUpgrades,
    utilityUpgrades,
    thriveAnyTerrain,
    fasterRefueling,
    longerRange,
    upfrontCosts,
    scarcity,
    viability,
    environmentalImpact,
    highlyRegulated
  };

  const fuelingAlternativesData = [
    VehicleBasicsInfo.ElectricVehicle,
    VehicleBasicsInfo.H2Vehicle
  ]


  const getFuelingAlternatives = () => {
    

    const alternativeTables = fuelingAlternativesData.map((alt) => {
      return (
        <FuelingAlternatives
          fuelingContent={alt()}
          cardImages={cardImages}
          key={alt.title}
        />
      )
    })

    return (
      <>
      <PageTitle page="ElectricOrFuelCell" />
        <PageHeader title="Electric or Fuel Cell?" tagline="Electric and hydrogen fuel cell vehicles require very different infrastructure. Which is right for you?" />
        {alternativeTables}
      </>
    )
  }
  return getFuelingAlternatives();
}

export default FuelingAlternativesWrapper;