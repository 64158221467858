import React, { useEffect } from 'react';
import { FlowComponentWrapper } from '@zappy-ride/react-flows';
import { useDispatch, useSelector } from 'react-redux';
import WifiIcon from '@material-ui/icons/Wifi';
import { useGetChargersQuery } from '../../services/unifiedAPIQuery';
import { Box, Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import { chillers, compressors, dispensers, electrolyzers, storage } from '../../data/h2Equipment';
import equipmentCatalogSlice, { retrievers, updaters } from '../../Slices/equipmentCatalogSlice';
import { ChargerCard, H2ChillerCard, H2CompressorCard, H2DispenserCard, H2ElectrolyzerCard, H2StorageCard } from '../CatalogCards';
import PageHeader from '../PageHeader';
import { CatalogDropDownFilter } from '../InputBuilders/inputBuilders';
import PageTitle, { LoadingPageTitle } from '../PageTitle';
import ElectricCatalogFilters from '../Catalogs/ElectricCatalogFilters';
import CatalogFilterSetBuilder from '../Catalogs/CatalogFilterSetBuilder';



function compareOrderedArraysOfInts(array1, array2) {

    //because they are ordered
    const outPutArray = []
    let i = 0, j = 0;
    while (array1.length > i && array2.length > j) {
        if (array1[i] === array2[j]) {
            outPutArray.push(array1[i]);
            i++;
            j++;
        } else if (array1[i] < array2[j]) {
            i++;
        }
        else {
            j++;
        }
    }
    return outPutArray;
}

/**************************************************
 *  
 *      CreateIndexValueKeys    
 *  
 * *************************************************/

const createIndexValueKeys = (
    catalog,
    keysOfInterest
) => {
    const valueKeys = new Map();

    catalog.forEach((item, index) => {
        keysOfInterest.forEach((key) => {
            //Check to see if  the value of key exists in valueKeys map 
            if (!valueKeys.has(key)) {
                valueKeys.set(key, new Map());
            }

            let vk = valueKeys.get(key)

            //Check to see if an array exists for the index
            if (!vk.has(item[key])) {
                vk.set(item[key], []);
            }

            vk.get(item[key]).push(index);
        })
    })

    return valueKeys;
}

/**************************************************
 *  
 *      SwitchComponents
 *  
 * *************************************************/


function SwitchComponents({ active, children }) {
    // Switch all children and return the "active" one
    return children.filter(child => child.props.name === active)
}

const createCatalogDropDownFilter = () => (new CatalogDropDownFilter());
const createCatalogFilterSetBuilder = () => (new CatalogFilterSetBuilder());


//catalog needs to take in array of possible items, index of the items to show and catalog cards

const EquipmentCatalogWrapper = () => {

    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [accordionExpanded, setAccordionExpanded] = React.useState(false);
    const evChargerFormFactor = useSelector(retrievers.evChargerFormFactor);
    const evChargerNumPlugs = useSelector(retrievers.evChargerNumPlugs);
    const evChargerPowerLevelAC = useSelector(retrievers.evChargerPowerLevelAC);
    const evChargerPowerLevelDC = useSelector(retrievers.evChargerPowerLevelDC);
    const evChargerFilterSmartCharging = useSelector(retrievers.evChargerFilterSmartCharging);
    const evChargerFilterNetworked = useSelector(retrievers.evChargerFilterSmartCharging);
    const evChargerFilterVGI = useSelector(retrievers.evChargerFilterVGI);
    const plugTypeFilter = useSelector(retrievers.plugTypeFilter);

    const h2EquipCategory = useSelector(retrievers.h2EquipCategory);
    const h270MPa = useSelector(retrievers.h270MPa);
    const h235MPa = useSelector(retrievers.h235MPa);
    const h2IntPOS = useSelector(retrievers.h2IntPOS);
    const h2StoragePressure = useSelector(retrievers.h2StoragePressure);
    const h2StorageType = useSelector(retrievers.h2StorageType);
    const h2StorageVolume = useSelector(retrievers.h2StorageVolume);
    const h2ElectrolyzerProduction = useSelector(retrievers.h2ElectrolyzerProduction);
    const h2ElectrolyzerFlowRate = useSelector(retrievers.h2ElectrolyzerFlowRate);
    const chillerCoolingCapacityRange = useSelector(retrievers.chillerCoolingCapacityRange);
    const compressorFlowRate = useSelector(retrievers.compressorFlowRate);
    const compressorPressure = useSelector(retrievers.compressorPressure);
    const compressorPower = useSelector(retrievers.compressorPower);

    useEffect(() => {
        if(evChargerFormFactor === 'AC') {
            dispatch(updaters.evChargerPowerLevelDC(''))
        }
        if(evChargerFormFactor === 'DC') {
            dispatch(updaters.evChargerPowerLevelAC(''))
        }
    },
        [evChargerFormFactor, dispatch]
    )

    // Load Api Data
    const { data, isLoading } = useGetChargersQuery({ 'postcode': '94133' });

    //Get source data
    const filterFieldName = ['current', 'num_of_ports', 'plug_types', 'vgi_capable', 'wifi', 'kw_ac', 'kw_dc'];
    const filterFieldNameToValueMapping = [evChargerFormFactor, evChargerNumPlugs, plugTypeFilter, evChargerFilterVGI, evChargerFilterNetworked, evChargerPowerLevelAC, evChargerPowerLevelDC]

    const hydrogrenChillerFieldNames = ['cooling_capacity_kW'];
    const hydrogrenChillerNameToValueMapping = [chillerCoolingCapacityRange];
    const hydrogenCompressorFieldNames = ['flow_rate_m3_hour', 'pressure_PSI', 'power_KW'];
    const hydrogenCompressorNameToValueMapping = [compressorFlowRate, compressorPressure, compressorPower];
    const hydrogenDispenserFieldNames = ['_35MPa_dispenser', '_70MPa_dispenser', 'integrated_point_of_sale'];
    const hydrogenDispenserNameToValueMapping = [h235MPa, h270MPa, h2IntPOS];
    const hydrogenElectrolyzerFieldNames = ['net_production_rate', 'flow_rate'];
    const hydrogenElectrolyzerNameToValueMapping = [h2ElectrolyzerProduction, h2ElectrolyzerFlowRate];
    const hydrogenStorageFieldNames = ['type', 'pressure', 'capacity'];
    const hydrogenStorageNameToValueMapping = [h2StorageType, h2StoragePressure, h2StorageVolume];

    //build index of intererested fields after data loads 
    let indexElectricFilterValueKeys, indexHydrogenChillerFilterValueKeys, indexHydrogenCompressorFilterValueKeys, indexHydrogenDispenserFilterValueKeys, indexHydrogenElectrolyzersFilterValueKeys, indexHydrogenStorageFilterValueKeys;
    if (!isLoading && data) {
        let equipementCatalogData = data.chargers;

        indexElectricFilterValueKeys = createIndexValueKeys(equipementCatalogData, filterFieldName)
        indexHydrogenChillerFilterValueKeys = createIndexValueKeys(chillers, hydrogrenChillerFieldNames);
        indexHydrogenCompressorFilterValueKeys = createIndexValueKeys(compressors, hydrogenCompressorFieldNames);
        indexHydrogenDispenserFilterValueKeys = createIndexValueKeys(dispensers, hydrogenDispenserFieldNames);
        indexHydrogenElectrolyzersFilterValueKeys = createIndexValueKeys(electrolyzers, hydrogenElectrolyzerFieldNames);
        indexHydrogenStorageFilterValueKeys = createIndexValueKeys(storage, hydrogenStorageFieldNames);

        const hydrogenFieldGroups = {
            Chillers: {
                index: indexHydrogenChillerFilterValueKeys,
                fieldNames: hydrogrenChillerFieldNames,
                mapping: hydrogrenChillerNameToValueMapping,
                catalog: chillers,
                cardRenderer: (catalogItem) => {
                    return <H2ChillerCard catalogItem={catalogItem} />
                }
            },
            Compressors: {
                index: indexHydrogenCompressorFilterValueKeys,
                fieldNames: hydrogenCompressorFieldNames,
                mapping: hydrogenCompressorNameToValueMapping,
                catalog: compressors,
                cardRenderer: (catalogItem) => {
                    return <H2CompressorCard catalogItem={catalogItem} />
                }
            },
            Dispensers: {
                index: indexHydrogenDispenserFilterValueKeys,
                fieldNames: hydrogenDispenserFieldNames,
                mapping: hydrogenDispenserNameToValueMapping,
                catalog: dispensers,
                cardRenderer: (catalogItem) => {
                    return <H2DispenserCard catalogItem={catalogItem} />
                }
            },
            Electrolyzers: {
                index: indexHydrogenElectrolyzersFilterValueKeys,
                fieldNames: hydrogenElectrolyzerFieldNames,
                mapping: hydrogenElectrolyzerNameToValueMapping,
                catalog: electrolyzers,
                cardRenderer: (catalogItem) => {
                    return <H2ElectrolyzerCard catalogItem={catalogItem} />
                }
            },
            Storage: {
                index: indexHydrogenStorageFilterValueKeys,
                fieldNames: hydrogenStorageFieldNames,
                mapping: hydrogenStorageNameToValueMapping,
                catalog: storage,
                cardRenderer: (catalogItem) => {
                    return <H2StorageCard catalogItem={catalogItem} />
                }
            }
        }

        const h2EquipCategoryFilter = createCatalogDropDownFilter()
            .withClassName()
            .withValues(Object.keys(hydrogenFieldGroups))
            .withInputLabel('Equipment Type')
            .withInputProps_name()
            .withInputProps_id()
            .withValue(h2EquipCategory)
            .withHandleChangeFunction((event) => { dispatch(updaters.h2EquipCategory(event.target.value)) })
            .hasBlankOption(false)
            .build();

        const getValuesInSliderRange = (range, key, index) => {
            const matchingValuesSet = new Set();

            const mapOfElements = index.get(key);

            const elementValues = Array.from(mapOfElements.keys());

            elementValues.forEach((elementIndex) => {
                if (elementIndex >= range[0] && elementIndex <= range[1]) {
                    const newMatchingElements = mapOfElements.get(elementIndex);
                    newMatchingElements.forEach((matchEl) => { matchingValuesSet.add(matchEl) })
                }
            })

            return matchingValuesSet;
        }

        const coolerSliderInputs = [
            {
                name: 'cooling_capacity_kW',
                value: chillerCoolingCapacityRange
            }
        ]

        const compressorSliderInputs = [
            {
                name: 'flow_rate_m3_hour',
                value: compressorFlowRate
            },
            {
                name: 'pressure_PSI',
                value: compressorPressure,
            },
            {
                name: 'power_KW',
                value: compressorPower,
            }
        ]

        const electrolyzerSliderInputs = [
            {
                name: 'net_production_rate',
                value: h2ElectrolyzerProduction
            },
            {
                name: 'flow_rate',
                value: h2ElectrolyzerFlowRate
            }
        ]

        const storageSliderInputs = [
            {
                name: 'pressure',
                value: h2StoragePressure
            },
            {
                name: 'capacity',
                value: h2StorageVolume
            }
        ];

        const h2Index = hydrogenFieldGroups[h2EquipCategory].index;
        const h2FieldNames = hydrogenFieldGroups[h2EquipCategory].fieldNames;
        const h2Mapping = hydrogenFieldGroups[h2EquipCategory].mapping;
        const h2Catalog = hydrogenFieldGroups[h2EquipCategory].catalog;

        const sliderInputsMap = {
            Chillers: coolerSliderInputs,
            Compressors: compressorSliderInputs,
            Electrolyzers: electrolyzerSliderInputs,
            Storage: storageSliderInputs
        }

        let sliderFilteredResults;

        if (sliderInputsMap[h2EquipCategory]) {
            sliderFilteredResults = sliderInputsMap[h2EquipCategory].map((slider) => {
                const returnedArr = Array.from(getValuesInSliderRange(slider.value, slider.name, h2Index)).sort((a, b) => {
                    return a - b;
                });

                return returnedArr;
            }).reduce(compareOrderedArraysOfInts);
        }

        const evEquipFiltered = filterFieldName.map(
            (key, index) => {
                let val = indexElectricFilterValueKeys.get(key).get(
                    typeof filterFieldNameToValueMapping[index] === 'boolean' ? filterFieldNameToValueMapping[index] :
                        isNaN(filterFieldNameToValueMapping[index]) ? filterFieldNameToValueMapping[index] :
                            (Number.isInteger(filterFieldNameToValueMapping[index]) ? parseInt(filterFieldNameToValueMapping[index]) :
                                parseFloat(filterFieldNameToValueMapping[index])))
                if (typeof val === 'undefined') {
                    return [...Array(equipementCatalogData.length).keys()]
                } else return val
            }).reduce(compareOrderedArraysOfInts)

        let h2EquipFiltered = h2FieldNames.map(
            (key, index) => {
                let val = h2Index.get(key).get(
                    typeof h2Mapping[index] === 'boolean' ? h2Mapping[index] :
                        isNaN(h2Mapping[index]) ? h2Mapping[index] :
                            (Number.isInteger(h2Mapping[index]) ? parseInt(h2Mapping[index]) :
                                parseFloat(h2Mapping[index])))
                if (typeof val === 'undefined') {
                    return [...Array(h2Catalog.length).keys()]
                } else return val

            }).reduce(compareOrderedArraysOfInts)

        if (sliderFilteredResults) {
            h2EquipFiltered = compareOrderedArraysOfInts(h2EquipFiltered, sliderFilteredResults);
        }

        const evEquipCatalog = () => {
            return (
                <Grid container spacing={3}>
                    {
                        evEquipFiltered.map((equipIndex) => {
                            const catalogItem = equipementCatalogData[equipIndex];

                            return (
                                <Grid item xs={12} md={4}>
                                    <ChargerCard
                                        catalogItem={catalogItem}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            )
        }

        const h2EquipCatalog = () => {
            return (
                <Grid container spacing={3}>
                    {
                        h2EquipFiltered.map((equipIndex) => {
                            const catalogItem = hydrogenFieldGroups[h2EquipCategory].catalog[equipIndex];

                            return (
                                <Grid item xs={12} md={4}>
                                    {hydrogenFieldGroups[h2EquipCategory].cardRenderer(catalogItem)}
                                </Grid>
                            )
                        })
                    }
                </Grid>
            )
        }

        const NamedDiv = ({ name, children }) => {
            return (
                <div
                    name={name}
                >
                    {children}
                </div>
            )
        }

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            {children}
                        </Box>
                    )}
                </div>
            );
        }

        function a11yProps(index) {
            return {
                id: `full-width-tab-${index}`,
                'aria-controls': `full-width-tabpanel-${index}`,
            };
        }
        return (
            <div>
                <PageTitle page="EquipmentCatalog" />
                <PageHeader title="Equipment Catalog" tagline="Electric vehicles and hydrogen vehicles require completely different infrastructure. Toggle between them to find the right equipment." />
                <AppBar position="static" color="default" className="tabs-bar">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        className="full-width-tabs"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Electric" {...a11yProps(0)} />
                        <Tab label="Hydrogen" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <>
                    <TabPanel value={value} index={0} dir='ltr'>
                        <ElectricCatalogFilters
                            indexElectricFilterValueKeys={indexElectricFilterValueKeys}
                            evChargerFormFactor={evChargerFormFactor}
                            evChargerPowerLevelDC={evChargerPowerLevelDC}
                            evChargerPowerLevelAC={evChargerPowerLevelAC}
                            evChargerNumPlugs={evChargerNumPlugs}
                            evChargerFilterSmartCharging={evChargerFilterSmartCharging}
                            evChargerFilterVGI={evChargerFilterVGI}
                            evChargerFilterNetworked={evChargerFilterNetworked}
                            accordionExpanded={accordionExpanded}
                            setAccordionExpanded={setAccordionExpanded}
                            updaters={updaters}
                        />
                        <div className = "electric-catalog-legend">
                            <Grid container>
                                <Grid item xs={3} className="legend-item">
                                    <div><WifiIcon /></div><div className="legend-item-text">&nbsp;-&nbsp;Networked</div>
                                </Grid>
                            </Grid>
                        </div>
                        {evEquipCatalog()}
                    </TabPanel>
                    <TabPanel value={value} index={1} dir='ltr'>
                        <SwitchComponents
                            active={h2EquipCategory}
                        >
                            <NamedDiv name="Chillers">
                                {
                                    createCatalogFilterSetBuilder()
                                        .withFilters([
                                            {
                                                type: 'built',
                                                component: h2EquipCategoryFilter
                                            },
                                            {
                                                type: 'slider',
                                                indexValueKeys: indexHydrogenChillerFilterValueKeys,
                                                cardProperty: 'cooling_capacity_kW',
                                                updater: (event, value) => {
                                                    dispatch(updaters.chillerCoolingCapacityRange(value))
                                                },
                                                value: chillerCoolingCapacityRange,
                                                label: 'Cooling capacity',
                                                units: 'kW'
                                            }
                                        ])
                                        .build()
                                }    
                            </NamedDiv>
                            <NamedDiv name="Compressors">
                                {
                                    createCatalogFilterSetBuilder()
                                        .withFilters([
                                            {
                                                type: 'built',
                                                component: h2EquipCategoryFilter
                                            },
                                            {
                                                type: 'slider',
                                                indexValueKeys: indexHydrogenCompressorFilterValueKeys,
                                                cardProperty: 'flow_rate_m3_hour',
                                                updater: (event, value) => {
                                                    dispatch(updaters.compressorFlowRate(value))
                                                },
                                                value: compressorFlowRate,
                                                label: 'Flow Rate',
                                                units: ['m', <sup>3</sup>, '/hr']
                                            },
                                            {
                                                type: 'slider',
                                                indexValueKeys: indexHydrogenCompressorFilterValueKeys,
                                                cardProperty: 'pressure_PSI',
                                                updater: (event, value) => {
                                                    dispatch(updaters.compressorPressure(value))
                                                },
                                                value: compressorPressure,
                                                label: 'Pressure',
                                                units: 'psi'
                                            },
                                            {
                                                type: 'slider',
                                                indexValueKeys: indexHydrogenCompressorFilterValueKeys,
                                                cardProperty: 'power_KW',
                                                updater: (event, value) => {
                                                    dispatch(updaters.compressorPower(value))
                                                },
                                                value: compressorPower,
                                                label: 'Power',
                                                units: 'kW'
                                            }  
                                        ])
                                        .build()
                                }
                            </NamedDiv>
                            <NamedDiv name="Dispensers">
                            { 
                                createCatalogFilterSetBuilder()
                                    .withFilters([
                                        {
                                            type: 'built',
                                            component: h2EquipCategoryFilter
                                        },
                                        {
                                            type: 'select',
                                            indexValueKeys: indexHydrogenDispenserFilterValueKeys,
                                            cardProperty: '_70MPa_dispenser',
                                            updater: (event) => {
                                                dispatch(updaters.h270MPa(event.target.value))
                                            },
                                            placeholder: "All",
                                            value: h270MPa,
                                            label: "70 MPa dispensing",
                                        },
                                        {
                                            type: 'select',
                                            indexValueKeys: indexHydrogenDispenserFilterValueKeys,
                                            cardProperty: '_35MPa_dispenser',
                                            updater: (event, value) => {
                                                dispatch(updaters.h235MPa(event.target.value))
                                            },
                                            placeholder: "All",
                                            value: h235MPa,
                                            label: "35 MPA dispensing",
                                        },
                                        {
                                            type: 'select',
                                            indexValueKeys: indexHydrogenDispenserFilterValueKeys,
                                            cardProperty: 'integrated_point_of_sale',
                                            updater: (event, value) => {
                                                dispatch(updaters.h2IntPOS(event.target.value))
                                            },
                                            placeholder: "All",
                                            value: h2IntPOS,
                                            label: "Integrated point of sale",
                                        }
                                    ])
                                    .build()
                                }
                            </NamedDiv>
                            <NamedDiv name="Electrolyzers">
                            {
                                createCatalogFilterSetBuilder()
                                    .withFilters([
                                        {
                                            type: 'built',
                                            component: h2EquipCategoryFilter
                                        },
                                        {
                                            type: 'slider',
                                            indexValueKeys: indexHydrogenElectrolyzersFilterValueKeys,
                                            cardProperty: 'net_production_rate',
                                            updater: (event, value) => {
                                                dispatch(updaters.h2ElectrolyzerProduction(value))
                                            },
                                            value: h2ElectrolyzerProduction,
                                            label: 'Production Rate',
                                            units: ['m', <sup>3</sup>, '/hr']
                                        }, 
                                    ])
                                    .build()
                                }
                            </NamedDiv>
                            <NamedDiv name="Storage">
                            {
                                createCatalogFilterSetBuilder()
                                    .withFilters([
                                        {
                                            type: 'built',
                                            component: h2EquipCategoryFilter
                                        },
                                        {
                                            type: 'select',
                                            indexValueKeys: indexHydrogenStorageFilterValueKeys,
                                            cardProperty: 'type',
                                            updater: (event) => {
                                                dispatch(updaters.h2StorageType(event.target.value))
                                            },
                                            placeholder: "All",
                                            value: h2StorageType,
                                            label: "Storage type",
                                        },
                                        {
                                            type: 'slider',
                                            indexValueKeys: indexHydrogenStorageFilterValueKeys,
                                            cardProperty: 'capacity',
                                            updater: (event, value) => {
                                                dispatch(updaters.h2StorageVolume(value))
                                            },
                                            value: h2StorageVolume,
                                            label: 'Volume',
                                            units: ['m',<sup>3</sup>]
                                        },
                                        {
                                            type: 'slider',
                                            indexValueKeys: indexHydrogenStorageFilterValueKeys,
                                            cardProperty: 'pressure',
                                            updater: (event, value) => {
                                                dispatch(updaters.h2StoragePressure(value))
                                            },
                                            value: h2StoragePressure,
                                            label: 'Pressure',
                                            units: 'MPa'
                                        }  
                                    ])
                                    .build()
                                }
                            </NamedDiv>
                        </SwitchComponents>
                        {h2EquipCatalog()}
                    </TabPanel>
                </>
            </div>
        );


    }
    else return (
        <>
            <LoadingPageTitle />
            <div>Loading...</div>
        </>)
}

const WrappedEquipmentCatalog = FlowComponentWrapper('EquipmentCatalog', equipmentCatalogSlice)(EquipmentCatalogWrapper);

export default WrappedEquipmentCatalog;