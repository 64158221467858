import React from 'react';
import { costEstimatesDisclaimer } from '../data';

const EVCostsData = [
    {
        key: 'costBreakdownMobile',
        type: 'topbar',
        mobileOnly: true,
        sections: [
            {
                key: 'costBreakdownSection',
                type: 'costBreakdownRanges',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'mobileTotalCost',
                        property: 'totalProjectCost',
                        useM: true,
                        disclaimer: costEstimatesDisclaimer
                    }
                ]
            }
        ]
    },
    {
        key: 'costBreakdown',
        type: 'topbar',
        desktopOnly: true,
        sections: [
            {
                key: 'costBreakdownSection',
                type: 'costBreakdownRanges',
                collection: 'totalCosts', 
                elements: [
                    {   
                        type: 'costBreakdownBullet',
                        title: 'Engineering & Design',
                        property: 'engDesignCost', // Always $15k - $45k
                        key: 'engDesignCost',
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Permitting & Fees',
                        property: 'permitFeesCost', // Always $5k - $45k
                        key: 'permitFeesCost',
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Chargers',
                        property: 'hardwareCost', // Based on user input
                        key: 'hardwareCost',
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Installation',
                        property: 'installationCost', // Based on user input
                        key: 'installationCost',
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Total', 
                        property: 'totalProjectCost',
                        key: 'totalProjectCost',
                        useM: true,
                    }
                ]
            }
        ] 
    },
    { 
        key: 'costBreakdownEngDesignDetails',
        sections: [
            {
                key: 'engDesignDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'title',
                        title: 'Engineering & Design',
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'engDesignCost',
                        key: 'engDesignCost'
                    }
                ]
            }, 
            {
                key: 'engDesignDetailsContent',
                type: 'costBreakdownContent',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'summary',
                        title: 'Labor',
                        text: 'Electrical & civil design of your charging infrastructure.',
                        property: 'engDesignCost',
                        key: 'detailsSummary',
                        className: 'ev-costs-summary'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #1',
                        text: ['Consult the ', <a href="vendor_catalog">Vendor catalog</a>, ' for a list of engineering firms.'],
                        key: 'detailsTip1',
                        className: "left-tip"
                    },
                    {
                        type: 'tip',
                        title: 'Tip #2',
                        text: 'Cost depends on the complexity of your site.',
                        key: 'detailsTip2',
                        className: "right-tip"
                    },
                    {
                        type: 'priceRange',
                        property: 'engDesignCost',
                        key: 'detailsPriceRange',
                        desktopOnly: true
                    }
                ]
            }
        ]
    },
    { 
        key: 'costBreakdownPermitFeesDetails',
        sections: [
            {
                key: 'permitFeesDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'title',
                        title: 'Permitting & Fees',
                        key: 'permittingTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'permitFeesCost',
                        key: 'permittingPrice'
                    }
                ]
            }, 
            {
                key: 'permitFeesDetailsContent',
                type: 'costBreakdownContent',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'summary',
                        title: 'Permitting & Fees',
                        text: 'You may need to obtain permits from multiple local authorities.',
                        key: 'permittingSummary',
                        property: 'permitFeesCost'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #1',
                        text: 'Permitting fees depend on project complexity.',
                        key: 'permittingTip1',
                        className: 'left-tip'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #2',
                        text: 'Public charging projects may require additional permits.',
                        key: 'permittingTip2',
                        className: 'right-tip'
                    },
                    {
                        type: 'priceRange',
                        property: 'permitFeesCost',
                        key: 'priceRamgePermit',
                        desktopOnly: true
                    }
                ]
            }
        ]
    },
    { 
        key: 'costBreakdownHardwareDetails',
        sections: [
            {
                key: 'hardwareDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'evInfrastructureDesign',
                elements: [
                    {
                        type: 'title',
                        title: 'EV Chargers',
                        key: 'evChargersTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'hardwareCost',
                        key: 'evPriceRange'
                    }
                ]
            }, 
            {
                key: 'hardwareFeesDetailsContent',
                type: 'costBreakdownContent',
                collection: 'evInfrastructureDesign',
                elements: [
                    {
                        type: 'hardwareRecap', 
                        title: 'EVSE Costs',
                        key: 'hardwareRecap',
                        properties: {
                            type: 'chargerType',
                            quantity: 'chargerQuantity',
                            cost: 'chargerCostPerUnit',
                            totalChargerCost: 'chargerCost'
                        }
                    },
                    {
                        type: 'tip',
                        title: 'Tip #1',
                        text: 'EVSE stands for "Electric Vehicle Supply Equipment".',
                        key: 'chargerTip1',
                        className: 'left-tip'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #2',
                        text: 'Smaller capacity EVSEs are less expensive.',
                        key: 'chargerTip2',
                        className: 'right-tip'
                    },
                    {
                        type: 'priceRange',
                        property: 'chargerCost',
                        key: 'chargerCostPriceRange',
                        desktopOnly: true
                    }
                ]
            }
        ]
    },
    { 
        key: 'installationDetails',
        sections: [
            {
                key: 'installationDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'title',
                        title: 'Installation',
                        key: 'installTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'installationCost',
                        key: 'installPricerange'
                    }
                ]
            }, 
            {
                key: 'installFeesDetailsContent',
                type: 'costBreakdownContent',
                collection: 'totalCosts',
                elements: [
                    {
                        type: 'summary',
                        title: 'Labor & Ancillary Hardware',
                        key: 'installationSummary',
                        property: 'installationCost',
                        text: [
                            'Includes conduit laying, trenching, civil work, electrical installation.',
                             <br/>,
                             <br/>, 
                            'Please consult the ', 
                            <a href="vendor_catalog">vendor catalog</a>, 
                            ' for installation contractors.']
                    },
                    {
                        type: 'tip',
                        title: 'Tip #1',
                        key: 'installationTip1',
                        text: 'Electrical complexity and footprint drive installation costs.',
                        className: 'left-tip'
                    },
                    {
                        type: 'tip',
                        title: 'Tip #2',
                        key: 'installationTip2',
                        text: 'Trenching through soil is cheaper than concrete or asphalt.',
                        className: 'right-tip'
                    },
                    {
                        type: 'priceRange',
                        key: 'installationPriceRange',
                        property: 'installationCost',
                        desktopOnly: true
                    }
                ]
            }
        ]
    },
    {
        key: 'totalCost',
        type: 'bottomBar',
        desktopOnly: true,
        sections: [
            {
                key: 'bottomLineBox',
                type: 'bottomLineBox',
                collection: 'totalCosts',
                elements: [
                    {
                        key: 'bottomLineTitleExp',
                        type: 'bottomLineTitleExp',
                        title: 'Total',
                        text: costEstimatesDisclaimer
                    },
                    {
                        type: 'priceRange',
                        property: 'totalProjectCost',
                        useM: true,
                        key: 'totalProjectPriceRange'
                    }
                ],
            }
        ]
    }
]

export default EVCostsData ;