const SelectiveInput = (
    {
        collection,
        property, 
        chooseComponent
    }
) => {
    return chooseComponent(collection[property])
}

export default SelectiveInput;