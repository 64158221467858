import React from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CatalogDropDownFilter, CatalogToggleButtonFilter } from '../InputBuilders/inputBuilders';

const ElectricCatalogFilters = ({
    indexElectricFilterValueKeys,
    evChargerFormFactor,
    evChargerPowerLevelDC,
    evChargerPowerLevelAC, 
    evChargerNumPlugs,
    evChargerFilterSmartCharging,
    evChargerFilterVGI,
    evChargerFilterNetworked,
    accordionExpanded,
    setAccordionExpanded,
    updaters, 
}) => {
    const dispatch = useDispatch();

    const createCatalogDropDownFilter = () => (new CatalogDropDownFilter());
    const createCatalogToggleButtonFilter = () => (new CatalogToggleButtonFilter());
    const hideACSelect = evChargerFormFactor === 'DC';
    const hideDCSelect = evChargerFormFactor === 'AC';

    const getBoolFromButtonClick = (event) => {
        return event.currentTarget.name === 'yes';
    }

    const formFactorFilter = createCatalogDropDownFilter()
        .withClassName()
        .withValues(Array.from(indexElectricFilterValueKeys.get("current").keys()))
        .withInputLabel("Form Factor")
        .withInputProps_name()
        .withInputProps_id()
        .withValue(evChargerFormFactor)
        .withPlaceholder('All')
        .withHandleChangeFunction((event) => { dispatch(updaters.evChargerFormFactor(event.target.value)) })
        .build();

    const kwdcFillter = createCatalogDropDownFilter()
        .withClassName()
        .withValues(Array.from(indexElectricFilterValueKeys.get("kw_dc").keys()).sort((a, b) => { return a - b }).filter((v) => { return v !== null }))
        .withInputLabel("DC Power per Plug")
        .withInputProps_name()
        .withInputProps_id()
        .withValue(evChargerPowerLevelDC)
        .withPlaceholder('All')
        .withHandleChangeFunction((event) => { dispatch(updaters.evChargerPowerLevelDC(event.target.value)) })
        .build();

    const kwacFillter = createCatalogDropDownFilter()
        .withClassName()
        .withValues(Array.from(indexElectricFilterValueKeys.get("kw_ac").keys()).sort((a, b) => { return a - b }).filter((v) => { return v !== null }))
        .withInputLabel("AC Power per Plug")
        .withInputProps_name()
        .withInputProps_id()
        .withValue(evChargerPowerLevelAC)
        .withPlaceholder('All')
        .withHandleChangeFunction((event) => { dispatch(updaters.evChargerPowerLevelAC(event.target.value)) })
        .build();

    const numberOfPlugFilters = createCatalogDropDownFilter()
        .withClassName()
        .withValues(Array.from(indexElectricFilterValueKeys.get("num_of_ports").keys()))
        .withInputLabel("# of Plugs")
        .withInputProps_name()
        .withInputProps_id()
        .withValue(evChargerNumPlugs)
        .withPlaceholder('All')
        .withHandleChangeFunction((event) => { dispatch(updaters.evChargerNumPlugs(event.target.value)) })
        .build();

    const smartChargingFilter = createCatalogToggleButtonFilter()
        .withClassName()
        .withValues(Array.from(indexElectricFilterValueKeys.get("wifi").keys()))
        .withInputLabel("Smart Charging")
        .withInputProps_name()
        .withInputProps_id()
        .withValue(evChargerFilterSmartCharging)
        .withTooltip('Smart Chargers can communicate with the vehicle, the grid, and charging operators to manage charging automatically and optimize for time and thus cost of charge.')
        .withHandleChangeFunction((event) => {
            dispatch(updaters.evChargerFilterSmartCharging(getBoolFromButtonClick(event)))
        })
        .build();

    const vehicleGridIntegrationFilter = createCatalogToggleButtonFilter()
        .withClassName()
        .withValues(Array.from(indexElectricFilterValueKeys.get("vgi_capable").keys()))
        .withInputLabel("Vehicle Grid Integration")
        .withInputProps_name()
        .withInputProps_id()
        .withValue(evChargerFilterVGI)
        .withTooltip('Vehicle Grid Integration is an extension of smart charging capabilities that enables bi-directional communication of charger and grid, and allows for EV operators to sell energy back to the grid at a potential profit.')
        .withHandleChangeFunction((event) => {
            dispatch(updaters.evChargerFilterVGI(getBoolFromButtonClick(event)))
        })
        .build();

    const networkedFilter = createCatalogToggleButtonFilter()
        .withClassName()
        .withValues(Array.from(indexElectricFilterValueKeys.get("wifi").keys()))
        .withInputLabel("Networked")
        .withInputProps_name()
        .withInputProps_id()
        .withValue(evChargerFilterNetworked)
        .withTooltip('Networked chargers offer a charger-to-charger or charger-to-network internet connection which enables them to collect usage data, identify problems, balance loads during peak demand, or charge users a fee for plugging in.')
        .withHandleChangeFunction((event) => {
            dispatch(updaters.evChargerFilterSmartCharging(getBoolFromButtonClick(event)))
        })
        .build();    


    const dcFilter = hideDCSelect 
        ? null
        : (            
            <Grid item xs={12} md={3}>
                {kwdcFillter}
            </Grid>
        );   
        
    const acFilter = hideACSelect 
        ? null
        : (            
            <Grid item xs={12} md={3}>
                {kwacFillter}
            </Grid>
        );        

    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                {formFactorFilter}
            </Grid>
            <Grid item xs={12} md={3}>
                {numberOfPlugFilters}
            </Grid>
            {dcFilter}
            {acFilter}
        </Grid>
        <Accordion
            expanded={accordionExpanded}
            onChange={(event, expanded) => {
                    setAccordionExpanded(expanded)
                }
            }
        >
            <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography>Additional Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>{smartChargingFilter}</Grid>
                    <Grid item xs={12} md={4}>{vehicleGridIntegrationFilter}</Grid>
                    <Grid item xs={12} md={4}>{networkedFilter}</Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
        </>
    )    
}

export default ElectricCatalogFilters;