import React, {useState} from "react";
import { CatalogSliderFilter, CatalogDropDownFilter } from '../InputBuilders/inputBuilders';
import { Grid } from '@material-ui/core';

const SliderComponent = ({
    indexValueKeys,
    cardProperty,
    label,
    value,
    units,
    updater,
}) => {
    const createCatalogSliderFilter = () => (new CatalogSliderFilter());

    const [sliderValue, setSliderValue] = useState([...value]); // Slider value must be within same component that renders the slider itself. It'll break if put anywhere else.

    const slider = createCatalogSliderFilter()
        .withClassName()
        .withValues(Array.from(indexValueKeys.get(cardProperty).keys()))
        .withInputLabel(label)
        .withInputProps_name()
        .withInputProps_id()
        .withValue(sliderValue)
        .withHandleChangeFunction(updater)
        .withOnChange((event, newValue) => {
            setSliderValue(newValue)        
        })
        .withMin(Math.min(...Array.from(indexValueKeys.get(cardProperty).keys())))
        .withMax(Math.max(...Array.from(indexValueKeys.get(cardProperty).keys())))
        .withUnits(units)
        .build()
    
    return slider;    
}

class CatalogFilterSetBuilder {
    constructor() {
        this.inputs={
            filters: []
        }

        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        const createCatalogDropDownFilter = () => (new CatalogDropDownFilter());

        const filterTypeMap = {
            slider : (props) => {
                return (  
                    <SliderComponent
                        indexValueKeys={props.indexValueKeys}
                        cardProperty={props.cardProperty}
                        label={props.label}
                        value={props.value}
                        units={props.units}
                        updater={props.updater}
                    />
                )
            }, 
            select: (props) => {
                return createCatalogDropDownFilter()
                    .withClassName(props.className)
                    .withValues(Array.from(props.indexValueKeys.get(props.cardProperty).keys()))
                    .withInputLabel(props.label)
                    .withInputProps_name(props.name)
                    .withInputProps_id(props.id)
                    .withValue(props.value)
                    .withPlaceholder(props.placeholder)
                    .withHandleChangeFunction(props.updater)
                    .build()
            },
            built: (props) => {
                return props.component;
            }
        }

        return(
            <Grid container spacing={3}>
                {
                    this.inputs.filters.map((filter, index) => {
                        return (
                            <Grid item xs={12} md={3} key={`filter_${index}`}>
                                {filterTypeMap[filter.type](filter)} 
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }
}

export default CatalogFilterSetBuilder;