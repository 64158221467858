import React from 'react';
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const mediaStyles = makeStyles((theme) => ({
    media: {
        width: 150,
        height: 250,
        margin: '0 auto'
    }
}))

const Catalog = (
    {
        componentMetadata,
        renderingFunction,
        catalogCards,
        cardMapping
    }
) => {
    const mediaClasses = mediaStyles();
    
    const defaultRenderer = () => {    
        const renderCatalogCards = () => {
            return(
                <Grid
                    spacing={2}
                    container
                >
                    {
                        componentMetadata.map((element, i) => {
                            return catalogCards(cardMapping, element, mediaClasses)
                        })
                    }
                </Grid>
            )
        };

        return (
            <Box>
                {renderCatalogCards()}
            </Box>
        )
    }

    const doRender = renderingFunction || defaultRenderer;

    return doRender();
}

export default Catalog;
