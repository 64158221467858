//assuming a 5min fueling time per vehicle
const calcDailyVehiclesFueledPerDispenser = (startTime, endTime) => {
  let fuelingTime = endTime - startTime;
  if (fuelingTime < 0) fuelingTime += 24;
  return fuelingTime * 12;
};
export default class H2DepotFueling{
    constructor() {
      this.inputs = {
        VehicleType: null,
        VehicleCount: 0,
        VehicleMilesPerDay: 0,
        StartTime: 0,
        EndTime: 0,
      };
      // //dynamically generating setters for inputs in constructor
      Object.keys(this.inputs).forEach(input => {
        this[`with${input}`] = (value) => {
          this.inputs[`${input}`] = value;
          return this
     }})

    }

    calculate() {

      var _vehicleKGH2PerMile = 1 / this.inputs.VehicleType 
      var _dailyH2DemandPerVehicle  =  _vehicleKGH2PerMile * this.inputs.VehicleMilesPerDay
      var _dailyH2FleetDemand  = _dailyH2DemandPerVehicle * this.inputs.VehicleCount;
      var _vehiclesFueledPerDispenser  = calcDailyVehiclesFueledPerDispenser(this.inputs.StartTime, this.inputs.EndTime);
      var _dispenserDemandCount = this.inputs.VehicleCount / _vehiclesFueledPerDispenser;

      return {
        vehicleKGH2PerMile: _vehicleKGH2PerMile,
        dailyH2DemandPerVehicle: _dailyH2DemandPerVehicle, 
        dailyH2FleetDemand: _dailyH2FleetDemand,  
        vehiclesFueledPerDispenser: _vehiclesFueledPerDispenser,
        dispenserDemandCount: _dispenserDemandCount 
      }
    }
}