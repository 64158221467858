const vehicleArchetypes = [
    {
        "description": "School Bus 10,001-14,000 lbs",
        "kwhPerMile": 1,
        "h2MilesPerKilogram": "N/A",
        "id": "school bus 10,001-14,000 lbs"
    },
    {
        "description": "School Bus 14,001-16,000 lbs",
        "kwhPerMile": 1,
        "h2MilesPerKilogram": "N/A",
        "id": "school bus 14,001-16,000 lbs"
    },
    {
        "description": "School Bus 16,001-26,000 lbs",
        "kwhPerMile": 1.4,
        "h2MilesPerKilogram": "N/A",
        "id": "school bus 16,001-26,000 lbs"
    },
    {
        "description": "School Bus 26,001-29,000 lbs",
        "kwhPerMile": 1.4,
        "h2MilesPerKilogram": "N/A",
        "id": "school bus 26,001-29,000 lbs"
    },
    {
        "description": "School Bus 29,000+ lbs",
        "kwhPerMile": 1.4,
        "h2MilesPerKilogram": "N/A",
        "id": "school bus 29,000+ lbs"
    },
    {
        "description": "School Bus 5,001-8,500 lbs",
        "kwhPerMile": 1.36,
        "h2MilesPerKilogram": "N/A",
        "id": "school bus 5,001-8,500 lbs"
    },
    {
        "description": "School Bus 8,501-10,000 lbs",
        "kwhPerMile": 1,
        "h2MilesPerKilogram": "N/A",
        "id": "school bus 8,501-10,000 lbs"
    },
    {
        "description": "Shuttle Bus 10,001-14,000 lbs",
        "kwhPerMile": 0.5,
        "h2MilesPerKilogram": "N/A",
        "id": "shuttle bus 10,001-14,000 lbs"
    },
    {
        "description": "Shuttle Bus 14,001-19,500 lbs",
        "kwhPerMile": 1,
        "h2MilesPerKilogram": "N/A",
        "id": "shuttle bus 14,001-19,500 lbs"
    },
    {
        "description": "Shuttle Bus 19,501-26,000 lbs",
        "kwhPerMile": 1.13,
        "h2MilesPerKilogram": "N/A",
        "id": "shuttle bus 19,501-26,000 lbs"
    },
    {
        "description": "Shuttle Bus 26,001-33,000 lbs",
        "kwhPerMile": 1.5,
        "h2MilesPerKilogram": "N/A",
        "id": "shuttle bus 26,001-33,000 lbs"
    },
    {
        "description": "Shuttle Bus 33,000+ lbs",
        "kwhPerMile": 2.15,
        "h2MilesPerKilogram": "N/A",
        "id": "shuttle bus 33,000+ lbs"
    },
    {
        "description": "Shuttle Bus 8,501-10,000 lbs",
        "kwhPerMile": 0.5,
        "h2MilesPerKilogram": "N/A",
        "id": "shuttle bus 8,501-10,000 lbs"
    },
    {
        "description": "Transit Bus 20-24'",
        "kwhPerMile": 1.5,
        "h2MilesPerKilogram": "N/A",
        "id": "transit bus 20-24'"
    },
    {
        "description": "Transit Bus 25-29'",
        "kwhPerMile": 1.5,
        "h2MilesPerKilogram": "N/A",
        "id": "transit bus 25-29'"
    },
    {
        "description": "Transit Bus 30-39'",
        "kwhPerMile": 2.01,
        "h2MilesPerKilogram": "8",
        "id": "transit bus 30-39'"
    },
    {
        "description": "Transit Bus 40-59'",
        "kwhPerMile": 2.19,
        "h2MilesPerKilogram": "4.99",
        "id": "transit bus 40-59'"
    },
    {
        "description": "Transit Bus 40'+ double decker",
        "kwhPerMile": 3,
        "h2MilesPerKilogram": "N/A",
        "id": "transit bus 40'+ double decker"
    },
    {
        "description": "Transit Bus 60'",
        "kwhPerMile": 2.94,
        "h2MilesPerKilogram": "5.83",
        "id": "transit bus 60'"
    },
    {
        "description": "Truck 10,001-14,001 lbs",
        "kwhPerMile": 1.5,
        "h2MilesPerKilogram": "N/A",
        "id": "truck 10,001-14,001 lbs"
    },
    {
        "description": "Truck 14,001-19,500 lbs",
        "kwhPerMile": 2,
        "h2MilesPerKilogram": "12.78",
        "id": "truck 14,001-19,500 lbs"
    },
    {
        "description": "Truck 14,001-19,500 lbs Step van",
        "kwhPerMile": 2.06,
        "h2MilesPerKilogram": "12.78",
        "id": "truck 14,001-19,500 lbs step van"
    },
    {
        "description": "Truck 19,501-26,000 lbs",
        "kwhPerMile": 2.2,
        "h2MilesPerKilogram": "N/A",
        "id": "truck 19,501-26,000 lbs"
    },
    {
        "description": "Truck 26,001-33,000 lbs",
        "kwhPerMile": 2.32,
        "h2MilesPerKilogram": "N/A",
        "id": "truck 26,001-33,000 lbs"
    },
    {
        "description": "Truck 33,000+ lbs Refuse",
        "kwhPerMile": 8.81,
        "h2MilesPerKilogram": "N/A",
        "id": "truck 33,000+ lbs refuse"
    },
    {
        "description": "Truck 33,000+ lbs Tractor",
        "kwhPerMile": 2.06,
        "h2MilesPerKilogram": "8",
        "id": "truck 33,000+ lbs tractor"
    },
    {
        "description": "Truck 5,001-8,500 lbs",
        "kwhPerMile": 1,
        "h2MilesPerKilogram": "N/A",
        "id": "truck 5,001-8,500 lbs"
    },
    {
        "description": "Truck 8,501-10,000 lbs",
        "kwhPerMile": 1.5,
        "h2MilesPerKilogram": "N/A",
        "id": "truck 8,501-10,000 lbs"
    }
]
export default vehicleArchetypes;