import React, {useState} from 'react';
import { Modal, Grid, Typography, Paper, Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { NavLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { 
    level2WiredLearnMore,
    dcfcWiredLearnMore,
    gaseousDeliveryLearnMore,
    liquidDeliveryLearnMore 
} from '../../../assets/images';

const FuelingAlternativesCardModal = ({
    modalData
}) => {

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const catalogLinkClassName = !isMobileDevice ? 'catalog-link-button-container' : 'catalog-link-button-container-mobile';

    const images = {
        level2WiredLearnMore,
        dcfcWiredLearnMore,
        gaseousDeliveryLearnMore,
        liquidDeliveryLearnMore
    }

    let modalHeight = modalData.img ? 495 : 460;
    if(isMobileDevice) {
        modalHeight = 0;
    }
    const modalClassName = modalData.img ? 'learn-more-modal' : 'learn-more-modal-no-image';
    const textContainerWidth = modalData.img ? 6 : 12;

    const imageWindow = modalData.img && !isMobileDevice && (
        <Grid 
            item 
            className="learn-more-image"
            xs={6}
        ><img src={images[modalData.img]} alt={modalData.img}/></Grid>
    )

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="learn-more-button-and-modal">
            <button type="button" className="learn-more-launch-modal" onClick={() => {setIsOpen(true)}}>
                { modalData.buttonText }
            </button>
            <Modal
                open={isOpen}
                onClose={() => {setIsOpen(false)}}
            >
                <div className={modalClassName} style={{ padding: 16, margin: 'auto', maxWidth: 749 }}>
                    <Paper style={{ padding: 16, position: 'relative', minHeight: modalHeight }}>   
                        <div className = "close-button">
                            <Button onClick={() => {
                                setIsOpen(false);
                            }}>
                                <CloseIcon/>
                            </Button>        
                        </div>    
                        
                        <Grid container>
                            {imageWindow}
                            <Grid
                                item  
                                className="learn-more-content"
                                xs={12}
                                md={textContainerWidth}
                            >
                                <Typography className="learn-more-title">
                                    {modalData.title}
                                </Typography> 
                                <Typography className="learn-more-subtitle">
                                    {modalData.subtitle}
                                </Typography>
                                <Typography className="learn-more-text">
                                    {modalData.text}
                                </Typography> 
                                <div 
                                    className={catalogLinkClassName}
                                >
                                    <NavLink className="catalog-link-button" to={modalData.exploreLinkURL}>{modalData.exploreLinkText}</NavLink>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>    
            </Modal>

        </div>
    )
}

export default FuelingAlternativesCardModal;