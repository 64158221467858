import React from "react"
import { Grid, Typography, Box } from "@material-ui/core"
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { NavLink } from "react-router-dom"
import { VendorCatalog } from "./Pages"

import {costEstimatesDisclaimer} from "../cap";


const costCardData = (engTotalCost, permitTotalCost, hardTotalCost, totalCost) => {

    return [
        {
            title: <Typography className="TopBar-title">Engineering &amp; Design</Typography>,
            cost: <Typography className="TopBar-cost">{engTotalCost}</Typography>
        },
        {
            title: <Typography className="TopBar-title">Permitting &amp; Fees</Typography>,
            cost: <Typography className="TopBar-cost">{permitTotalCost}</Typography>
        },
        {
            title: <Typography className="TopBar-title">Hardware &amp; Installation</Typography>,
            cost: <Typography className="TopBar-cost">{hardTotalCost}</Typography>
        },
        {
            title: <Typography className="TopBar-title">Total</Typography>,
            cost: <Typography className="TopBar-cost">{totalCost}</Typography>,
            hideChevron: true
        },
    ]
}

export const createMobileCostTopBar = (totalCost) => {
    return (
        <>
            <Grid item xs={3}><Typography className="TopBar-title">Total</Typography></Grid>
            <Grid item xs={9}><Typography className="TopBar-cost">{totalCost}</Typography></Grid>
            <Grid item xs={12}><Typography className="TopBar-disclaimer">{costEstimatesDisclaimer}</Typography></Grid>
        </>
    );

}

export const createCostTopBar = (engTotalCost, permitTotalCost, hardTotalCost, totalCost) => {
    const mappableCostCardData = costCardData(engTotalCost, permitTotalCost, hardTotalCost, totalCost);
    


    return mappableCostCardData.map(card => {

        const chevron = !card.hideChevron && <ArrowRightIcon className="costs_chevron"/>

        return (
            <Grid item xs={2}>
                <Box
                    display="flex"
                >
                    <Box>
                        {card.title}
                        {card.cost}
                    </Box>
                    <Box>
                        {chevron}
                    </Box>

                </Box>
            </Grid>
        )
    })
}



const laborTipCardsData = (engTotalCost) => {

    return [
        {
            title: <Typography className="labor-tip-cards-title">Labor</Typography>,
            text: <Typography className="labor-tip-card-description">Design of your hydrogen infrastructure</Typography>,
            xs: 12,
            desktopOnly: true
        },
        {
            gridContainer: (
                <Grid item xs={12} container>
                    <Grid item xs={6}>
                        <Typography className="labor-tip-cards-title-mobile">Labor</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className="labor-tip-cards-cost-mobile">{engTotalCost}</Typography>
                    </Grid>                 
                    <Grid item xs={12} className="labor-tip-cards-description-container-mobile">
                        <Typography className="labor-tip-cards-description-mobile">Design of your hydrogen infrastructure</Typography>
                    </Grid>                   
                </Grid>
            ),
            mobileOnly: true
        }, 
        {
            title: <Typography className="labor-tip-cards-title">Tip #1</Typography>,
            text: <Typography className="labor-tip-card-text">Consult the <NavLink to={VendorCatalog.link}>Vendor catalog</NavLink> for a list of engineering firms.</Typography>,
            xs: 6,
            isTip: true
        },
        {
            title: <Typography className="labor-tip-cards-title">Tip #2</Typography>,
            text: <Typography className="labor-tip-card-text">Cost depends on the complexity of your site.</Typography>,
            xs: 6,
            isTip: true
        },
        {
            title: <Typography className="labor-tip-cards-cost">{engTotalCost}</Typography>,
            xs: 12,
            desktopOnly: true
        }]
}

export const createLaborTips = (engTotalCost, isMobileDevice) => {
    return laborTipCardsData(engTotalCost).map(card => {

        const tipCardClassName = 'tip-card';

        const gridItemClassName = card.isTip ?
            isMobileDevice ? `${tipCardClassName}-mobile` : `${tipCardClassName}-desktop` :
            null

        let gridItem = card.gridContainer || (
            <Grid item xs={card.xs} md={3} className={gridItemClassName}>
                {card.title}
                {card.text}
            </Grid>
        )

        if(card.desktopOnly) {
            gridItem = isMobileDevice ? null: gridItem;
        }
        
        if(card.mobileOnly) {
            gridItem = isMobileDevice ? gridItem: null;
        }

        return gridItem;
    })
}

const permitFeesTipCardsData = permitTotalCost => {
    return [
        {
            title: <Typography className="permit-fees-tip-cards-title">Permitting &amp; Fees</Typography>,
            text: <Typography className="permit-fees-tip-card-description">You may need to obtain permits from multiple local authorities</Typography>, 
            xs: 12,
            desktopOnly: true
        },
        {
            gridContainer: (
                <Grid item xs={12} container>
                    <Grid item xs={6}>
                        <Typography className="permit-fees-tip-cards-title-mobile">Permitting &amp; Fees</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className="permit-fees-tip-cards-cost-mobile">{permitTotalCost}</Typography>
                    </Grid>                 
                    <Grid item xs={12} className="permit-fees-tip-cards-description-container-mobile">
                        <Typography className="permit-fees-tip-cards-description-mobile">You may need to obtain permits from multiple local authorities</Typography>
                    </Grid>                   
                </Grid>
            ),
            mobileOnly: true
        },
        {
            title: <Typography className="permit-fees-tip-cards-title">Tip #1</Typography>,
            text: <Typography className="permit-fees-tip-card-text">Permitting fees depend on project complexity.</Typography>,
            xs: 6,
            isTip: true
        },
        {
            title: <Typography className="permit-fees-tip-cards-title">Tip #2</Typography>,
            text: <Typography className="permit-fees-tip-card-text">Public H2 fueling projects may require additional permits.</Typography>,
            xs: 6,
            isTip: true
        },
        {
            title: <Typography className="permit-fees-tip-cards-cost">{permitTotalCost}</Typography>,
            xs: 12,
            desktopOnly: true
        }]
}

export const createPermitTips = (permitTotalCost, isMobileDevice) => {
    return permitFeesTipCardsData(permitTotalCost).map(card => {

        const tipCardClassName = 'tip-card';

        const gridItemClassName = card.isTip ?
            isMobileDevice ? `${tipCardClassName}-mobile` : `${tipCardClassName}-desktop` :
            null

        let gridItem = card.gridContainer || (
            <Grid item xs={card.xs} md={3} className={gridItemClassName}>
                {card.title}
                {card.text}
            </Grid>
        )

        if(card.desktopOnly) {
            gridItem = isMobileDevice ? null: gridItem;
        }
        
        if(card.mobileOnly) {
            gridItem = isMobileDevice ? gridItem: null;
        }

        return gridItem;
    })
}
