//Compile and use logic to handle
import {
    CHARGER_TYPE_AC_WIRELESS,
    CHARGER_TYPE_AC_WIRED,
    CHARGER_TYPE_DC_WIRED,
    CHARGER_TYPE_DC_OVERHEAD
} from '../../constants';

const CHARGER_OPTIONS = {
    'l2ACwl7.2': 'Level 2 AC wireless charging, 7.2kW',
    'l2ACw7.7': 'Level 2 AC wired charging, 7.7kW',
    'l2ACw16.8': 'Level 2 AC wired charging, 16.8kW',
    'l2ACw19.2': 'Level 2 AC wired charging, 19.2kW',
    'dcfw50': 'DC Fast Charging, wired, 50kW',
    'dcfw150': 'DC Fast Charging, wired, 150kW',
    'dcfw350': 'DC Fast Charging, wired, 350kW',
    'dcfo150': 'DC Fast Charging, overhead, 150kW',
    'dcfo350': 'DC Fast Charging, overhead, 350kW'
}

const RECOMMENDED_CHARGER_DETAILS = {
    'l2ACwl7.2': {
        chargerType: CHARGER_TYPE_AC_WIRELESS,
        chargerPower: 7.2,
        chargerCost: {
            min: 2,
            max: 5
        }
    },
    'l2ACw7.7_p2': {
        chargerType: `${CHARGER_TYPE_AC_WIRED} 2 plugs`,
        chargerPower: 7.7,
        chargerCost: {
            min: 2,
            max: 5    
        }
    },
    'l2ACw16.8_p2': {
        chargerType: `${CHARGER_TYPE_AC_WIRED} 2 plugs`,
        chargerPower: 16.8,
        chargerCost: {
            min: 2,
            max: 5    
        }
    },
    'l2ACw19.2_p2': {
        chargerType: `${CHARGER_TYPE_AC_WIRED} 2 plugs`,
        chargerPower: 19.2,
        chargerCost: {
            min: 2,
            max: 5    
        }
    }, 
    'dcfw50_p2': {
        chargerType: `${CHARGER_TYPE_DC_WIRED} 2 plugs`,
        chargerPower: 50,
        chargerCost: {
            min: 30,
            max: 60   
        }
    },
    'dcfw150_p2': {
        chargerType: `${CHARGER_TYPE_DC_WIRED} 2 plugs`,
        chargerPower: 150,
        chargerCost: {
            min: 50,
            max: 100   
        }
    },
    'dcfw350_p2': {
        chargerType: `${CHARGER_TYPE_DC_WIRED} 2 plugs`,
        chargerPower: 350,
        chargerCost: {
            min: 128,
            max: 150   
        }
    }, 
    'dcfo150_p2': {
        chargerType: CHARGER_TYPE_DC_OVERHEAD,
        chargerPower: 150,
        chargerCost: {
            min: 300,
            max: 1200   
        }
    },
    'dcfo350_p2': {
        chargerType: CHARGER_TYPE_DC_OVERHEAD,
        chargerPower: 350,
        chargerCost: {
            min: 300,
            max: 1200   
        }
    }    
}

export {CHARGER_OPTIONS, RECOMMENDED_CHARGER_DETAILS};