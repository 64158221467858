import { InfrastructureSite } from './infrastructureSiteBuilder'
import { h2CostByDelivryMechanism } from '../data/h2Costs'

export default class H2InfrastructureSite extends InfrastructureSite {

    constructor() {
        super();
        this.inputs = Object.assign({}, this.inputs, { h2Quantity: 0, naturalGas: false, siteRecommendation: "" })
        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }
}

export const getSiteRecommendation = (hydrogenInfrastructureSite) => {
    if (hydrogenInfrastructureSite.inputs.landSize === 'mid') {
        hydrogenInfrastructureSite.withSiteRecommendation('h2')
    }
}

export const getHydrogenInfrastructureRecommendation = (hydrogenInfrastructureSite) => {

    const getDeliveryCostByMechanism = (h2Quantity, mechanism) => {
        if (h2Quantity === 0) {
            throw new Error('Must enter hydrogen quantity')
        }
        let demand = "standardMin"
        if (mechanism === "steamMethaneReforming") {
            demand = (h2Quantity < 300 ? "lowDemandMin" : "standardMin")
        }
        return {
            deliveryOption: mechanism,
            min: h2Quantity * h2CostByDelivryMechanism[mechanism][`${demand}`],
            max: h2Quantity * h2CostByDelivryMechanism[mechanism][`${demand}`],

        };
    };

    const validDeliveryOptions = {};
 

    let userSiteDeliveryOptions = getViableDeliveryMechanisms(hydrogenInfrastructureSite.inputs.water, hydrogenInfrastructureSite.inputs.landSize, hydrogenInfrastructureSite.inputs.electricity, hydrogenInfrastructureSite.inputs.gas, hydrogenInfrastructureSite.inputs.electricityPrice);
    // checks if all options in userSiteDeliveryOptions are false so hydrogen is not possible
    if(userSiteDeliveryOptions === undefined) return
    if (Object.values(userSiteDeliveryOptions).every(element => element === false)) {
        return userSiteDeliveryOptions
    }
    for (const option in userSiteDeliveryOptions) {
        if (userSiteDeliveryOptions[option] === true) {
            validDeliveryOptions[option] = userSiteDeliveryOptions[option];
        }
    }
    let cheaptestDeliveryMethod = null

    var results = []
    for (const option in validDeliveryOptions) {
        if (cheaptestDeliveryMethod === null) {
            cheaptestDeliveryMethod = (getDeliveryCostByMechanism(hydrogenInfrastructureSite.inputs.h2Quantity, option))

        } else {
            let newDeliveryMethod = getDeliveryCostByMechanism(hydrogenInfrastructureSite.inputs.h2Quantity, option);
            if (newDeliveryMethod.min === cheaptestDeliveryMethod.min) results.push(newDeliveryMethod);
            else {
                cheaptestDeliveryMethod = (newDeliveryMethod.min < cheaptestDeliveryMethod.min) ? newDeliveryMethod : cheaptestDeliveryMethod
                results = []
            }
        }
    }
    results.push(cheaptestDeliveryMethod);
    return results
}
export const getViableDeliveryMechanisms = (landSize, water, electricity, naturalGas) => {
    let userSiteDeliveryOptions = {
        tubeTrailer: false,
        gas: false,
        liquid: false,
        electrolysis: false,
        steamMethaneReforming: false,
    };


    if (landSize === 'small') {
        return userSiteDeliveryOptions;
    }

    if (landSize === "mid" || landSize === 'large') {
        userSiteDeliveryOptions.tubeTrailer = true;
        if (electricity === "yes") {
            userSiteDeliveryOptions.liquid = true;
            userSiteDeliveryOptions.gas = true;
            if (water === "yes" && landSize === "large") {
                if (electricity === "yes") {
                    userSiteDeliveryOptions.electrolysis = true;
                }
                if (naturalGas === "yes") {
                    userSiteDeliveryOptions.steamMethaneReforming = true;
                }
            }
        }
        return userSiteDeliveryOptions;
    }
    return userSiteDeliveryOptions;
}