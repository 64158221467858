import { Grid, Button, FormControl, InputLabel, Select, Slider, Typography } from '@material-ui/core';
import { commafyNumber } from '../../utils/numberFormatting';
import CSTooltip from '../CSTooltip';

/**************************************************
 *  
 *      Filter Functions   - Selects
 *  
 * *************************************************/


class CatalogDropDownFilter {

    constructor() {
        this.inputs = {
            className: "", values: [], inputLabel: "",
            inputProps_name: "", inputProps_id: "", value: "",
            handleChangeFunction: "", placeholder: "", blankOption: true
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        const inputProps = {
            name: this.inputs.inputProps_name,
            id: this.inputs.inputProps_id
        }

        const firstOption = this.inputs.blankOption && this.inputs.values[0] !== null && <option value="">{this.inputs.placeholder}</option>

        return (
            <FormControl
                className={this.inputs.className}
            >
                <InputLabel htmlFor={this.inputs.className}>{this.inputs.inputLabel}</InputLabel>
                <Select native value={this.inputs.value} onChange={this.inputs.handleChangeFunction} inputProps={inputProps}>
                    {firstOption}
                    {this.inputs.values.map(value => <option value={value} >{value}</option>)}
                </Select>
            </FormControl>
        )
    }
}

/**************************************************
 *  
 *      Filter Functions   -  Buttons Groups
 *  
 * *************************************************/


class CatalogToggleButtonFilter {

    constructor() {
        this.inputs = {
            className: "", values: [], inputLabel: "",
            inputProps_name: "", inputProps_id: "", value: "",
            handleChangeFunction: () => { },
            tooltip: null,
            selected: ""
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {

        const tooltip = this.inputs.tooltip && (
            <CSTooltip 
                buttonClassName="title-component-tooltip info-icon"
                details={this.inputs.tooltip}
            />

        )


        return (
            <FormControl className={this.inputs.className}>
                <InputLabel htmlFor={this.inputs.className}>{this.inputs.inputLabel}{tooltip}</InputLabel>

                <Grid container spacing={3} className="additional-filter-buttons">
                    <Grid item xs={6} >
                        <Button name="yes" onClick={this.inputs.handleChangeFunction} className={(this.inputs.value === true) ? "isActive" : ""} >Yes</Button>
                    </Grid>
                    <Grid item xs={6} >
                        <Button name="no" onClick={this.inputs.handleChangeFunction} className={(this.inputs.value === false) ? "isActive" : ""} >No</Button>
                    </Grid>
                </Grid>

            </FormControl>
        )
    }
}

/**************************************************
 *  
 *      Filter Functions   -  Slider
 *  
 * *************************************************/

class CatalogSliderFilter {

    constructor() {
        this.inputs = {
            className: "", values: [], inputLabel: "",
            inputProps_name: "", inputProps_id: "", value: [0, 0],
            handleChangeFunction: () => { }, onChange: () => {},
            selected: "", min: 0, max: 100,
            step: 1, units: ""
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {

        return (
            <div>
                <Typography id={this.inputs.inputProps_id} gutterBottom>
                    {this.inputs.inputLabel}
                </Typography>

                <Slider
                    min={this.inputs.min}
                    max={this.inputs.max}
                    step={this.inputs.step}
                    value={this.inputs.value}
                    aria-labelledby={this.inputs.inputProps_id}
                    onChangeCommitted={this.inputs.handleChangeFunction}
                    onChange={this.inputs.onChange}
                />
                <Typography id="non-linear-slider" gutterBottom> {commafyNumber(this.inputs.value[0])} to {commafyNumber(this.inputs.value[1])} {this.inputs.units} </Typography>
            </div>
        )
    }
}

export {CatalogDropDownFilter, CatalogToggleButtonFilter, CatalogSliderFilter}