import React from 'react'
import PrintIcon from '@material-ui/icons/Print';


const CostWorkPlanPageTitle = ({ title }) => {
    return (
        <div className="page-title-container">
            <h3 className="cost-work-plan-page-title">{title}</h3>
            <div className="print-container" onClick={() => window.print()}>
                <span className="print-text">print</span>
                <PrintIcon />
            </div>
        </div>
    )
}
export default CostWorkPlanPageTitle