import React from 'react';
import { chillers, compressors, dispensers, storage, electrolyzers } from '../data/h2Equip/h2Equip';
import { YES, NO } from '../../data/constants'

import { Typography } from '@material-ui/core';

const yesOrNo = [
    {
        name: YES,
        value: YES
    },
    {
        name: NO,
        value: NO
    }
]

const storageTypes = [
    {
        name: 'Pipeline Trailer',
        value: 'Pipeline Trailer'
    },
]

const h2EquipCategory = [
    {
        name: 'Chillers',
        value: 'Chillers'
    },
    {
        name: 'Compressors',
        value: 'Compressors'
    },
    {
        name: 'Dispensers',
        value: 'Dispensers'
    },
    {
        name: 'Electrolyzers',
        value: 'Electrolyzers'
    },
    {
        name: 'Storage',
        value: 'Storage'
    }
]

const EquipmentCatalogContainer = [
    {
        filterHook: 'Chillers',
        key: 'hydrogenChillerCatalogContainer',
        type: 'catalog', // @TODO find a way to not need to declare this both here and within the section.
        sections: [
            {
                key: 'hydrogenChillerCatalog',
                collection: 'fuelingAlternativeFilters',
                type: 'catalog',
                spacing: 4,
                cardFilterHooks: [
                    {
                        field: 'cooling_capacity_kW',
                        property: 'chillerCoolingCapacityRange'
                    }
                ],
                cardMapping: {
                    title: {
                        field: 'make',
                        className: ' chargerCardMake'
                    },
                    subheader: {
                        field: 'model',
                        className: ' chargerCardModel'
                    },
                    abovePictureText: {
                        fields: [
                            'model_variant',
                        ],
                        className: ' abovePictureText'
                    },
                    media: {
                        field: 'img_final_th_(225x150)',
                        className: ''
                    },
                    cardContent: {
                        functions: [
                            (element) => {
                                return (
                                    <Typography>{`${element.cooling_capacity_kW} kW cooling capacity`}</Typography>
                                )
                            }
                        ]
                    },
                    buttonLink: {
                        field: 'product_info_page',
                        buttonText: 'See Details'
                    }
                },
                elements: chillers
            }
        ]
    },
    {
        filterHook: 'Compressors',
        key: 'hydrogenCompressorCatalogContainer',
        type: 'catalog',
        sections: [
            {
                key: 'hydrogenCompressorCatalog',
                collection: 'fuelingAlternativeFilters',
                type: 'catalog',
                spacing: 4,
                cardFilterHooks: [
                    {
                        field: 'flow_rate_m3_hour',
                        property: 'compressorFlowRate'
                    },
                    {
                        field: 'pressure_PSI',
                        property: 'compressorPressure'
                    },
                    {
                        field: 'power_KW',
                        property: 'compressorPower'
                    }
                ],
                cardMapping: {
                    title: {
                        field: 'make',
                        className: ' chargerCardMake'
                    },
                    subheader: {
                        field: 'model',
                        className: ' chargerCardModel'
                    },
                    abovePictureText: {
                        fields: [
                            'model_variant',
                        ],
                        className: ' abovePictureText'
                    },
                    media: {
                        field: 'img_final_th_(225x150)',
                        className: ''
                    },
                    cardContent: {
                        functions: [
                            (element) => {
                                return (
                                    <Typography>{`${element.flow_rate_m3_hour} m<sup>3</sup> per hour`}</Typography>
                                )
                            },
                            (element) => {
                                return (
                                    <Typography>{`${element.pressure_PSI} PSI`}</Typography>
                                )
                            },
                            (element) => {
                                return (
                                    <Typography>{`${element.power_KW} KW`}</Typography>
                                )
                            }
                        ]
                    },
                    buttonLink: {
                        field: 'product_info_page',
                        buttonText: 'See Details'
                    }
                },
                elements: compressors
            }
        ]
    },
    {
        filterHook: 'Dispensers',
        key: 'hydrogenDispenserCatalogContainer',
        type: 'catalog',
        sections: [
            {
                key: 'hydrogenDispenserCatalog',
                collection: 'fuelingAlternativeFilters',
                type: 'catalog',
                spacing: 4,
                cardFilterHooks: [
                    {
                        field: '_70MPa_dispenser',
                        property: 'h270MPa'
                    },
                    {
                        field: '_35MPa_dispenser',
                        property: 'h235MPa'
                    },
                    {
                        field: 'integrated_point_of_sale',
                        property: 'hIntPOS'
                    }
                ],
                cardMapping: {
                    title: {
                        field: 'make',
                        className: ' chargerCardMake'
                    },
                    subheader: {
                        field: 'model',
                        className: ' chargerCardModel'
                    },
                    abovePictureText: {
                        fields: [
                            'model_variant',
                        ],
                        className: ' abovePictureText'
                    },
                    media: {
                        field: 'img_final_th',
                        className: ''
                    },
                    cardContent: {
                        functions: [
                            (element) => {
                                const has70MPa = element._70MPa_dispenser === YES;
                                const toRender = has70MPa && (<Typography>70 MPa dispensing</Typography>)

                                return toRender
                            },
                            (element) => {
                                const has35MPa = element._35MPa_dispenser === YES;
                                const toRender = has35MPa && (<Typography>35 MPa dispensing</Typography>)

                                return toRender
                            },
                            (element) => {
                                const intPOS = element.integrated_point_of_sale === YES;
                                const toRender = intPOS && (<Typography>Integrated Point of Sale</Typography>)

                                return toRender
                            },

                        ]
                    },
                    buttonLink: {
                        field: 'product_info_page',
                        buttonText: 'See Details'
                    }
                },
                elements: dispensers
            }
        ]
    },
    {
        filterHook: 'Electrolyzers',
        key: 'hydrogenElectrolyzerCatalogContainer',
        type: 'catalog',
        sections: [
            {
                key: 'electrolyzerCatalog',
                collection: 'fuelingAlternativeFilters',
                type: 'catalog',
                spacing: 4,
                cardFilterHooks: [
                    {
                        field: 'net_production_rate',
                        property: 'h2ElectrolyzerProduction'
                    },
                    {
                        field: 'flow_rate',
                        property: 'h2ElectrolyzerFlowRate'
                    }
                ],
                cardMapping: {
                    title: {
                        field: 'make',
                        className: ' chargerCardMake'
                    },
                    subheader: {
                        field: 'model',
                        className: ' chargerCardModel'
                    },
                    abovePictureText: {
                        fields: [
                            'model_variant',
                        ],
                        className: ' abovePictureText'
                    },
                    media: {
                        field: 'img_final_th',
                        className: ''
                    },
                    cardContent: {
                        functions: [
                            (element) => {
                                return (
                                    <Typography>{element.type}</Typography>
                                )
                            },
                            (element) => {
                                return (
                                    <Typography>{`${element.net_production_rate} m<sup>3</sup> / hour`}</Typography>
                                )
                            },
                            (element) => {
                                return (
                                    <Typography>{`${element.flow_rate} cc / minute`}</Typography>
                                )
                            },

                        ]
                    },
                    buttonLink: {
                        field: 'product_info_page',
                        buttonText: 'See Details'
                    }
                },
                elements: electrolyzers
            }
        ]
    },

    {
        filterHook: 'Storage',
        key: 'hydrogenDispenserCatalogContainer',
        type: 'catalog',
        sections: [
            {
                key: 'hydrogenDispenserCatalog',
                collection: 'fuelingAlternativeFilters',
                type: 'catalog',
                spacing: 4,
                cardFilterHooks: [
                    {
                        field: 'pressure',
                        property: 'h2StoragePressure'
                    },
                    {
                        field: 'type',
                        property: 'h2StorageType'
                    },
                    {
                        field: 'capacity',
                        property: 'h2StorageVolume'
                    }
                ],
                cardMapping: {
                    title: {
                        field: 'make',
                        className: ' chargerCardMake'
                    },
                    subheader: {
                        field: 'model',
                        className: ' chargerCardModel'
                    },
                    abovePictureText: {
                        fields: [
                            'model_variant',
                        ],
                        className: ' abovePictureText'
                    },
                    media: {
                        field: 'img_final_th',
                        className: ''
                    },
                    cardContent: {
                        functions: [
                            (element) => {
                                return (
                                    <Typography>{element.type}</Typography>
                                )
                            },
                            (element) => {
                                return (
                                    <Typography>{`${element.capacity} m<sup>3</sup>`}</Typography>
                                )
                            },
                            (element) => {
                                return (
                                    <Typography>{`${element.pressure} PSI`}</Typography>
                                )
                            },

                        ]
                    },
                    buttonLink: {
                        field: 'product_info_page',
                        buttonText: 'See Details'
                    }
                },
                elements: storage
            }
        ]
    }
]

const H2CatalogFilterFieldGroups = [
    {
        key: 'H2StorageFilters',
        filterHook: 'Storage',
        sections: [
            {
                key: 'storageFilter',
                collection: 'fuelingAlternativeFilters',
                type: 'interactive',
                elements: [
                    {
                        key: 'h2EquipCategory',
                        property: 'h2EquipCategory',
                        type: 'dropdown',
                        options: h2EquipCategory,
                        title: 'Equipment Type',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'h2StorageType',
                        property: 'h2StorageType',
                        type: 'dropdown',
                        options: storageTypes,
                        title: 'Equipment Type',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'h2StorageVolume',
                        property: 'h2StorageVolume',
                        type: 'rangeSlider',
                        title: 'Volume',
                        min: 50000,
                        max: 500000, // @TODO work with real range?
                        label: 'm<sup>3</sup>',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'h2StoragePressure',
                        property: 'h2StoragePressure',
                        type: 'rangeSlider',
                        title: 'Pressure',
                        min: 0,
                        max: 5000, // @TODO work with real range?
                        label: 'MPa',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                ]
            }
        ]
    },

    {
        key: 'Electolyzers',
        filterHook: 'Electrolyzers',
        sections: [
            {
                key: 'electrolyzerFilter',
                collection: 'fuelingAlternativeFilters',
                type: 'interactive',
                elements: [
                    {
                        key: 'h2EquipCategory',
                        property: 'h2EquipCategory',
                        type: 'dropdown',
                        options: h2EquipCategory,
                        title: 'Equipment Type',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'h2ElectrolyzerProduction',
                        property: 'h2ElectrolyzerProduction',
                        type: 'rangeSlider',
                        title: 'Production Rate',
                        min: 50000,
                        max: 500000, // @TODO work with real range?
                        label: 'm<sup>3</sup> / hr',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'h2ElectrolyzerFlowRate',
                        property: 'h2ElectrolyzerFlowRate',
                        type: 'rangeSlider',
                        title: 'Flow Rate',
                        min: 0,
                        max: 150, // @TODO work with real range?
                        label: 'cc / minute',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                ]
            }
        ]
    },

    {
        key: 'H2DispenserFilters',
        filterHook: 'Dispensers',
        sections: [
            {
                key: 'dispensersFilter',
                collection: 'fuelingAlternativeFilters',
                type: 'interactive',
                elements: [
                    {
                        key: 'h2EquipCategory',
                        property: 'h2EquipCategory',
                        type: 'dropdown',
                        options: h2EquipCategory,
                        title: 'Equipment Type',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'h270MPa',
                        property: 'h270MPa',
                        type: 'dropdown',
                        options: yesOrNo,
                        title: '70 MPa dispensing',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'h235MPa',
                        property: 'h235MPa',
                        type: 'dropdown',
                        options: yesOrNo,
                        title: '35 MPa dispensing',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'hIntPOS',
                        property: 'hIntPOS',
                        type: 'dropdown',
                        options: yesOrNo,
                        title: 'Integrated point of sale',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    }
                ]
            }
        ]
    },
    {
        key: 'H2CompressorFilters',
        filterHook: 'Compressors',
        sections: [
            {
                key: 'compresssorsFilter',
                collection: 'fuelingAlternativeFilters',
                type: 'interactive',
                elements: [
                    {
                        key: 'h2EquipCategory',
                        property: 'h2EquipCategory',
                        type: 'dropdown',
                        options: h2EquipCategory,
                        title: 'Equipment Type',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'compressorFlowRate',
                        property: 'compressorFlowRate',
                        type: 'rangeSlider',
                        title: 'Flow Rate',
                        min: 0,
                        max: 5000, // @TODO work with real range?
                        label: 'm<sup>3</sup> / hr',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'compressorPressure',
                        property: 'compressorPressure',
                        type: 'rangeSlider',
                        title: 'Pressure',
                        min: 0,
                        max: 14000, // @TODO work with real range?
                        label: 'psi',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'compressorPower',
                        property: 'compressorPower',
                        type: 'rangeSlider',
                        title: 'Power',
                        min: 0,
                        max: 110, // @TODO work with real range?
                        label: 'kW',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    }
                ]
            }
        ]
    },
    {
        key: 'H2ChillerFilters',
        filterHook: 'Chillers',
        sections: [
            {
                key: 'chillersFilter',
                collection: 'fuelingAlternativeFilters',
                type: 'interactive',
                elements: [
                    {
                        key: 'h2EquipCategory',
                        property: 'h2EquipCategory',
                        type: 'dropdown',
                        options: h2EquipCategory,
                        title: 'Equipment Type',
                        tooltip: 'TBD',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    },
                    {
                        key: 'coolingCapactityRange',
                        property: 'chillerCoolingCapacityRange',
                        type: 'rangeSlider',
                        title: 'Cooling capactity',
                        min: 0,
                        max: 100, // @TODO work with real range
                        label: 'kW',
                        scaleOverrides: {
                            xs: 12,
                            md: 3
                        }
                    }
                ]
            }
        ]
    }
]

const EquipCatalogFieldGroups = [
    {
        key: 'fuelingAlternativeToggle',
        sections: [
            {
                key: 'fuelingAlternativeToggleButton',
                collection: 'fuelingAlternativeFilters',
                type: 'interactive',
                elements: [
                    {
                        key: 'fuelAlternativesToggle',
                        property: 'fuelType',
                        type: 'toggleTabs',
                        options: [
                            { name: 'Electric', action: 'electric', key: 'fuelType_electric' },
                            { name: 'Hydrogen', action: 'hydrogen', key: 'fuelType_hydrogen' }
                        ],
                        default: 'Electric'
                    }
                ]
            }
        ],
    }
]

const HydrogenEquipData = [
    ...EquipCatalogFieldGroups,
    ...H2CatalogFilterFieldGroups,
    ...EquipmentCatalogContainer
]

export default HydrogenEquipData;