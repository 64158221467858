import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    moreEVFiltersVisible: 'hidden',
    fuelType: '', 
    evChargerFormFactor: '',
    evChargerNumPlugs: '',
    evChargerPowerLevelAC: '',
    evChargerPowerLevelDC: '',
    evChargerFilterSmartCharging: '',
    evChargerFilterNetworked: '',
    evChargerFilterVGI: '',
    plugTypeFilter: new Set(),
    h2EquipCategory: 'Chillers',
    h270MPa: '',
    h235MPa: '',
    h2IntPOS: '',
    h2StoragePressure: [0, 5000],
    h2StorageType: 'Pipeline Trailer',
    h2StorageVolume: [50000, 500000],
    h2ElectrolyzerProduction: [0, 500000],
    h2ElectrolyzerFlowRate: [0, 3500],
    chillerCoolingCapacityRange: [0, 100], 
    compressorFlowRate: [0, 22000],
    compressorPressure: [0, 43511],
    compressorPower: [0, 33000]
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {}; 
const reducers = {};

stateKeys.forEach((key) => {
    stateRetrievers[key] = (state) => {
      if(!state.EquipmentCatalog) {
        return null;
      }
      return state.EquipmentCatalog[key];
    }

    reducers[key] = (state, action) => {
        state[key] = action.payload;
    }
});

export const equipmentCatalogSlice = createSlice(
    {
        name: 'EquipmentCatalog',
        initialState,
        reducers
    }
)

export const updaters = equipmentCatalogSlice.actions;

export const retrievers = stateRetrievers;

export default equipmentCatalogSlice.reducer;