import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlFetchBaseQuery } from '../../graphql-fetch-base-query'
import { gql } from 'graphql-request'

const endpoint = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PROD_CALSTART_BACKEND_HOST
    : process.env.REACT_APP_DEV_CALSTART_BACKEND_HOST;

export const userSettingsApi = createApi({
    reducerPath: 'userSettingsApi',
    baseQuery: graphqlFetchBaseQuery({
        baseUrl: endpoint,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().AuthenticatedUser.idManagementSession.signInUserSession.accessToken.jwtToken;
           if (token) {
                headers.set('Authorization', token)
            }
            return headers
        },
    }),
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => ({
                document: gql`query ListSettingss {
                    listSettingss {
                        items {
                            settings
                            id
                            _version                        
                        }
                    }
                }`,
            }),
        }),
        changeUserData: builder.mutation({
            query: (qContent) => ({
                document: gql`${qContent}`
            })
        })
    }),
})

export const { useGetUserQuery, useChangeUserDataMutation } = userSettingsApi