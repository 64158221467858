const EVWorkplanData = [
    {
        key: 'costBreakdown',
        type: 'topbar',
        sections: [
            {
                key: 'workplanBreakdownSection',
                type: 'costBreakdownRanges',
                collection: 'workplan', // Think of collection as being more like tags rather than folders
                elements: [
                    {   
                        type: 'costBreakdownBullet',
                        title: 'Design',
                        property: 'designTimeline', 
                        key: 'designTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Permitting',
                        property: 'permitTimeline', 
                        key: 'permitTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Construction',
                        property: 'constructionTimeline', 
                        key: 'constructionTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Commissioning',
                        property: 'commissioningTimeline', 
                        key: 'commissioningTimeline'
                    },
                    {
                        type: 'costBreakdownBullet',
                        title: 'Total', 
                        property: 'totalProjectTimeline',
                        key: 'totalProjectTimeline'
                    }
                ]
            }
        ] 
    },
    { 
        key: 'timelineDesignDetails',
        sections: [
            {
                key: 'engDesignDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'DesignPublicReview'
                    },
                    {
                        type: 'title',
                        title: 'Design & Public Design Review',
                        link: {
                            text: 'Find a design partner',
                            href: '/vendor_catalog'
                        },
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'designTimeline',
                        key: 'engDesignTimeline'
                    }
                ]
            }, 
            {
                key: 'engDesignDetailsCards',
                type: 'timelineBreakdownCards',
                collection: 'workplan',
                elements: [
                    {
                        type: 'workplanDetailCard',
                        title: 'Talk to your Utility',
                        text: 'Early and frequent communication with your local utility is the best way to avoid delays later on.',
                        key: 'designTalkToUtility'
                    },
                    {
                        type: 'workplanDetailCard',
                        title: 'Talk to the Public',
                        text: 'Prior to a project breaking ground, the city council will review and approve or reject the site plan design. Community outreach may also be required.',
                        key: 'designTalkToPublic'
                    },
                    {
                        type: 'workplanDetailCard',
                        title: 'Local Quirks',
                        text: 'Some communities are more particular or strict than others, which can lead to delays.',
                        key: 'designLocalQuirks'
                    },
                ]
            }
        ]
    },
    { 
        key: 'timelinePermittingDetails',
        sections: [
            {
                key: 'engPermittingDetailsTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'Permitting'
                    },
                    {
                        type: 'title',
                        title: 'Permitting',
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'permitTimeline',
                        key: 'permittingTimeline'
                    }
                ]
            }, 
            {
                key: 'engPermittingDetailsCards',
                type: 'timelineBreakdownCards',
                collection: 'workplan',
                elements: [
                    {
                        type: 'workplanDetailCard',
                        title: 'Required to Dig',
                        text: 'In order to break ground, a permit must be acquired.',
                        key: 'permittingRequiredToDig',
                    },
                    {
                        type: 'workplanDetailCard',
                        title: 'Experience Helps',
                        text: 'Permitting takes less time when the permitting agency is already familiar with EV projects.',
                        key: 'permittingExperienceHelps',
                    },
                    {
                        type: 'workplanDetailCard',
                        title: 'Inspection',
                        text: 'There are required inspections to ensure build to print and built to code to ensure public safety.',
                        key: 'permittingInspection',
                    }
                ]
            }
        ]
    },
    { 
        key: 'timelineConstructionDetails',
        sections: [
            {
                key: 'constructionTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'Construction'
                    },
                    {
                        type: 'title',
                        title: 'Construction',
                        link: {
                            text: 'Find a construction partner',
                            href: '/vendor_catalog'
                        },
                        key: 'engDesignTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'constructionTimeline',
                        key: 'constructionTimeline'
                    }
                ]
            }, 
            {
                key: 'engDesignDetailsCards',
                type: 'timelineBreakdownCards',
                collection: 'workplan',
                elements: [
                    {
                        type: 'workplanDetailCard',
                        title: 'Wide Range',
                        text: 'Construction time will vary based on the amount of equipment being installed, the complexity of the project, and environmental factors such as rain or lightning.',
                        key: 'constructionWideRange',
                        scaleOverrides: {
                            md: 6
                        }
                    },
                    {
                        type: 'workplanDetailCard',
                        title: 'Utility Upgrades',
                        text: 'If utility upgrades are required, expect construction delays.', 
                        key: 'utilityUpgrades',
                        scaleOverrides: {
                            md: 6
                        }
                    }
                ]
            }
        ]
    },
    { 
        key: 'timelineCommissioningDetails',
        sections: [
            {
                key: 'commissioningTitle',
                type: 'costBreakdownTitle',
                collection: 'workplan',
                elements: [
                    {
                        type: 'thumbnail',
                        img: 'CommissioningAndInspection'
                    },
                    {
                        type: 'title',
                        title: 'Commissioning & Inspection',
                        link: {
                            text: 'Find a construction partner',
                            href: '/vendor_catalog'
                        },
                        key: 'comissioningTitle'
                    },
                    {
                        type: 'priceRange',
                        property: 'commissioningTimeline',
                        key: 'commissioningTimeline'
                    }
                ]
            }, 
            {
                key: 'engDesignDetailsCards',
                type: 'timelineBreakdownCards',
                collection: 'workplan',
                elements: [
                    {
                        type: 'workplanDetailCard',
                        title: 'Test the equipment',
                        text: 'Testing that each piece of equipment works individually and that the system works as a whole.',
                        key: 'testEquipment'
                    },
                    {     
                        type: 'workplanDetailCard',
                        title: 'Test Charging',
                        text: 'Test the system with a few vehicles to validate the network and ensure that electricity is transferred safely.',
                        key: 'testCharging'
                    },
                    {
                        type: 'workplanDetailCard',
                        title: 'Ready to Use!',
                        text: 'After successful commissioning, feel confident everything works and is ready to start using on a daily basis.',
                        key: 'readyToUse'
                    }
                ]
            }
        ]
    }
]

 
export default EVWorkplanData;