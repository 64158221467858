import React, {useState} from 'react'
import { Tooltip, Button } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';

const CSTooltip = ({
    button = <InfoIcon/>,
    details,
    buttonClassName = 'info-icon',
    detailsClassName = 'tooltip-text'
}) => {

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

    return (
        <Tooltip
            open={tooltipIsOpen}
            onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title={[<div className={detailsClassName}>{details}</div>]}
            className="tooltip">
            <Button className={buttonClassName}>{button}</Button>
        </Tooltip>
    )
}

export default CSTooltip;