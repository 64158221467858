import {FleetAndSiteCommonInputFields} from './';

const { VehicleTypes, TimesOfDayOptions, SiteInputs, StationType } = FleetAndSiteCommonInputFields;
 
const OnRouteFields = {
    'fieldGroups': [
        StationType,
        {
            title: 'My Fleet',
            subtitle: '',
            collection: 'fleet',
            sections: [
                {
                    type: 'interactive',
                    inputs: [
                        {
                            property: 'vehicleType',
                            type: 'dropdown',
                            options: VehicleTypes,
                            title: 'Vehicle type',
                            placeholder: 'Select Vehicle Type'
                        },
                        {
                            properties: [
                                'timeAvailableStartOnRoute',
                                'timeAvailableFinishOnRoute'
                            ], 
                            type: 'doubleDropdown',
                            options: [
                                [ ...TimesOfDayOptions],
                                [ ...TimesOfDayOptions]
                            ],
                            title: [
                                'Time available to recharge or refuel',
                                ''
                            ],
                            placeholders: [
                                'Start',
                                'Finish'
                            ],
                            mobileClasses: [
                                '',
                                'charge-finish-mobile'
                            ], 
                            scaleOverrides: [
                                {
                                    md: 3
                                },
                                {
                                    md: 3
                                }
                            ]
                        },
                    ]
                },
                {
                    type: 'interactive',
                    inputs: [
                        {
                            property: 'onRouteVehiclesPerDay',
                            type: 'slider',
                            unit: 'vehicles',
                            title: 'Vehicles per day',
                            default: 200,
                            min: 0,
                            max: 300
                        },
                        {
                            property: 'timeAtOnRouteStation',
                            type: 'slider',
                            unit: 'minutes',
                            title: 'How long can vehicles stay?',
                            min: 0,
                            max: 60
                        },
                        {
                            property: 'additionalMilesPerCharge',
                            type: 'slider',
                            unit: 'miles',
                            title: 'Additional mileage needed per charge or refueling',
                            min: 0,
                            max: 400
                        }
                    ]
                } 
            ]
        },
        SiteInputs
    ]
};

export default OnRouteFields;