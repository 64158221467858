import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sortDirection: 'A-Z',
    includeEngineering: true,
    includeConstruction: true,
    includeProcurement: true,
    includeProduction: true,
    includeDispensingStorage: true
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {}; 
const reducers = {};

stateKeys.forEach((key) => {
    stateRetrievers[key] = (state) => {
      if(!state.VendorCatalog) {
        return null;
      }
      return state.VendorCatalog[key];
    }

    reducers[key] = (state, action) => {
        state[key] = action.payload;
    }
});

export const vendorCatalogSlice = createSlice(
    {
        name: 'VendorCatalog',
        initialState,
        reducers
    }
)

export const updaters = vendorCatalogSlice.actions;

export const retrievers = stateRetrievers;

export default vendorCatalogSlice.reducer;