import React, {useMemo}  from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import addChildToElement from '../utils/addChildToElement';

const buildMenuItems = (options) => {
    return options.map((option, i) => {
        return (
            <MenuItem value={option.value} key={`${i}_${option.name}`}>
                {option.name}
            </MenuItem>
        )
    });
}



/**
 * Adds child to a ReactMD metadata element  
 *  
 * @param {Array} options
 * @param {FormControlProps} formControlProps
 * @param {SelectProps} selectProps
 * @param {InputLabelProps} inputLabelProps
 * @returns {object} element
 */
const Dropdown = ({ options, formControlProps, inputLabelProps, selectProps  }) => {
    if(typeof options === 'undefined') throw new Error('Dropdown requires options id to be set')
    return useMemo ( () => React.createElement(FormControl,
        addChildToElement(formControlProps,
            [
                React.createElement(InputLabel, inputLabelProps),
                React.createElement(Select, addChildToElement(selectProps, buildMenuItems(options)))
            ]
        )
    )
    , [selectProps]) // eslint-disable-line react-hooks/exhaustive-deps
};

export default Dropdown;