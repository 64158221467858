import React from 'react';
import { Box, Card, Grid, Typography, Link } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { InsertIntoGridItem } from '../../Components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const cardStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        textAlign: 'left',
        padding: '0 1rem'
    },
    alignLeft: {
        textAlign: 'left'
    },
    alignRight: {
        textAlign: 'right'
    }
}));

const InfrastructureWorkplan = (
    {
        componentMetadata,
        icons,
        renderMethod = null,
        collection,
        sourceDataFilter
    }
) => {

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const cardClasses = cardStyles();

    const renderElementsInSection = (section) => {
        const noOp = () => { return <div />; }


        const sectionTypeHandlers = {

            // Binding element type to component.
            costBreakdownRanges: (element) => {

                const elementMapping = {

                    costBreakdownBullet: () => {
                        const elementToReturn = (
                            <Box
                                display="flex"
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                    >{element.title}</Typography>
                                    <Typography>{collection[element.property]}</Typography>
                                </Box>
                                <Box>
                                    <ArrowRightIcon />
                                </Box>

                            </Box>
                        )

                        return InsertIntoGridItem(elementToReturn, section, element.key, element.scaleOverrides);
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();
            },

            costBreakdownTitle: (element) => {

                const elementMapping = {

                    thumbnail: () => {
                        return (
                            <Grid
                                className={isMobileDevice ? '' : 'workflow-thumbnail'}
                                item
                                key={element.key}
                                xs={12}
                                md={2}
                            >
                                <img
                                    src={icons[element.img]}
                                    alt={element.img}
                                />
                            </Grid>
                        )
                    },

                    title: () => {
                        return (
                            <Grid
                                item
                                key={element.key}
                                xs={12}
                                md={6}
                            >
                                <Box>
                                    <Typography
                                        variant="h3"
                                        className={cardClasses.alignLeft}
                                    >{element.title}</Typography>
                                </Box>
                                <Box>
                                    {element.link &&
                                        (<Typography
                                            variant="h4"
                                            className={cardClasses.alignLeft}
                                        >
                                            <Link
                                                href={element.link.href}
                                            >{element.link.text}</Link>
                                        </Typography>)
                                    }
                                </Box>
                            </Grid>
                        )
                    },

                    priceRange: () => {
                        return (
                            <Grid
                                item
                                key={element.key}
                                xs={12}
                                md={4}
                            >
                                <Typography
                                    variant="h3"
                                    className={cardClasses.alignRight}
                                >{collection[element.property]}</Typography>
                            </Grid>
                        )
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();
            },
            costBreakdownContent: (element) => {

                const elementMapping = {
                    summary: () => {
                        const returnedElement = (
                            <Box>
                                <Typography
                                    variant="h5"
                                >{element.title}</Typography>
                                <Typography><em>{ element.text }</em></Typography>
                            </Box>
                        )
   
                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    },

                    tip: () => {
                        const returnedElement = (
                            <Card>
                                <Typography>{element.title}</Typography>
                                <Typography>{element.text}</Typography>
                            </Card>
                        )

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    },

                    priceRange: () => {
                        const returnedElement = (
                            <Typography>{collection[element.property]}</Typography>
                        );

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    },

                    hardwareRecap: () => {
                        const returnedElement = (
                            <Box>
                                <Typography
                                    variant="h5"
                                >{element.title}</Typography>
                                <Typography>{collection[element.properties.type]}</Typography>
                                <Typography>{collection[element.properties.quantity]}</Typography>
                                <Typography>{collection[element.properties.cost]}</Typography>
                            </Box>
                        );

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();
            },
            timelineBreakdownCards: (element) => {
                const elementMapping = {
                    workplanDetailCard: () => {
                        const returnedElement = (
                            <Card
                                className={cardClasses.root}
                            >
                                <Typography><strong>{element.title}</strong></Typography>
                                <Typography>{element.text }</Typography>
                            </Card>
                        )

                        return InsertIntoGridItem(returnedElement, section, element.key, element.scaleOverrides);
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();
            },
            bottomLineBox: (element) => {
                const elementMapping = {
                    bottomLineTitleExp: () => {
                        return (
                            <Grid
                                item
                                xs={9}
                                key={element.key}
                            >
                                <Typography
                                    variant="h5"
                                >
                                    {element.title}
                                </Typography>
                                <Typography>
                                    {element.text}
                                </Typography>
                            </Grid>
                        )
                    },
                    priceRange: () => {
                        return (
                            <Grid
                                item
                                xs={3}
                                key={element.key}
                            >
                                <Typography
                                    variant="h5"
                                >
                                    {collection[element.property]}
                                </Typography>
                            </Grid>
                        )
                    }
                }

                const elementHandler = elementMapping[element.type] || noOp;

                return elementHandler();
            }
        }

        return (
            <Grid
                key={section.key}
                container
                justify="center"
                spacing={2}
            >
                {
                    section.elements.map((element) => {
                        const sectionTypeHandler = sectionTypeHandlers[section.type] || noOp;


                        return sectionTypeHandler(element)
                    })
                }
            </Grid>
        )
    }

    const defaultRenderMethod = () => {
        return (
            <Box>
                {componentMetadata.map((fieldSet) => {


                    let hasCards = true;

                    // So we can see the white arrows... not the permanent approach to styling
                    const colorSet = {
                        topbar: {
                            bg: 'black',
                            fg: 'white'
                        },
                        bottomBar: {
                            bg: 'black',
                            fg: 'white'
                        }
                    }

                    const boxColors = colorSet[fieldSet.type] || {
                        bg: 'white',
                        fg: 'black'
                    }

                    // If we have one with 0 cards, don't render it...
                    if (fieldSet.type === 'timelineDetails') {
                        const comparator = (val) => {
                            if (val.type === 'timelineBreakdownCards') {
                                return true;
                            }

                            return false;
                        }

                        const sectionsWithCards = sourceDataFilter(fieldSet.sections, comparator);

                        if (sectionsWithCards.length === 0) {
                            hasCards = false
                        }
                    }

                    const fieldGroupBox = (
                        <Box
                            key={fieldSet.key}
                            bgcolor={boxColors.bg}
                            color={boxColors.fg}
                        >
                            {fieldSet.sections.map((section) => {
                                return renderElementsInSection(section);
                            })}
                        </Box>
                    )

                    return hasCards
                        ? fieldGroupBox
                        : null;
                })}
            </Box>
        )
    }

    const doRender = renderMethod || defaultRenderMethod;

    return doRender()
}

export default InfrastructureWorkplan;